export const getNutsLevelTranslations = (level, t) => {
  const translations = t => ({
    0: t("nutsLevel0"),
    1: t("nutsLevel1"),
    2: t("nutsLevel2"),
    3: t("nutsLevel3"),
    4: t("nutsLevel4"),
    5: t("nutsLevel5"),
    6: t("nutsLevel6"),
    7: t("nutsLevel7"),
    8: t("nutsLevel8"),
    9: t("nutsLevel9"),
    10: t("nutsLevel10"),
    11: t("nutsLevel11"),
    12: t("nutsLevel12"),
    13: t("nutsLevel13"),
    14: t("nutsLevel14"),
    15: t("nutsLevel15"),
    16: t("nutsLevel16"),
    17: t("nutsLevel17"),
    18: t("nutsLevel18"),
    19: t("nutsLevel19")
  });

  return translations(t)[level] ?? t(`nutsLevel${level}`);
};
