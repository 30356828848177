import React from "react";
import {Box, CardHeader} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import {
  FONT_SIZE_SELECTOR_FONT_SIZE_LG,
  FONT_SIZE_SELECTOR_FONT_SIZE_MD,
  FONT_SIZE_SELECTOR_FONT_SIZE_SM
} from "../font-size-selector";
import FormLabelWithTooltip from "../form-label-with-tooltip";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC
} from "../temporal-dim-order-selector/constants";
import {
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT
} from "./constants";

const fieldStyle = {
  margin: "8px 0"
};

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};

function TableSettings(props) {
  const {t, commonSettings, setCommonSettings, tableSettings, setTableSettings} = props;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& > *": {
          marginBottom: "16px"
        },
        "& *:last-child": {
          marginBottom: 0
        }
      }}
    >
      <Paper variant="outlined" sx={paperStyle}>
        <CardHeader subheader={t("components.tableSettings.header.common")} sx={cardHeaderStyle} />
        <FormControl id="table-settings-form__temporal-dim-order" fullWidth sx={fieldStyle}>
          <TextField
            select
            fullWidth
            label={
              <FormLabelWithTooltip tooltip={t("components.temporalDimOrderSelector.title")}>
                {t("components.temporalDimOrderSelector.label")}
              </FormLabelWithTooltip>
            }
            value={commonSettings.temporalDimOrder || ""}
            onChange={ev => setCommonSettings({...commonSettings, temporalDimOrder: ev.target.value})}
            variant="outlined"
          >
            <MenuItem id="TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC" value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
              {t("components.temporalDimOrderSelector.values.asc")}
            </MenuItem>
            <MenuItem id="TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC" value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
              {t("components.temporalDimOrderSelector.values.desc")}
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl id="table-settings-form__label-format" fullWidth sx={fieldStyle}>
          <TextField
            select
            fullWidth
            label={t("components.labelFormatSelector.labelFormat.title")}
            value={commonSettings.labelFormat || ""}
            onChange={ev => setCommonSettings({...commonSettings, labelFormat: ev.target.value})}
            variant="outlined"
          >
            <MenuItem id="LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME" value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>
              {t("components.labelFormatSelector.labelFormat.values.name")}
            </MenuItem>
            <MenuItem id="LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID" value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>
              {t("components.labelFormatSelector.labelFormat.values.id")}
            </MenuItem>
            <MenuItem id="LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH" value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>
              {t("components.labelFormatSelector.labelFormat.values.both")}
            </MenuItem>
          </TextField>
        </FormControl>
      </Paper>
      <Paper variant="outlined" sx={paperStyle}>
        <CardHeader subheader={t("components.tableSettings.header.general")} sx={cardHeaderStyle} />
        <FormControl id="table-settings-form__font-size" fullWidth sx={fieldStyle}>
          <TextField
            select
            fullWidth
            label={t("components.tableSettings.fontSize.title")}
            value={tableSettings.fontSize || ""}
            onChange={ev => setTableSettings({...tableSettings, fontSize: ev.target.value})}
            variant="outlined"
          >
            <MenuItem id="TABLE_SETTINGS_FONT_SIZE_SM" value={FONT_SIZE_SELECTOR_FONT_SIZE_SM}>
              {t("components.tableSettings.fontSize.values.sm")}
            </MenuItem>
            <MenuItem id="TABLE_SETTINGS_FONT_SIZE_MD" value={FONT_SIZE_SELECTOR_FONT_SIZE_MD}>
              {t("components.tableSettings.fontSize.values.md")}
            </MenuItem>
            <MenuItem id="TABLE_SETTINGS_FONT_SIZE_LG" value={FONT_SIZE_SELECTOR_FONT_SIZE_LG}>
              {t("components.tableSettings.fontSize.values.lg")}
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl id="table-settings-form__merge-split-cells" fullWidth sx={fieldStyle}>
          <TextField
            select
            fullWidth
            label={t("components.tableSettings.mergeSplitCells.title")}
            value={tableSettings.mergeSplitCells || ""}
            onChange={ev => setTableSettings({...tableSettings, mergeSplitCells: ev.target.value})}
            variant="outlined"
          >
            <MenuItem
              id="TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE"
              value={TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE}
            >
              {t("components.tableSettings.mergeSplitCells.values.mergeHeaderAndSide")}
            </MenuItem>
            <MenuItem
              id="TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT"
              value={TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT}
            >
              {t("components.tableSettings.mergeSplitCells.values.mergeHeaderSplitSide")}
            </MenuItem>
            <MenuItem
              id="TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE"
              value={TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE}
            >
              {t("components.tableSettings.mergeSplitCells.values.splitHeaderMergeSide")}
            </MenuItem>
            <MenuItem
              id="TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT"
              value={TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT}
            >
              {t("components.tableSettings.mergeSplitCells.values.splitHeaderAndSide")}
            </MenuItem>
          </TextField>
        </FormControl>
      </Paper>
    </Box>
  );
}

export default withTranslation()(TableSettings);
