import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import dataLabelsPlugin from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import Color from "color";
import {Chart as ReactChart} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {CHART_COLORS_ALL_DIMENSION_VALUES_KEY} from "../chart-settings-forms/Colors";
import CustomEmpty from "../custom-empty";
import {
  AUTOMATIC,
  CHART_DATA_LABEL_TYPE_NONE,
  CHART_DATA_LABEL_TYPE_PERCENTAGE,
  CHART_DATA_LABEL_TYPE_VALUE,
  CHART_EXPORT_SIZE_3000X2000,
  CHART_EXPORT_SIZE_ORIGINAL,
  CHART_LEGEND_POSITION_TOP,
  CHART_TYPE_AREA,
  CHART_TYPE_BAR,
  CHART_TYPE_HORIZONTAL_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_PYRAMID,
  CHART_TYPE_RADAR,
  FROM_ZERO,
  getVariationChartType
} from "./constants";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  Tooltip
} from "chart.js";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";
import {getFormattedValue} from "../../utils/formatters";
import {localizeI18nObj} from "../../utils/i18n";
import {getClonedCanvas, getLightOrDarkColorBasedOnContrastRatio} from "../../utils/other";
import "./chart.css";

const $ = window.jQuery;

ChartJS.register(
  // chart elements
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  RadarController,
  RadialLinearScale,
  Tooltip,
  PolarAreaController,
  // plugins
  annotationPlugin,
  dataLabelsPlugin,
  zoomPlugin
);

const AXIS_DECIMAL_PLACES_DEFAULT = 2;

const getChartSizeParams = size => {
  switch (size) {
    case CHART_EXPORT_SIZE_3000X2000: {
      return {
        width: 3000,
        height: 2000,
        fontSize: 48,
        gridLineWidth: 3,
        legendBoxWidth: 100,
        legendPadding: 50,
        borderWidth: 8,
        pointRadius: 16,
        pointBorderWidth: 4
      };
    }
    case CHART_EXPORT_SIZE_ORIGINAL:
    default: {
      return {
        width: undefined,
        height: undefined,
        fontSize: undefined,
        gridLineWidth: undefined,
        legendBoxWidth: undefined,
        legendPadding: undefined,
        borderWidth: undefined,
        pointRadius: undefined,
        pointBorderWidth: undefined
      };
    }
  }
};

const setChartStyle = (chartId, chart, size) => {
  const {
    width,
    height,
    fontSize,
    gridLineWidth,
    legendBoxWidth,
    legendPadding,
    borderWidth,
    pointRadius,
    pointBorderWidth
  } = getChartSizeParams(size);

  const axes = ["x", "y", "varAxis"];
  axes.forEach(axis => {
    chart.options.scales[axis].ticks.font.size = fontSize;
    chart.options.scales[axis].title.font.size = fontSize;
    chart.options.scales[axis].grid.lineWidth = gridLineWidth;
  });

  chart.data.datasets.forEach(dataset => {
    dataset.borderWidth = borderWidth || 2;
    dataset.pointRadius = pointRadius || 4;
    dataset.pointBorderWidth = pointBorderWidth || 1;
  });

  chart.options.plugins.legend.labels.font.size = fontSize;
  chart.options.plugins.legend.labels.boxWidth = legendBoxWidth || 40;
  chart.options.plugins.legend.labels.padding = legendPadding || 10;

  chart.options.plugins.datalabels.font.size = fontSize;

  chart.resize(width, height);

  chart.update();
};

const getDefaultChartSettings = defaultExtremitiesCartesianAxesGraphs => ({
  stacked: false,
  legendPosition: CHART_LEGEND_POSITION_TOP,
  colors: {},
  showAxesLabel: true,
  customizeCategoryAxis: false,
  categoryAxisLabel: {},
  valueAxisLabel: {},
  dataLabelType: CHART_DATA_LABEL_TYPE_NONE,
  extremitiesCartesianAxesGraphs: defaultExtremitiesCartesianAxesGraphs
});

const getCompleteChartSettings = (chartSetting, defaultExtremitiesCartesianAxesGraphs) => {
  const newChartSettings = {};

  const defaultChartSettings = getDefaultChartSettings(defaultExtremitiesCartesianAxesGraphs);

  if (chartSetting === null || chartSetting === undefined) {
    return defaultChartSettings;
  }

  Object.keys(defaultChartSettings).forEach(key => {
    newChartSettings[key] =
      chartSetting[key] !== null && chartSetting[key] !== undefined ? chartSetting[key] : defaultChartSettings[key];
  });

  return newChartSettings;
};

const isBarChart = type => type === CHART_TYPE_BAR || type === CHART_TYPE_HORIZONTAL_BAR || type === CHART_TYPE_PYRAMID;

const isLineChart = type => type === CHART_TYPE_LINE || type === CHART_TYPE_AREA || type === CHART_TYPE_RADAR;

const isBarOrLineChart = type => isBarChart(type) || isLineChart(type);

const formatData = (chartType, chartData, layout, chartSettings, defaultChartColor, hiddenDatasets, hiddenData) => {
  const {primaryDim: primaryDimArr, secondaryDim: secondaryDimArr} = layout;

  const primaryDim = primaryDimArr[0];
  const secondaryDim = secondaryDimArr[0];

  chartData.datasets.forEach(dataset => {
    dataset.data = dataset.data.map((data, idx) => (hiddenData.includes(idx) ? null : data));
  });

  const colorArr = defaultChartColor;

  const getPrimaryDimValueColors = (datasetIdx, isBorder = false) => {
    return chartData.datasets[datasetIdx].data.map((_, valIdx) => {
      const customizedColor = secondaryDim
        ? chartSettings.colors?.[secondaryDim]?.[chartData.datasets[datasetIdx].dimValue]
        : chartSettings.colors?.[primaryDim]?.[chartData.primaryDimInfo.valuesInfo[valIdx]?.dimValue] ||
          chartSettings.colors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY];

      const color = customizedColor || colorArr[datasetIdx % colorArr.length];
      const alpha = Color(color).alpha();
      return !isBorder
        ? color
        : Color(color)
            .alpha(alpha + 0.2)
            .string();
    });
  };

  const getDatasetColors = (datasetIdx, isBorder = false) => {
    if (isBarChart(chartType)) {
      return getPrimaryDimValueColors(datasetIdx, isBorder);
    } else if (isLineChart(chartType)) {
      const color =
        (secondaryDim && chartSettings.colors?.[secondaryDim]?.[chartData.datasets[datasetIdx].dimValue]) ||
        (!secondaryDim && chartSettings.colors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY]) ||
        colorArr[datasetIdx % colorArr.length];
      const alpha = Color(color).alpha();
      return !isBorder
        ? color
        : Color(color)
            .alpha(alpha + 0.2)
            .string();
    } else {
      return chartData.datasets[datasetIdx].data.map((_, valIdx) => {
        const color =
          chartSettings.colors?.[primaryDim]?.[chartData.primaryDimInfo.valuesInfo[valIdx]?.dimValue] ||
          colorArr[valIdx % colorArr.length];
        const alpha = Color(color).alpha();
        return !isBorder
          ? color
          : Color(color)
              .alpha(alpha + 0.2)
              .string();
      });
    }
  };

  const getFill = datasetIdx => {
    let fill = false;
    if (chartType === CHART_TYPE_AREA) {
      fill = chartSettings.stacked && isBarChart(chartType) ? (datasetIdx === 0 ? "origin" : "-1") : "origin";
    } else if (chartType === CHART_TYPE_RADAR) {
      fill = "origin";
    }
    return fill;
  };

  return {
    labels: chartData.primaryDimInfo.valuesInfo.map(dim => dim.label),
    datasets: chartData.datasets.map((dataset, datasetIdx) => ({
      ...dataset,
      hidden: hiddenDatasets.includes(datasetIdx),
      backgroundColor: getDatasetColors(datasetIdx),
      borderColor: isBarOrLineChart(chartType) ? getDatasetColors(datasetIdx, true) : "white",
      borderWidth: 2,
      fill: getFill(datasetIdx),
      pointBackgroundColor: getPrimaryDimValueColors(datasetIdx),
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBorderColor: "white",
      pointBorderWidth: 1,
      pointHoverBorderWidth: 1,
      tension: dataset.isVariation || chartType === CHART_TYPE_LINE ? 0.4 : undefined
    })),
    filterDims: chartData.filterDims
  };
};

const updateWindowChartObj = (chartId, chart, data, options) => {
  if (window?.ChartJs?.[chartId]) {
    if (data) {
      window.ChartJs[chartId].getData = () => data;
    }
    if (options) {
      window.ChartJs[chartId].getOptions = () => options;
    }
    if (chart) {
      window.ChartJs[chartId].getZoomLevel = () => chart.getZoomLevel();
      window.ChartJs[chartId].getAxesExtremes = () => ({
        xMin: chart.scales.x.min,
        xMax: chart.scales.x.max,
        yMin: chart.scales.y.min,
        yMax: chart.scales.y.max
      });
    }
  }
};

const getOptions = (
  chartId,
  chartType,
  layout,
  data,
  labelFormat,
  primaryDimLabel,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  chartSettings,
  disableWheelZoom,
  showTrend = false,
  showCyclical = false,
  showArithmeticMean,
  arithmeticMeanDims,
  arithmeticMeans,
  hiddenDatasets,
  onDatasetShow,
  onDatasetHide,
  hiddenData,
  onDataShow,
  onDataHide,
  disableCategoriesAxisPanAndZoom,
  defaultLanguage,
  languages,
  t
) => {
  let max = 0;
  data.datasets.forEach(dataset => dataset.data.forEach(value => (max = Math.max(max, Math.abs(value)))));
  max += Math.floor((max / 100) * 20);

  const showAxes = isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR;
  const showVariationAxis =
    showAxes &&
    data.datasets.some(dataset => dataset.isVariation) &&
    (showTrend || showCyclical) &&
    getVariationChartType(chartType);
  const invertAxes = chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID;

  const categoriesAxisLabel = localizeI18nObj(chartSettings.categoryAxisLabel, defaultLanguage, languages);
  const valuesAxisLabel = localizeI18nObj(chartSettings.valueAxisLabel, defaultLanguage, languages);

  const categoriesAxis = {
    display: showAxes,
    title: {
      display: chartSettings.showAxesLabel,
      text:
        chartSettings.customizeCategoryAxis && (categoriesAxisLabel || "").length > 0
          ? categoriesAxisLabel
          : primaryDimLabel,
      font: {}
    },
    beginAtZero: false,
    ticks: {
      font: {}
    },
    grid: {
      display: isLineChart(chartType)
    },
    stacked: chartType === CHART_TYPE_PYRAMID || (chartSettings.stacked && isBarChart(chartType))
  };

  const valuesAxis = {
    display: showAxes,
    title: {
      display: chartSettings.showAxesLabel && (valuesAxisLabel || "").length > 0,
      text: valuesAxisLabel,
      font: {}
    },
    beginAtZero: chartSettings.extremitiesCartesianAxesGraphs === FROM_ZERO,
    ticks: {
      font: {},
      callback: value => {
        const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
        if (chartType === CHART_TYPE_PYRAMID && data.datasets.length === 2 && value < 0) {
          return getFormattedValue(-value, decimalSeparator, axisDecimalPlaces, "", roundingStrategy);
        } else {
          return getFormattedValue(value, decimalSeparator, axisDecimalPlaces, "", roundingStrategy);
        }
      }
    },
    grid: {
      display: true
    },
    max: chartType === CHART_TYPE_PYRAMID ? max : undefined,
    min: chartType === CHART_TYPE_PYRAMID ? -max : undefined,
    stacked: chartType === CHART_TYPE_PYRAMID || (chartSettings.stacked && isBarChart(chartType))
  };

  const variationAxis = {
    display: showVariationAxis,
    title: {
      display: chartSettings.showAxesLabel,
      text: t("components.chart.axis.variation.label") + " (%)",
      font: {}
    },
    position: "right",
    beginAtZero: false,
    ticks: {
      font: {},
      callback: value => {
        const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
        return getFormattedValue(value, decimalSeparator, axisDecimalPlaces, "", roundingStrategy);
      }
    },
    grid: {
      display: false
    }
  };

  const isArithmeticMeanVisible =
    showArithmeticMean &&
    chartType === CHART_TYPE_BAR &&
    Object.keys(arithmeticMeans || {}).length > 0 &&
    data.filterDims.every((dim, idx) => dim === arithmeticMeanDims[idx]);

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: !invertAxes ? categoriesAxis : valuesAxis,
      y: !invertAxes ? valuesAxis : categoriesAxis,
      varAxis: variationAxis
    },
    indexAxis: !invertAxes ? "x" : "y",
    plugins: {
      legend: {
        display: !isBarOrLineChart(chartType) || (data.datasets || []).length > 1,
        position: chartSettings.legendPosition,
        labels: {
          generateLabels: function () {
            if (isBarOrLineChart(chartType) || chartType === CHART_TYPE_RADAR) {
              return data.datasets.map((dataset, idx) => {
                return {
                  elementIdx: idx,
                  text: dataset.label,
                  fontColor: "rgba(42, 42, 42, 1)",
                  fillStyle: !dataset.backgroundColor
                    ? undefined
                    : typeof dataset.backgroundColor === "string" || dataset.backgroundColor instanceof String
                      ? dataset.backgroundColor
                      : dataset.backgroundColor[0],
                  strokeStyle: !dataset.borderColor
                    ? undefined
                    : typeof dataset.borderColor === "string" || dataset.borderColor instanceof String
                      ? dataset.borderColor
                      : dataset.borderColor[0],
                  lineWidth: dataset.borderWidth,
                  hidden: hiddenDatasets.includes(idx)
                };
              });
            } else {
              return data.labels.map((label, idx) => {
                const firstDataset = data.datasets?.[0] || {};

                return {
                  elementIdx: idx,
                  text: label,
                  fillStyle: firstDataset.backgroundColor?.[idx],
                  strokeStyle: firstDataset.borderColor,
                  lineWidth: firstDataset.borderWidth,
                  hidden: hiddenData.includes(idx)
                };
              });
            }
          },
          font: {}
        },
        onClick: function (e, legendItem) {
          if (isBarOrLineChart(chartType) || chartType === CHART_TYPE_RADAR) {
            const datasetIndex = legendItem.elementIdx;
            if (hiddenDatasets.includes(datasetIndex)) {
              onDatasetShow(datasetIndex);
            } else {
              onDatasetHide(datasetIndex);
            }
          } else {
            const dataIndex = legendItem.elementIdx;
            if (hiddenData.includes(dataIndex)) {
              onDataShow(dataIndex);
            } else {
              onDataHide(dataIndex);
            }
          }
        }
      },
      tooltip: {
        mode: "point",
        callbacks: {
          title: tooltipItems => tooltipItems[0]?.label || "",
          label: tooltipItem => {
            const dataset = tooltipItem.dataset;

            let value = dataset.data[tooltipItem.dataIndex];
            if (!value) {
              return "";
            }

            value = getFormattedValue(value, decimalSeparator, decimalPlaces, "", roundingStrategy);
            value =
              chartType === CHART_TYPE_PYRAMID && tooltipItem.datasetIndex === 0 && value[0] === "-"
                ? value.slice(1)
                : value;

            return `${dataset.label}: ${value}${dataset.isVariation ? "%" : ""}`;
          },
          footer: tooltipItems => {
            const datasetIdx = tooltipItems[0].datasetIndex;
            const dataIdx = tooltipItems[0].dataIndex;

            const attributes = data.datasets[datasetIdx].dataAttributes[dataIdx];
            const labels = [];

            /** observation **/
            if ((attributes.observation || []).length > 0) {
              labels.push(t("components.chart.tooltip.attributes.observation.label") + ":");
              attributes.observation.forEach(attr => {
                labels.push(`- ${getAttributeLabel(attr, labelFormat)}: ${getAttributeValueLabel(attr, labelFormat)}`);
              });
            }

            return labels;
          }
        },
        footerFont: {
          weight: "normal"
        },
        footerMarginTop: 12
      },
      zoom: {
        pan: {
          enabled: disableWheelZoom !== true && isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR,
          mode: () =>
            !disableCategoriesAxisPanAndZoom
              ? "xy"
              : chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID
                ? "x"
                : "y",
          onPanComplete: ({chart}) => {
            updateWindowChartObj(chartId, chart);
          }
        },
        zoom: {
          wheel: {
            enabled: disableWheelZoom !== true && isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR
          },
          pinch: {
            enabled: disableWheelZoom !== true && isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR
          },
          mode: () =>
            !disableCategoriesAxisPanAndZoom
              ? "xy"
              : chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID
                ? "x"
                : "y",
          onZoomComplete: ({chart}) => {
            updateWindowChartObj(chartId, chart);
          }
        }
      },
      datalabels: {
        labels: {
          value: {
            display: chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_NONE ? false : "auto",
            formatter: (value, ctx) => {
              const dataset = data.datasets[ctx.datasetIndex];

              if (chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_VALUE) {
                return (
                  getFormattedValue(value, decimalSeparator, decimalPlaces, "", roundingStrategy) +
                  (dataset.isVariation ? "%" : "")
                );
              } else if (chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_PERCENTAGE) {
                let sum = 0;
                dataset.data.forEach(data => {
                  sum += data;
                });
                return ((value * 100) / sum).toFixed(1) + "%";
              } else {
                return "";
              }
            },
            color: ctx => {
              const dataset = data.datasets[ctx.datasetIndex];

              const rgbaLightColor = "rgba(255, 255, 255, 0.9)";
              const rgbaDarkColor = "rgba(42, 42, 42, 1)";

              const color = ctx.dataset.backgroundColor[ctx.dataIndex];
              return isLineChart(dataset.type)
                ? rgbaDarkColor
                : color.startsWith("rgba")
                  ? getLightOrDarkColorBasedOnContrastRatio(color, rgbaLightColor, rgbaDarkColor)
                  : rgbaLightColor;
            },
            align: chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID ? "left" : "bottom",
            offset: 2
          },
          attribute: {
            formatter: (value, ctx) => {
              const attributes = data.datasets[ctx.datasetIndex].dataAttributes[ctx.dataIndex];
              return attributes?.observation !== null && attributes?.observation !== undefined ? "(*)" : null;
            },
            font: {
              family: "Do Hyeon"
            },
            align:
              chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_NONE
                ? "center"
                : chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID
                  ? "right"
                  : "top",
            offset: 2
          }
        },
        font: {},
        clamp: true,
        clip: true
      },
      annotation: {
        annotations: isArithmeticMeanVisible
          ? data.datasets
              .filter(({isVariation}) => !isVariation)
              .map((dataset, datasetIdx) => {
                const meanIdx = data.filterDims.map(dim => dataset.filtersValue[dim]).join("+");

                const secondaryDim = layout.secondaryDim.length > 0 ? layout.secondaryDim[0] : null;
                const secondaryDimValue = secondaryDim ? dataset.filtersValue[secondaryDim] : null;
                const secondaryDimValueLabel = dataset.label;

                return {
                  type: "line",
                  scaleID: "y",
                  value: !hiddenDatasets.includes(datasetIdx) ? arithmeticMeans[meanIdx] : null,
                  borderColor: dataset.borderColor,
                  borderWidth: 2,
                  label: {
                    display: true,
                    position: "start",
                    xAdjust: 10,
                    content:
                      t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label") +
                      (secondaryDimValue ? ` ${secondaryDimValueLabel}` : ""),
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.75)"
                  }
                };
              })
          : []
      }
    }
  };
};

const Chart = forwardRef((props, ref) => {
  const {
    defaultLanguage,
    languages,
    defaultChartColor,
    disableCategoriesAxisPanAndZoom,
    defaultExtremitiesCartesianAxesGraphs,
    chartId: externalChartId,
    type,
    chartData,
    layout,
    disableWheelZoom,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    chartSettings,
    showTrend,
    showCyclical,
    showArithmeticMean,
    arithmeticMeanDims,
    arithmeticMeans
  } = props;
  const {t} = useTranslation();

  const chartRef = useRef();

  const [chartId] = useState(externalChartId || uuidv4());

  const [hiddenDatasets, setHiddenDataset] = useState([]);
  const [hiddenData, setHiddenData] = useState([]);

  const zoomIn = () => {
    chartRef.current.zoom(1.1, "zoom");
    updateWindowChartObj(chartId, chartRef.current);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9, "zoom");
    updateWindowChartObj(chartId, chartRef.current);
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  useImperativeHandle(ref, () => ({
    zoomIn: () => zoomIn(),
    zoomOut: () => zoomOut(),
    resetZoom: () => resetZoom()
  }));

  useEffect(() => {
    defaults.font.family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
  }, []);

  useEffect(() => {
    const chart = chartRef.current;

    window.ChartJs = window.ChartJs || {};
    window.ChartJs[chartId] = {};
    window.ChartJs[chartId].getCanvas = (size, cb) => {
      const $chartCanvas = $(`#${chartId}`);
      const $chartCanvasClone = getClonedCanvas($chartCanvas);
      $chartCanvas.before($chartCanvasClone);
      $chartCanvas.css({visibility: "hidden"});

      chart.options.animation = false; // disables all animations

      if (size !== CHART_EXPORT_SIZE_ORIGINAL) {
        setChartStyle(chartId, chart, size);
      }

      const c = $chartCanvas.get(0);
      cb(c);

      if (size !== CHART_EXPORT_SIZE_ORIGINAL) {
        setChartStyle(chartId, chart, CHART_EXPORT_SIZE_ORIGINAL);
      }

      setTimeout(() => {
        $chartCanvas.css({visibility: "visible"});
        $chartCanvasClone.remove();

        chart.options.animation = true; // re-enable all animations
      }, 100);
    };
    updateWindowChartObj(chartId, chartRef.current, {}, {});

    return () => {
      delete window.ChartJs[chartId];
    };
  }, [chartId, chartRef]);

  useEffect(() => {
    setHiddenDataset([]);
    setHiddenData([]);
  }, [type]);

  const onDatasetHide = useCallback(datasetIdx => {
    setHiddenDataset(prevHiddenDatasets => [...prevHiddenDatasets, datasetIdx]);
  }, []);

  const onDatasetShow = useCallback(datasetIdx => {
    setHiddenDataset(prevHiddenDatasets => prevHiddenDatasets.filter(idx => idx !== datasetIdx));
  }, []);

  const onDataHide = useCallback(dataIdx => {
    setHiddenData(prevHiddenData => [...prevHiddenData, dataIdx]);
  }, []);

  const onDataShow = useCallback(dataIdx => {
    setHiddenData(prevHiddenData => prevHiddenData.filter(idx => idx !== dataIdx));
  }, []);

  const completeChartSettings = getCompleteChartSettings(chartSettings, defaultExtremitiesCartesianAxesGraphs);

  const formattedChartData = formatData(
    type,
    chartData,
    layout,
    completeChartSettings,
    defaultChartColor,
    hiddenDatasets,
    hiddenData
  );

  const options = getOptions(
    chartId,
    type,
    layout,
    formattedChartData,
    labelFormat,
    chartData.primaryDimInfo.label,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    completeChartSettings,
    disableWheelZoom,
    showTrend,
    showCyclical,
    showArithmeticMean,
    arithmeticMeanDims,
    arithmeticMeans,
    hiddenDatasets,
    onDatasetShow,
    onDatasetHide,
    hiddenData,
    onDataShow,
    onDataHide,
    disableCategoriesAxisPanAndZoom,
    defaultLanguage,
    languages,
    t
  );

  updateWindowChartObj(chartId, chartRef.current, formattedChartData, options);

  const dataCount = (formattedChartData?.datasets || []).reduce((acc, {data}) => acc + data.length, 0);
  if (dataCount === 0) {
    return <CustomEmpty text={t("components.chart.noDataToDisplay")} />;
  }

  const isInvalidPyramid =
    type === CHART_TYPE_PYRAMID &&
    ((layout.secondaryDim || []).length !== 1 || (layout.secondaryDimValues || []).length !== 2);
  if (isInvalidPyramid) {
    return <CustomEmpty text={t("components.chart.invalidPyramid")} />;
  }

  return <ReactChart id={chartId} ref={chartRef} className={"chart"} options={options} data={formattedChartData} />;
});

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  defaultChartColor: state.appConfig.chartConfig.defaultChartColor,
  disableCategoriesAxisPanAndZoom: state.appConfig.chartConfig.disableCategoriesAxisPanAndZoom,
  defaultExtremitiesCartesianAxesGraphs: state.appConfig.chartConfig.extremitiesCartesianAxesGraphs || AUTOMATIC
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(Chart);
