import React from "react";
import {Box, CardHeader, Grid} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import {
  AUTOMATIC,
  CHART_DATA_LABEL_TYPE_NONE,
  CHART_DATA_LABEL_TYPE_VALUE,
  CHART_DIM_ORDER_SELECTOR_VALUE_ASC,
  CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
  CHART_DIM_ORDER_SELECTOR_VALUE_DESC,
  CHART_LEGEND_POSITION_BOTTOM,
  CHART_LEGEND_POSITION_lEFT,
  CHART_LEGEND_POSITION_RIGHT,
  CHART_LEGEND_POSITION_TOP,
  CHART_SORTING_VALUE_DEFAULT,
  DEFAULT,
  FROM_ZERO,
  initialChartSortingCartesian,
  initialChartSortingRadial
} from "../chart/constants";
import FormLabelWithTooltip from "../form-label-with-tooltip";
import I18nTextField from "../i18n-text-field";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC
} from "../temporal-dim-order-selector/constants";

const fieldStyle = {
  margin: "8px 0"
};

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};

function ChartSettingsGeneral(props) {
  const {
    t,
    cartesianSecondaryDim,
    cartesianSecondaryDimValues,
    radialSecondaryDim,
    radialSecondaryDimValues,
    getDimLabel,
    getDimValueLabel,
    commonSettings,
    setCommonSettings,
    settings,
    onSettingsSet
  } = props;

  const {
    stacked,
    legendPosition,
    showAxesLabel,
    customizeCategoryAxis,
    categoryAxisLabel,
    valueAxisLabel,
    dataLabelType,
    extremitiesCartesianAxesGraphs,
    obsSortingOrderCartesian,
    obsSortingValueCartesian,
    obsSortingOrderRadial,
    obsSortingValueRadial
  } = settings;

  // If the value of the secondary dimension for which I am trying to sort does
  // not exist, for example because it is not selected at the criteria level,
  // I discard the current sort and restore the default one
  if (
    obsSortingValueCartesian !== CHART_SORTING_VALUE_DEFAULT &&
    !cartesianSecondaryDimValues.includes(obsSortingValueCartesian)
  ) {
    onSettingsSet({
      ...settings,
      ...initialChartSortingCartesian
    });
  }
  if (
    obsSortingValueRadial !== CHART_SORTING_VALUE_DEFAULT &&
    !radialSecondaryDimValues.includes(obsSortingValueRadial)
  ) {
    onSettingsSet({
      ...settings,
      ...initialChartSortingRadial
    });
  }

  // If there is a secondary dimension and the selected order is different
  // from default, I set the first value of the secondary dimension as preselected.
  const handleSortingOptionCartesian = order => {
    let value = obsSortingValueCartesian;
    if (order === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT) {
      value = CHART_SORTING_VALUE_DEFAULT;
    } else {
      if (value === CHART_SORTING_VALUE_DEFAULT && cartesianSecondaryDimValues.length > 0) {
        value = cartesianSecondaryDimValues[0];
      }
    }
    onSettingsSet({
      ...settings,
      obsSortingOrderCartesian: order,
      obsSortingValueCartesian: value
    });
  };
  const handleSortingOptionRadial = order => {
    let value = obsSortingValueRadial;
    if (order === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT) {
      value = CHART_SORTING_VALUE_DEFAULT;
    } else {
      if (value === CHART_SORTING_VALUE_DEFAULT && radialSecondaryDimValues.length > 0) {
        value = radialSecondaryDimValues[0];
      }
    }
    onSettingsSet({
      ...settings,
      obsSortingOrderRadial: order,
      obsSortingValueRadial: value
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& > *": {
          marginBottom: "16px"
        },
        "& *:last-child": {
          marginBottom: 0
        }
      }}
    >
      {setCommonSettings && (
        <Paper variant="outlined" sx={paperStyle}>
          <CardHeader subheader={t("components.chartSettings.header.common")} sx={cardHeaderStyle} />
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              id="chart-settings-form__temporal-dim-order-selector"
              select
              fullWidth
              label={
                <FormLabelWithTooltip tooltip={t("components.temporalDimOrderSelector.title")}>
                  {t("components.temporalDimOrderSelector.label")}
                </FormLabelWithTooltip>
              }
              value={commonSettings.temporalDimOrder || ""}
              onChange={ev => setCommonSettings({...commonSettings, temporalDimOrder: ev.target.value})}
              variant="outlined"
            >
              <MenuItem
                id="chart-settings-form__temporal-dim-order-selector-asc"
                value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}
              >
                {t("components.temporalDimOrderSelector.values.asc")}
              </MenuItem>
              <MenuItem
                id="chart-settings-form__temporal-dim-order-selector-desc"
                value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}
              >
                {t("components.temporalDimOrderSelector.values.desc")}
              </MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              id="chart-settings-form__label-format-selector"
              select
              fullWidth
              label={t("components.labelFormatSelector.labelFormat.title")}
              value={commonSettings.labelFormat || ""}
              onChange={ev => setCommonSettings({...commonSettings, labelFormat: ev.target.value})}
              variant="outlined"
            >
              <MenuItem
                id="chart-settings-form__label-format-selector-name"
                value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}
              >
                {t("components.labelFormatSelector.labelFormat.values.name")}
              </MenuItem>
              <MenuItem
                id="chart-settings-form__label-format-selector-id"
                value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}
              >
                {t("components.labelFormatSelector.labelFormat.values.id")}
              </MenuItem>
              <MenuItem
                id="chart-settings-form__label-format-selector-both"
                value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}
              >
                {t("components.labelFormatSelector.labelFormat.values.both")}
              </MenuItem>
            </TextField>
          </FormControl>
        </Paper>
      )}
      <Paper variant="outlined" sx={paperStyle}>
        <CardHeader subheader={t("components.chartSettings.header.general")} sx={cardHeaderStyle} />
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            id="chart-settings-form__legend-position"
            select
            fullWidth
            label={t("components.chartSettings.general.legendPosition.label")}
            value={legendPosition}
            variant="outlined"
            onChange={ev => onSettingsSet({...settings, legendPosition: ev.target.value})}
          >
            <MenuItem id="chart-settings-form__legend-position-top" value={CHART_LEGEND_POSITION_TOP}>
              {t("components.chartSettings.general.legendPosition.values.top")}
            </MenuItem>
            <MenuItem id="chart-settings-form__legend-position-right" value={CHART_LEGEND_POSITION_RIGHT}>
              {t("components.chartSettings.general.legendPosition.values.right")}
            </MenuItem>
            <MenuItem id="chart-settings-form__legend-position-bottom" value={CHART_LEGEND_POSITION_BOTTOM}>
              {t("components.chartSettings.general.legendPosition.values.bottom")}
            </MenuItem>
            <MenuItem id="chart-settings-form__legend-position-left" value={CHART_LEGEND_POSITION_lEFT}>
              {t("components.chartSettings.general.legendPosition.values.left")}
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl fullWidth sx={fieldStyle}>
          <FormControlLabel
            label={t("components.chartSettings.general.dataLabelType.label")}
            control={
              <Checkbox
                id="chart-settings-form__data-label-type"
                checked={dataLabelType === CHART_DATA_LABEL_TYPE_VALUE}
                onChange={(e, value) => {
                  onSettingsSet({
                    ...settings,
                    dataLabelType: value ? CHART_DATA_LABEL_TYPE_VALUE : CHART_DATA_LABEL_TYPE_NONE
                  });
                }}
              />
            }
          />
        </FormControl>

        {/* Assi cartesiani */}
        <Paper variant="outlined" sx={{...paperStyle, ...fieldStyle}}>
          <CardHeader subheader={t("components.chartSettings.header.chartCartesian")} sx={cardHeaderStyle} />
          <FormControl fullWidth sx={fieldStyle}>
            <FormControlLabel
              label={t("components.chartSettings.general.isStacked.label")}
              control={
                <Checkbox
                  id="chart-settings-form__is-stacked"
                  checked={stacked}
                  onChange={(e, value) => onSettingsSet({...settings, stacked: value})}
                />
              }
            />
          </FormControl>
          <FormControl fullWidth sx={fieldStyle}>
            <FormControlLabel
              label={t("components.chartSettings.general.showAxesLabel.label")}
              control={
                <Checkbox
                  id="chart-settings-form__show-axes-label"
                  checked={showAxesLabel}
                  onChange={(e, value) => onSettingsSet({...settings, showAxesLabel: value})}
                />
              }
            />
          </FormControl>
          {showAxesLabel && (
            <Paper variant="outlined" sx={{...paperStyle, ...fieldStyle}}>
              <>
                <FormControl id="chart-settings-form__value-axis-label" fullWidth sx={fieldStyle}>
                  <I18nTextField
                    label={t("components.chartSettings.general.valueAxisLabel.label")}
                    variant="outlined"
                    value={valueAxisLabel}
                    onChange={value => onSettingsSet({...settings, valueAxisLabel: value})}
                    disabled={!showAxesLabel}
                  />
                </FormControl>
                <FormControl fullWidth sx={fieldStyle}>
                  <FormControlLabel
                    label={t("components.chartSettings.general.customizeCategoryAxis.label")}
                    control={
                      <Checkbox
                        id="chart-settings-form__customize-category-axis"
                        checked={customizeCategoryAxis}
                        onChange={(e, value) => onSettingsSet({...settings, customizeCategoryAxis: value})}
                        disabled={!showAxesLabel}
                      />
                    }
                  />
                </FormControl>
                {customizeCategoryAxis && (
                  <FormControl id="chart-settings-form__category-axis-label" fullWidth sx={fieldStyle}>
                    <I18nTextField
                      label={t("components.chartSettings.general.categoryAxisLabel.label")}
                      variant="outlined"
                      value={categoryAxisLabel}
                      onChange={value => onSettingsSet({...settings, categoryAxisLabel: value})}
                      disabled={!customizeCategoryAxis || !showAxesLabel}
                    />
                  </FormControl>
                )}
              </>
            </Paper>
          )}
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              id="chart-settings-form__extremities-cartesian-axes-graphs"
              select
              fullWidth
              label={t("components.chartSettings.general.extremitiesCartesianAxesGraphs.label")}
              value={extremitiesCartesianAxesGraphs || DEFAULT}
              variant="outlined"
              onChange={ev => onSettingsSet({...settings, extremitiesCartesianAxesGraphs: ev.target.value})}
            >
              <MenuItem id="chart-settings-form__extremities-cartesian-axes-graphs-default" value={DEFAULT}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.default")}
              </MenuItem>
              <MenuItem id="chart-settings-form__extremities-cartesian-axes-graphs-automatic" value={AUTOMATIC}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.automatic")}
              </MenuItem>
              <MenuItem id="chart-settings-form__extremities-cartesian-axes-graphs-from-zero" value={FROM_ZERO}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.fromZero")}
              </MenuItem>
            </TextField>
          </FormControl>
          <Box sx={fieldStyle}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="obs-sorting-order-cartesian__select"
                  select
                  fullWidth
                  value={obsSortingOrderCartesian}
                  label={t("components.chartSettings.general.sortingCartesian.order.label")}
                  variant="outlined"
                  sx={fieldStyle}
                  onChange={ev => handleSortingOptionCartesian(ev.target.value)}
                >
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT}>
                    {t("components.chartSettings.general.sorting.values.default")}
                  </MenuItem>
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_ASC}>
                    {t("components.chartSettings.general.sorting.values.asc")}
                  </MenuItem>
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DESC}>
                    {t("components.chartSettings.general.sorting.values.desc")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="obs-sorting-value-cartesian__select"
                  select
                  fullWidth
                  disabled={
                    obsSortingOrderCartesian === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT ||
                    cartesianSecondaryDimValues.length === 0
                  }
                  value={obsSortingValueCartesian}
                  label={t("components.chartSettings.general.sortingCartesian.value.label", {
                    dimLabel: cartesianSecondaryDim.length > 0 ? `(${getDimLabel(cartesianSecondaryDim)})` : ""
                  })}
                  variant="outlined"
                  sx={fieldStyle}
                  onChange={ev => onSettingsSet({...settings, obsSortingValueCartesian: ev.target.value})}
                >
                  {cartesianSecondaryDimValues.map(val => (
                    <MenuItem key={val} value={val}>
                      {getDimValueLabel(cartesianSecondaryDim, val)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {/* Assi radiali */}
        <Paper variant="outlined" sx={{...paperStyle, ...fieldStyle}}>
          <CardHeader subheader={t("components.chartSettings.header.chartRadial")} sx={cardHeaderStyle} />
          <Box sx={fieldStyle}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="obs-sorting-order-radial__select"
                  select
                  fullWidth
                  value={obsSortingOrderRadial}
                  label={t("components.chartSettings.general.sortingRadial.order.label")}
                  variant="outlined"
                  sx={fieldStyle}
                  onChange={ev => handleSortingOptionRadial(ev.target.value)}
                >
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT}>
                    {t("components.chartSettings.general.sorting.values.default")}
                  </MenuItem>
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_ASC}>
                    {t("components.chartSettings.general.sorting.values.asc")}
                  </MenuItem>
                  <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DESC}>
                    {t("components.chartSettings.general.sorting.values.desc")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="obs-sorting-value-radial__select"
                  select
                  fullWidth
                  disabled={
                    obsSortingOrderRadial === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT ||
                    radialSecondaryDimValues.length === 0
                  }
                  value={obsSortingValueRadial}
                  label={t("components.chartSettings.general.sortingRadial.value.label", {
                    dimLabel: radialSecondaryDim.length > 0 ? `(${getDimLabel(radialSecondaryDim)})` : ""
                  })}
                  variant="outlined"
                  sx={fieldStyle}
                  onChange={ev => onSettingsSet({...settings, obsSortingValueRadial: ev.target.value})}
                >
                  {radialSecondaryDimValues.map(val => (
                    <MenuItem key={val} value={val}>
                      {getDimValueLabel(radialSecondaryDim, val)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
}

export default withTranslation()(ChartSettingsGeneral);
