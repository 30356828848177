export const numberFormatter = new Intl.NumberFormat().format;

export const DECIMAL_SEPARATOR_DEFAULT = ",";
export const DECIMAL_PLACES_DEFAULT = 2;
export const TABLE_EMPTY_CHAR_DEFAULT = "";
export const ROUNDING_STRATEGY = "NormalRounding";

const getRoundingMode = roundingStrategy => {
  if (roundingStrategy === "RoundingUp") {
    return "ceil";
  } else if (roundingStrategy === "Truncation") {
    return "floor";
  } else {
    return undefined;
  }
};

export const getFormattedValue = (
  value,
  decimalSeparator = DECIMAL_SEPARATOR_DEFAULT,
  decimalPlaces = DECIMAL_PLACES_DEFAULT,
  emptyChar = TABLE_EMPTY_CHAR_DEFAULT,
  roundingStrategy = ROUNDING_STRATEGY
) => {
  if (value === null || value === undefined || (typeof value === "string" && value.length === 0)) {
    return emptyChar;
  }

  const regex = /^[-+]?\d*[.,]?\d*$/;
  if (typeof value === "string" && !regex.test(value)) {
    return value;
  }

  const locale = decimalSeparator === "." ? "en-GB" : "it-IT";

  const formattedValue = Intl.NumberFormat(locale, {
    minimumFractionDigits: decimalPlaces < 0 ? 0 : decimalPlaces,
    maximumFractionDigits: decimalPlaces < 0 ? 100 : decimalPlaces,
    trailingZeroDisplay: "stripIfInteger",
    roundingMode: getRoundingMode(roundingStrategy)
  }).format(value);

  return formattedValue;
};

export const getTrimmedStr = str => {
  if (!str || str.length === 0) {
    return "";
  }

  if (String.prototype.trim) {
    return str.trim();
  } else {
    return str.replace(/^\s+|\s+$/gm, "");
  }
};

export const toUpperCaseFirst = string =>
  string !== null && string !== undefined && string.length > 0 ? string[0].toUpperCase() + string.slice(1) : string;

const SECONDS_PER_DAY = 86400;
const SECONDS_PER_HOUR = 3600;

/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} totalSeconds
 * @param {function} t
 * @returns {string}
 */
export const secondsToHms = (totalSeconds, t) => {
  if (totalSeconds < 0) {
    return t("scenes.nodeSettings.cacheSettings.alwaysValid");
  } else if (totalSeconds === 0) {
    return t("scenes.nodeSettings.cacheSettings.disabled");
  } else {
    var days = Math.floor(totalSeconds / SECONDS_PER_DAY);
    var hours = Math.floor((totalSeconds - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR);
    var minutes = Math.floor((totalSeconds - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR) / 60);
    var seconds = totalSeconds - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR - minutes * 60;

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    return wellFormattedTimeString(days, hours, minutes, seconds);
  }
};

const wellFormattedTimeString = (days, hours, mins, sec) => {
  var result = "";
  result += days > 0 ? days + "d " : ""; // hide days if 0
  result += (hours < 10 ? " " + hours : hours) + "h ";
  result += (mins < 10 ? " " + mins : mins) + "m ";

  if (days === 0) {
    // truncate if days is greater than zero
    result += (sec < 10 ? " " + sec : sec) + "s";
  }

  return result;
};
