import React from "react";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SanitizedHTML from "../sanitized-html";
import useLanguages from "../../state/hooks/useLanguages";
import {hubExtraSelector} from "../../state/hub/hubSelectors";

const Footer = () => {
  const {t} = useTranslation();
  const {localizeI18nObj} = useLanguages();
  const hubExtra = useSelector(hubExtraSelector);

  return (
    <Box
      sx={{
        marginTop: "auto",
        paddingTop: "16px"
      }}
      component="footer"
      id="footer"
    >
      <Divider id="footer__divider" sx={{margin: "0 16px"}} />
      <nav id="footer__content" aria-label={t("components.footer.ariaLabel")}>
        <SanitizedHTML html={localizeI18nObj(hubExtra?.Footer)} />
      </nav>
    </Box>
  );
};

export default Footer;
