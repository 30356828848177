import {Reducer} from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import {initialChartSortingCartesian, initialChartSortingRadial} from "../../../components/chart/constants";
import {
  getViewerIdxFromStructure,
  getViewerIdxFromType,
  isChartLayoutCartesianByViewerIdx
} from "../../../components/data-viewer/constant";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../../../components/label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC} from "../../../components/temporal-dim-order-selector/constants";
import {
  Criteria,
  decodeLayoutAggregateCells,
  GENERATING_HTML_TIME_KEY,
  getChartSortingSettingsFromStructure,
  HEADER_CELLS_TYPE,
  OBSERVATION_COUNT_KEY,
  SERVER_TIMINGS_KEY,
  SIDE_CELLS_TYPE,
  ViewerMode
} from "../constants";
import {
  DATASET_SVP_CHART_LAYOUT_SUBMIT,
  DATASET_SVP_CHART_SETTINGS_SET,
  DATASET_SVP_CHART_SORTING_RESET,
  DATASET_SVP_CODELISTS_FETCH,
  DATASET_SVP_CRITERIA_ALERT_HIDE,
  DATASET_SVP_CRITERIA_HIDE,
  DATASET_SVP_CRITERIA_SHOW,
  DATASET_SVP_DATASET_FETCH,
  DATASET_SVP_DATASET_FETCH_ENABLE,
  DATASET_SVP_DOWNLOAD_SUBMIT,
  DATASET_SVP_DOWNLOAD_WARNING_HIDE,
  DATASET_SVP_HIERARCHY_SET,
  DATASET_SVP_HTML_GENERATING_TIME_SET,
  DATASET_SVP_LABEL_FORMAT_SET,
  DATASET_SVP_LAYOUT_HIDE,
  DATASET_SVP_LAYOUT_SHOW,
  DATASET_SVP_MAP_DETAIL_LEVEL_SET,
  DATASET_SVP_MAP_LAYOUT_SUBMIT,
  DATASET_SVP_MAP_SETTINGS_SET,
  DATASET_SVP_SDMX_QUERY_FETCH,
  DATASET_SVP_SDMX_QUERY_HIDE,
  DATASET_SVP_SDMX_QUERY_SHOW,
  DATASET_SVP_SET_FULLSCREEN,
  DATASET_SVP_STATE_BACKUP,
  DATASET_SVP_STRUCTURE_CODELIST_FULL_FETCH,
  DATASET_SVP_STRUCTURE_CODELIST_FULL_HIDE,
  DATASET_SVP_STRUCTURE_CRITERIA_SET,
  DATASET_SVP_STRUCTURE_FETCH_ERROR,
  DATASET_SVP_STRUCTURE_FETCH_INIT,
  DATASET_SVP_STRUCTURE_FETCH_SUCCESS,
  DATASET_SVP_TABLE_LAYOUT_SUBMIT,
  DATASET_SVP_TABLE_SETTINGS_SET,
  DATASET_SVP_TEMPORAL_DIM_ORDER_SET,
  DATASET_SVP_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_SVP_VARIATION_SET,
  DATASET_SVP_VIEW_ERROR_HIDE,
  DATASET_SVP_VIEW_TEMPLATE_HIDE,
  DATASET_SVP_VIEW_TEMPLATE_SHOW,
  DATASET_SVP_VIEW_TEMPLATE_SUBMIT,
  DATASET_SVP_VIEWER_SET
} from "./actions";
import {
  DatasetSingleViewerPlusState,
  getCompleteChartLayoutCartesian,
  getCompleteChartLayoutRadial,
  getCompleteMapLayout,
  getCompleteTableLayout,
  getUpdatedSVPStateDataOnHierarchySelect,
  getViewTemplateBackupFromState
} from "./constants";
import {
  getActionExtras,
  getAppConfig,
  getCurrentNodeConfig
} from "../../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../../middlewares/request/requestActions";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaObjectFromArray,
  getFilteredCodelistsListFromCriteria
} from "../../../utils/criteria";
import {getDatasetSize, getDimensionsInfo} from "../../../utils/dataset";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../../utils/formatters";
import {localizeI18nObj} from "../../../utils/i18n";
import {getDatasetStorageKey} from "../../../utils/other";
import {
  FREQ_ANNUAL,
  getMinAndMax,
  getTimeValuesFromRange,
  SUPPORTED_FREQ_VALUES
} from "../../../utils/timePeriodAndFreq";
import {getMappedTree, getTreeFromArray} from "../../../utils/tree";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getChartSettingsFromViewTemplateLayouts,
  getMapSettingsFromViewTemplateLayouts
} from "../../../utils/viewTemplate";

const initialState = {
  datasetUuid: null,
  dataset: null,
  isFetchStarted: false,
  isFetchFailed: false,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isTooLongQuery: false,
  isCriteriaVisible: false,
  initialCriteriaDimension: null,
  isCriteriaAlertVisible: false,
  dimensions: null,
  dimensionsInfo: {},
  territoryDim: null,
  timeDim: null,
  freqDim: null,
  viewerIdx: null,
  isViewerHoverVisible: false,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  hasAnnotationLayout: false,
  isLayoutVisible: false,
  tableLayoutPartial: null,
  mapLayoutPartial: null,
  chartLayoutPartialCartesian: null,
  chartLayoutPartialRadial: null,
  tableLayout: null,
  mapLayout: null,
  chartLayoutCartesian: null,
  chartLayoutRadial: null,
  labelFormat: null,
  customLabelFormats: {},
  temporalDimOrder: null,
  showTrend: false,
  showCyclical: false,
  splitHeaderCells: false,
  splitSideCells: false,
  areCriteriaApplied: false,
  criteria: {},
  initialCriteria: {},
  decimalSeparator: null,
  roundingStrategy: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: {},
  mapSettings: {},
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  codelistTrees: null,
  codelistLists: null,
  hierarchicalCodelists: null,
  codelistFilteredLists: null,
  codelistMaps: null,
  codelistsLength: null,
  codelistFetchError: false,
  timings: null,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null,
  detailLevel: null,
  isFullscreen: false,
  isTableEnabled: true,
  isMapEnabled: true,
  isChartEnabled: true,
  tableLockedDimensions: null,
  graphLockedDimensions: null,
  missingFilterValues: null,
  lastNObservations: null,
  attributesAsDim: null
};

const emptyJsonStat = {
  id: [],
  size: [],
  role: {
    time: [],
    geo: []
  },
  value: {},
  extension: {
    attributes: {}
  },
  dimension: {},
  version: "",
  class: "",
  label: ""
};

const datasetSVPReducer: Reducer<DatasetSingleViewerPlusState, any> = (state = initialState, action) => {
  switch (action.type) {
    case DATASET_SVP_STATE_BACKUP: {
      if (state.datasetUuid !== null && state.datasetUuid === action.datasetUuid && state.dataset !== null) {
        sessionStorage.setItem(state.datasetUuid, JSON.stringify(getViewTemplateBackupFromState(state)));
      }
      return state;
    }
    case DATASET_SVP_STRUCTURE_FETCH_INIT: {
      return initialState;
    }
    case DATASET_SVP_STRUCTURE_FETCH_SUCCESS: {
      const {language, languages} = getActionExtras(action);

      const VIEW_KEY = "view";
      const TEMPLATE_KEY = "template";

      const {datasetUuid, structure, viewId} = action.payload;

      const dimensions = structure?.criteria || [];

      const territoryDim = structure?.territorialDimension || null;
      const timeDim = structure?.timeDimension || null;
      const freqDim = structure?.freqDimension || null;

      const hasView =
        structure[VIEW_KEY] && (!structure[VIEW_KEY]?.mode || structure[VIEW_KEY].mode === ViewerMode.SingleViewer);
      const view = hasView
        ? {
            ...structure[VIEW_KEY],
            layouts: JSON.parse(structure[VIEW_KEY].layouts)
          }
        : null;

      const hasTemplate =
        structure[TEMPLATE_KEY] &&
        (!structure[TEMPLATE_KEY]?.mode || structure[TEMPLATE_KEY].mode === ViewerMode.SingleViewer);
      const template = hasTemplate
        ? {
            ...structure[TEMPLATE_KEY],
            layouts: JSON.parse(structure[TEMPLATE_KEY].layouts)
          }
        : null;

      let sessionBackup;
      const storageItem = sessionStorage.getItem(datasetUuid);
      if (storageItem && storageItem.length > 0) {
        sessionBackup = JSON.parse(storageItem || "{}");
        sessionBackup.layouts = JSON.parse(sessionBackup?.layouts || "{}");
        sessionBackup = sessionBackup.mode === ViewerMode.SingleViewerPlus ? sessionBackup : null;

        const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
        if (!storedDatasets.map(getDatasetStorageKey).includes(datasetUuid)) {
          sessionStorage.removeItem(datasetUuid);
        }
      }

      let viewTemplate = sessionBackup || view || template || null;
      let viewTemplateLayouts = null;

      if (viewTemplate) {
        viewTemplate = {
          ...viewTemplate,
          decimalSeparator: hasTemplate ? template.decimalSeparator : null,
          roundingStrategy: hasTemplate ? template.roundingStrategy : null,
          decimalNumber: hasTemplate ? template.decimalNumber : null,
          enableCriteria: hasTemplate ? template.enableCriteria : true,
          enableLayout: hasTemplate ? template.enableLayout : true,
          enableVariation: hasTemplate ? template.enableVariation : false
        };
        viewTemplateLayouts = {
          ...(template?.layouts || {}),
          ...(view?.layouts || {}),
          ...(sessionBackup?.layouts || {})
        };
      }

      const structureCriteria = (structure.filters || []).length > 0 ? structure.filters : null;

      const criteria = getCriteriaObjectFromArray(viewTemplate?.criteria || structureCriteria);

      let isCriteriaVisible = !viewTemplate && _.isEmpty(criteria);

      // check whether each dimension with associated HCL has a hierarchy selected
      const dimensionsWithHcl = dimensions.filter(dimension => (dimension.hierarchies || []).length > 0) || [];
      dimensionsWithHcl.forEach(dim => {
        if (!criteria?.[dim.id]?.hierarchyId) {
          // there is no selected hierarchy
          if (dim.hierarchyId) {
            // there is a default value, this value will be selected
            criteria[dim.id] = {
              id: dim.id,
              type: CRITERIA_FILTER_TYPE_CODES,
              filterValues: [],
              period: undefined,
              from: undefined,
              to: undefined,
              hierarchyId: dim.hierarchyId
            };
          } else {
            // there is no default value, the user must choose a value
            isCriteriaVisible = true;
          }
        }
      });

      const disabledViewers = (structure?.disabledViewers || []).map((v: string) => v.toLowerCase());

      let viewerIdx = null;
      let isTableEnabled = !disabledViewers.includes("table");
      let isMapEnabled = !disabledViewers.includes("map");
      let isChartEnabled = !disabledViewers.includes("graph");

      if (viewTemplate) {
        viewerIdx = getViewerIdxFromType(viewTemplate.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (structure.defaultView) {
        viewerIdx = getViewerIdxFromStructure(structure.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (isTableEnabled) {
        viewerIdx = 0;
      } else if (isMapEnabled) {
        viewerIdx = 1;
      } else if (isChartEnabled) {
        viewerIdx = 2;
      }

      const tableLayout =
        viewTemplateLayouts && viewTemplateLayouts?.tableLayout
          ? viewTemplateLayouts.tableLayout
          : structure?.layoutTable
            ? {
                cols: [],
                rows: [],
                sections: [],
                filters: [],
                ...structure.layoutTable
              }
            : null;
      const mapLayout = !territoryDim
        ? null
        : viewTemplateLayouts && viewTemplateLayouts?.mapLayout
          ? viewTemplateLayouts.mapLayout
          : structure?.layoutMap
            ? {
                filters: [],
                ...structure.layoutMap,
                territoryDim: territoryDim
              }
            : null;
      const chartLayoutCartesian =
        viewTemplateLayouts && viewTemplateLayouts?.chartLayoutCartesian
          ? viewTemplateLayouts.chartLayoutCartesian
          : viewTemplateLayouts && viewTemplateLayouts?.chartLayout
            ? viewTemplateLayouts.chartLayout
            : structure?.layoutChartCartesian
              ? {
                  primaryDim: [],
                  primaryDimValues: [],
                  secondaryDim: [],
                  secondaryDimValues: [],
                  filters: [],
                  ...structure?.layoutChartCartesian
                }
              : null;
      const chartLayoutRadial =
        viewTemplateLayouts && viewTemplateLayouts?.chartLayoutRadial
          ? viewTemplateLayouts.chartLayoutRadial
          : viewTemplateLayouts && viewTemplateLayouts?.chartLayout
            ? viewTemplateLayouts.chartLayout
            : structure?.layoutChartRadial
              ? {
                  primaryDim: [],
                  primaryDimValues: [],
                  secondaryDim: [],
                  secondaryDimValues: [],
                  filters: [],
                  ...structure?.layoutChartRadial
                }
              : null;

      const chartLockedDimensionsCartesian =
        hasTemplate &&
        template.layouts?.lockChartDimensionsCartesian &&
        (template.layouts?.chartLockedDimensionsCartesian || []).length > 0
          ? template.layouts?.chartLockedDimensionsCartesian
          : structure?.chartLockedDimensionsCartesian || [];

      const chartLockedDimensionsRadial =
        hasTemplate &&
        template.layouts?.lockChartDimensionsRadial &&
        (template.layouts?.chartLockedDimensionsRadial || []).length > 0
          ? template.layouts?.chartLockedDimensionsRadial
          : structure?.chartLockedDimensionsRadial || [];

      const customLabelFormats = [...(structure?.criteria ?? []), ...(structure?.attributes ?? [])].reduce(
        (acc: {[key: string]: string}, item: any) => {
          if ((item?.extra?.DataStructureRef ?? "").length === 0) {
            acc[item.id] = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME;
          }
          return acc;
        },
        {
          [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
        }
      );

      return {
        ...state,
        datasetUuid: datasetUuid,
        hasViewLayout: hasView,
        hasTemplateLayout: hasTemplate,
        hasAnnotationLayout:
          !!structure.layoutTable ||
          !!structure.layoutMap ||
          !!structure.layoutChartCartesian ||
          !!structure.layoutChartRadial,
        view: view,
        template: template,
        dimensions: dimensions,
        territoryDim: territoryDim,
        timeDim: timeDim,
        freqDim: freqDim,
        detailLevel: !viewTemplateLayouts
          ? null
          : viewTemplateLayouts.detailLevel !== null && viewTemplateLayouts.detailLevel !== undefined
            ? viewTemplateLayouts.detailLevel
            : viewTemplateLayouts.mapDetailLevel !== null && viewTemplateLayouts.mapDetailLevel !== undefined
              ? viewTemplateLayouts.mapDetailLevel
              : null,
        codelistsLength: state.codelistsLength ? state.codelistsLength : null,
        isCriteriaVisible: isCriteriaVisible,
        viewerIdx: viewerIdx,
        isUnavailableViewWarningVisible: viewId !== null && !structure?.[VIEW_KEY],
        tableLayoutPartial: tableLayout,
        mapLayoutPartial: mapLayout,
        chartLayoutPartialCartesian: chartLayoutCartesian,
        chartLayoutPartialRadial: chartLayoutRadial,
        labelFormat: viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
        customLabelFormats: customLabelFormats,
        temporalDimOrder:
          viewTemplateLayouts?.temporalDimOrder || structure.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
        showTrend: viewTemplateLayouts?.showTrend || false,
        showCyclical: viewTemplateLayouts?.showCyclical || false,
        splitHeaderCells:
          viewTemplateLayouts?.splitHeaderCells !== undefined && viewTemplateLayouts?.splitHeaderCells !== null
            ? viewTemplateLayouts?.splitHeaderCells
            : structure.layoutAggregateCells !== undefined && structure.layoutAggregateCells !== null
              ? decodeLayoutAggregateCells(structure.layoutAggregateCells, HEADER_CELLS_TYPE)
              : getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== undefined &&
                  getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== null
                ? decodeLayoutAggregateCells(
                    getAppConfig(action).tableDefaultSettings?.layoutAggregateCells,
                    HEADER_CELLS_TYPE
                  )
                : false,
        splitSideCells:
          viewTemplateLayouts?.splitSideCells !== undefined && viewTemplateLayouts?.splitSideCells !== null
            ? viewTemplateLayouts?.splitSideCells
            : structure.layoutAggregateCells !== undefined && structure.layoutAggregateCells !== null
              ? decodeLayoutAggregateCells(structure.layoutAggregateCells, SIDE_CELLS_TYPE)
              : getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== undefined &&
                  getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== null
                ? decodeLayoutAggregateCells(
                    getAppConfig(action).tableDefaultSettings?.layoutAggregateCells,
                    SIDE_CELLS_TYPE
                  )
                : false,
        areCriteriaApplied: !isCriteriaVisible,
        criteria: criteria,
        initialCriteria: criteria,
        decimalSeparator:
          Object.keys(viewTemplate?.decimalSeparator || {}).length > 0
            ? localizeI18nObj(viewTemplate.decimalSeparator, language, languages)
            : structure.decimalSeparator,
        roundingStrategy:
          viewTemplate?.roundingStrategy !== undefined ? viewTemplate?.roundingStrategy : structure.roundingStrategy,
        decimalPlaces:
          viewTemplate?.decimalNumber !== undefined ? viewTemplate?.decimalNumber : structure.decimalPlaces,
        tableEmptyChar:
          viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined
            ? viewTemplateLayouts?.tableEmptyChar
            : structure.emptyCellPlaceHolder,
        chartSettings: {
          ...getAppConfig(action).chartDefaultSettings,
          ...getChartSortingSettingsFromStructure(structure),
          ...getChartSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        mapSettings: {
          ...getAppConfig(action).mapDefaultSettings,
          ...getMapSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        enableCriteria: viewTemplate?.enableCriteria !== false && dimensions.length > 0,
        enableLayout: viewTemplate?.enableLayout !== false && dimensions.length > 0,
        enableVariation: viewTemplate?.enableVariation === true,
        isTableEnabled: isTableEnabled,
        isMapEnabled: isMapEnabled,
        isChartEnabled: isChartEnabled,
        tableLockedDimensions:
          hasTemplate &&
          template.layouts?.lockTableDimensions &&
          (template.layouts?.tableLockedDimensions || []).length > 0
            ? template.layouts?.tableLockedDimensions
            : structure?.tableLockedDimensions || [],
        chartLockedDimensionsCartesian: chartLockedDimensionsCartesian,
        chartLockedDimensionsRadial: chartLockedDimensionsRadial,
        lastNObservations: structure.lastNObservations,
        attributesAsDim: structure.attributesAsDim ?? []
      };
    }
    case DATASET_SVP_STRUCTURE_FETCH_ERROR: {
      return {
        ...initialState,
        enableCriteria: false,
        enableLayout: false
      };
    }
    case DATASET_SVP_DATASET_FETCH_ENABLE: {
      const newCodelistFilteredLists: {[key: string]: any[]} = getFilteredCodelistsListFromCriteria(
        state.codelistLists,
        state.criteria,
        state.timeDim,
        state.freqDim
      );

      const showSingleGeometry = getAppConfig(action)?.mapConfig?.showSingleGeometry || false;

      const dimensionsIds = (state.dimensions ?? []).map(({id}) => id);

      const tableLayout = getCompleteTableLayout(state, dimensionsIds, newCodelistFilteredLists, false, action.limit);
      const mapLayout = getCompleteMapLayout(state, dimensionsIds, newCodelistFilteredLists, false, showSingleGeometry);
      const chartLayoutCartesian = getCompleteChartLayoutCartesian(
        state,
        dimensionsIds,
        newCodelistFilteredLists,
        false
      );
      const chartLayoutRadial = getCompleteChartLayoutRadial(state, dimensionsIds, newCodelistFilteredLists, false);

      const dimensionsInfo = getDimensionsInfo(state.dimensions, newCodelistFilteredLists);
      const currentLayout =
        state.viewerIdx === 0
          ? tableLayout
          : state.viewerIdx === 1
            ? mapLayout
            : isChartLayoutCartesianByViewerIdx(state.viewerIdx)
              ? chartLayoutCartesian
              : chartLayoutRadial;
      const datasetSize = getDatasetSize(dimensionsInfo, currentLayout);

      return {
        ...state,
        isCriteriaVisible: false,
        initialCriteriaDimension: null,
        areCriteriaApplied: true,
        dimensionsInfo: dimensionsInfo,
        codelistFilteredLists: newCodelistFilteredLists,
        tableLayoutPartial: null,
        mapLayoutPartial: null,
        chartLayoutPartialCartesian: null,
        chartLayoutPartialRadial: null,
        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayoutCartesian: chartLayoutCartesian,
        chartLayoutRadial: chartLayoutRadial,
        isFetchDatasetDisabled: datasetSize > action.limit,
        isViewerHoverVisible: false
      };
    }
    case DATASET_SVP_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true,
        initialCriteriaDimension: action.initialCriteriaDimension || null
      };
    }
    case DATASET_SVP_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        initialCriteriaDimension: null,
        criteria: state.initialCriteria
      };
    }
    case DATASET_SVP_MAP_DETAIL_LEVEL_SET: {
      return {
        ...state,
        detailLevel: action.detailLevel
      };
    }
    case DATASET_SVP_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      };
    }
    case DATASET_SVP_STRUCTURE_CRITERIA_SET: {
      return {
        ...state,
        criteria: action.criteria
      };
    }
    case DATASET_SVP_VIEWER_SET: {
      return {
        ...state,
        isFetchDatasetDisabled: false,
        dataset: null,
        viewerIdx: action.viewerIdx,
        isTableVisible: action.viewerIdx === 0,
        isMapVisible: action.viewerIdx === 1,
        isChartVisible: action.viewerIdx >= 2,
        isViewerHoverVisible: false
      };
    }
    case DATASET_SVP_LAYOUT_SHOW: {
      return {
        ...state,
        isLayoutVisible: true
      };
    }
    case DATASET_SVP_LAYOUT_HIDE: {
      return {
        ...state,
        isLayoutVisible: false
      };
    }
    case DATASET_SVP_TABLE_LAYOUT_SUBMIT: {
      return {
        ...state,
        tableLayout: action.layout,
        isViewerHoverVisible: !action.enableFetch,
        isFetchDatasetDisabled: action.enableFetch ? false : state.isFetchDatasetDisabled
      };
    }
    case DATASET_SVP_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout,
        isViewerHoverVisible: !action.enableFetch,
        isFetchDatasetDisabled: action.enableFetch ? false : state.isFetchDatasetDisabled
      };
    }
    case DATASET_SVP_CHART_LAYOUT_SUBMIT: {
      return isChartLayoutCartesianByViewerIdx(state.viewerIdx)
        ? {
            ...state,
            chartLayoutCartesian: action.layout,
            isViewerHoverVisible: !action.enableFetch,
            isFetchDatasetDisabled: action.enableFetch ? false : state.isFetchDatasetDisabled
          }
        : {
            ...state,
            chartLayoutRadial: action.layout,
            isViewerHoverVisible: !action.enableFetch,
            isFetchDatasetDisabled: action.enableFetch ? false : state.isFetchDatasetDisabled
          };
    }
    case DATASET_SVP_CHART_SORTING_RESET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...(action.isChartCartesian ? initialChartSortingCartesian : initialChartSortingRadial)
        }
      };
    }
    case DATASET_SVP_LABEL_FORMAT_SET: {
      return {
        ...state,
        labelFormat: action.labelFormat
      };
    }
    case DATASET_SVP_VARIATION_SET: {
      return {
        ...state,
        showTrend: action.variation?.showTrend || false,
        showCyclical: action.variation?.showCyclical || false
      };
    }
    case DATASET_SVP_TEMPORAL_DIM_ORDER_SET: {
      return {
        ...state,
        temporalDimOrder: action.temporalDimOrder
      };
    }
    case DATASET_SVP_TABLE_SETTINGS_SET: {
      return {
        ...state,
        temporalDimOrder: action.tableSettings.temporalDimOrder,
        labelFormat: action.tableSettings.labelFormat,
        splitHeaderCells: action.tableSettings.splitHeaderCells,
        splitSideCells: action.tableSettings.splitSideCells
      };
    }
    case DATASET_SVP_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      };
    }
    case DATASET_SVP_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      };
    }
    case DATASET_SVP_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      };
    }
    case DATASET_SVP_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      };
    }
    case DATASET_SVP_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      };
    }
    case DATASET_SVP_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      };
    }
    case DATASET_SVP_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: false,
        isTemplateVisible: false
      };
    }
    case DATASET_SVP_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      };
    }
    case DATASET_SVP_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      };
    }
    case DATASET_SVP_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      };
    }
    case DATASET_SVP_STRUCTURE_CODELIST_FULL_HIDE: {
      return {
        ...state,
        missingFilterValues: null
      };
    }
    case DATASET_SVP_SET_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: action.isFullscreen
      };
    }
    case DATASET_SVP_HIERARCHY_SET: {
      const hierarchyValues = state.hierarchicalCodelists?.[action.dimensionId];
      const updatedData =
        getUpdatedSVPStateDataOnHierarchySelect(
          state,
          action.dimensionId,
          action.hierarchyId || "",
          hierarchyValues,
          action.tabId,
          action.selectedKeys
        ) || {};
      return {
        ...state,
        ...updatedData
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_SVP_CODELISTS_FETCH: {
          return {
            ...state,
            codelistFetchError: false
          };
        }
        case DATASET_SVP_DATASET_FETCH: {
          return {
            ...state,
            dataset: null,
            isFetchStarted: true,
            isFetchFailed: false,
            tableLayoutPartial: null,
            mapLayoutPartial: null,
            chartLayoutPartialCartesian: null,
            chartLayoutPartialRadial: null,
            isFetchDatasetDisabled: true
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_SVP_CODELISTS_FETCH: {
          if ((action.payload.response?.criteria ?? []).length === 0 || action.payload.statusCode === 204) {
            return initialState;
          }

          const dimensions: any[] = [];
          (action.payload.response?.criteria ?? []).forEach((dim: any) => {
            if ((dim?.values ?? []).length > 0 || (dim?.hierarchies ?? []).length > 0 || dim.id === state.timeDim) {
              const structureDim = (state.dimensions ?? []).find((strDim: any) => strDim.id === dim.id);
              dimensions.push(
                structureDim !== undefined
                  ? structureDim
                  : {
                      ...dim,
                      values: undefined,
                      extra: {
                        DataStructureRef: ""
                      }
                    }
              );
            }
          });

          const dimensionsIds = (dimensions ?? []).map(({id}) => id);

          const timeDim = dimensionsIds.includes(state.timeDim) ? state.timeDim : null;
          const freqDim = dimensionsIds.includes(state.freqDim) ? state.freqDim : null;

          const criteria = state.criteria;

          const newCriteria: {[key: string]: Criteria} = _.cloneDeep(state.criteria);
          const codelistTrees: {[key: string]: any[]} = {};
          const codelistLists: {[key: string]: string[]} = {};
          const codelistMaps: {[key: string]: {[key: string]: string}} = {};
          const codelistLength: (number | null)[] = (dimensions || []).map(() => null);
          const newHierarchicalCodelists: {[key: string]: any} = {};

          (dimensions || []).forEach((dimension, idx) => {
            const dimensionCodelist = action.payload.response.criteria.find(({id}: any) => id === dimension.id);

            if (dimensionCodelist) {
              const dimensionHasHcl = (dimension.hierarchies || []).length > 0;
              if (dimensionHasHcl) {
                const hierarchiesValues = _.cloneDeep(dimensionCodelist.hierarchiesValues || {});
                const hierarchies = Object.keys(hierarchiesValues) || [];
                const hierarchyId = dimension.hierarchyId || "";
                const dimensionCriteria = criteria?.[dimension.id];
                const dimensionCriteriaHierarchyId = dimensionCriteria?.hierarchyId || "";
                const hasToSelectHierarchy =
                  dimensionCriteriaHierarchyId !== "" || hierarchyId !== "" || hierarchies.length === 1;
                hierarchies.forEach(hierId => {
                  let hierarchyValues = hierarchiesValues[hierId];
                  hierarchyValues = getMappedTree(hierarchyValues, "hierarchyCodes", (node: any) => ({
                    ...node,
                    label: `[${node.id}] ${node.name}`
                  }));
                  hierarchiesValues[hierId] = hierarchyValues;
                });
                let selectedHierarchy = "";
                if (hasToSelectHierarchy) {
                  selectedHierarchy =
                    dimensionCriteriaHierarchyId !== ""
                      ? dimensionCriteriaHierarchyId
                      : hierarchyId !== ""
                        ? hierarchyId
                        : hierarchies[0];
                }
                const updatedData = getUpdatedSVPStateDataOnHierarchySelect(
                  state,
                  dimension.id,
                  selectedHierarchy,
                  hierarchiesValues,
                  idx,
                  dimensionCriteria?.filterValues || []
                );
                codelistLists[dimension.id] = updatedData.codelistLists[dimension.id];
                codelistTrees[dimension.id] = updatedData.codelistTrees[dimension.id];
                codelistLength[idx] = updatedData.codelistsLength[idx];
                codelistMaps[dimension.id] = updatedData.codelistMaps[dimension.id];
                newCriteria[dimension.id] = updatedData.criteria[dimension.id];

                newHierarchicalCodelists[dimension.id] = hierarchiesValues;
              } else {
                const values: any[] = [];
                const ids: string[] = [];
                const map: {[key: string]: string} = {};
                (dimensionCodelist.values || []).forEach((code: any) => {
                  values.push({
                    ...code,
                    label: `[${code.id}] ${code.name}`
                  });
                  map[code.id] = code.name;
                  if (code.isSelectable) {
                    ids.push(code.id);
                  }
                });

                codelistTrees[dimension.id] = getTreeFromArray(values, "parentId", "children");
                codelistLists[dimension.id] = ids;
                codelistMaps[dimension.id] = map;

                if (!timeDim || dimension.id !== timeDim) {
                  codelistLength[idx] = ids.length;
                  if (values.length === 1) {
                    newCriteria[dimension.id] = {
                      id: dimension.id,
                      type: CRITERIA_FILTER_TYPE_CODES,
                      filterValues: [values[0].id],
                      period: undefined,
                      from: undefined,
                      to: undefined
                    };
                  }
                } else if (!newCriteria[timeDim]) {
                  if (freqDim && isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1)) {
                    newCriteria[timeDim] = {
                      id: timeDim,
                      type: CRITERIA_FILTER_TYPE_PERIODS,
                      period: action.payload.extra.defaultLastNPeriods,
                      filterValues: undefined,
                      from: undefined,
                      to: undefined
                    };
                  } else {
                    const {min, max} = getMinAndMax(values, action.payload.extra.freq, getCurrentNodeConfig(action));
                    newCriteria[timeDim] = {
                      id: timeDim,
                      type: CRITERIA_FILTER_TYPE_RANGE,
                      from: min,
                      to: max,
                      filterValues: undefined,
                      period: undefined
                    };
                  }
                }
              }
            } else {
              codelistTrees[dimension.id] = [];
              codelistLists[dimension.id] = [];
              codelistMaps[dimension.id] = {};
            }
          });

          if (timeDim) {
            const freqs = freqDim && codelistLists[freqDim] ? codelistLists[freqDim] : [FREQ_ANNUAL];
            const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;
            const {min, max} = getMinAndMax(codelistTrees[timeDim], freq, getCurrentNodeConfig(action));

            codelistLists[timeDim] = getTimeValuesFromRange(freqs, moment(min), moment(max));
            codelistMaps[timeDim] = {};
          }

          const newCodelistFilteredLists: {[key: string]: any[]} = getFilteredCodelistsListFromCriteria(
            codelistLists,
            newCriteria,
            timeDim,
            freqDim
          );

          const isFetchEnabled = state.hasViewLayout || state.hasTemplateLayout || !_.isEmpty(state.criteria);
          const hasLastNPeriods = timeDim ? newCriteria[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS : false;

          const showSingleGeometry = getAppConfig(action)?.mapConfig?.showSingleGeometry || false;

          const tableLayout = isFetchEnabled
            ? getCompleteTableLayout(state, dimensionsIds, newCodelistFilteredLists, hasLastNPeriods, action.limit)
            : state.tableLayout;
          const mapLayout = isFetchEnabled
            ? getCompleteMapLayout(state, dimensionsIds, newCodelistFilteredLists, hasLastNPeriods, showSingleGeometry)
            : state.mapLayout;
          const chartLayoutCartesian = isFetchEnabled
            ? getCompleteChartLayoutCartesian(state, dimensionsIds, newCodelistFilteredLists, hasLastNPeriods)
            : state.chartLayoutCartesian;
          const chartLayoutRadial = isFetchEnabled
            ? getCompleteChartLayoutRadial(state, dimensionsIds, newCodelistFilteredLists, hasLastNPeriods)
            : state.chartLayoutRadial;

          return {
            ...state,
            dimensions: dimensions,
            dimensionsInfo: getDimensionsInfo(dimensions, newCodelistFilteredLists),
            tableLayout: tableLayout,
            mapLayout: mapLayout,
            chartLayoutCartesian: chartLayoutCartesian,
            chartLayoutRadial: chartLayoutRadial,
            criteria: newCriteria,
            initialCriteria: newCriteria,
            codelistTrees: codelistTrees,
            codelistLists: codelistLists,
            hierarchicalCodelists: newHierarchicalCodelists,
            codelistFilteredLists: newCodelistFilteredLists,
            codelistMaps: codelistMaps,
            codelistsLength: codelistLength,
            isFetchDatasetDisabled: !isFetchEnabled
          };
        }
        case DATASET_SVP_DATASET_FETCH: {
          const isEmptyData = action.payload.extra.status === 204 || (action.payload.response?.id || "").length === 0;
          const isPartialData = action.payload.extra.status === 206;

          const layout =
            state.viewerIdx === 0
              ? state.tableLayout
              : state.viewerIdx === 1
                ? state.mapLayout
                : isChartLayoutCartesianByViewerIdx(state.viewerIdx)
                  ? state.chartLayoutCartesian
                  : state.chartLayoutRadial;
          const filterCombinationCount: number = (layout?.filters || []).reduce(
            (acc: number, dim: string) => acc * (state.codelistFilteredLists?.[dim] || []).length,
            1
          );

          return {
            ...state,
            dataset: isEmptyData ? emptyJsonStat : action.payload.response,
            isFetchStarted: false,
            isFetchFailed: false,

            isEmptyData: isEmptyData,
            isPartialData: isPartialData,

            isCriteriaVisible: state.isCriteriaVisible && isEmptyData && filterCombinationCount === 1,
            isCriteriaAlertVisible: isEmptyData && filterCombinationCount === 1,

            initialCriteria: state.criteria,

            timings: {
              [OBSERVATION_COUNT_KEY]: action.payload.response?.value
                ? Object.keys(action.payload.response.value).length
                : null,
              [SERVER_TIMINGS_KEY]: action.payload.extra.responseHeaders?.backendtimers
                ? JSON.parse(action.payload.extra.responseHeaders.backendtimers)
                : null
            },

            structureQuery: null,
            dataQuery: null
          };
        }
        case DATASET_SVP_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
                ...state,
                isViewVisible: false,
                isTemplateVisible: false
              }
            : {
                ...initialState
              };
        }
        case DATASET_SVP_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          };
        }
        case DATASET_SVP_STRUCTURE_CODELIST_FULL_FETCH: {
          const codelist = (action.payload.response.criteria[0] || {}).values || [];
          return {
            ...state,
            missingFilterValues: codelist.filter((code: any) =>
              action.payload.extra.missingFilterValueIds.includes(code.id)
            )
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_SVP_CODELISTS_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery,
            codelistFetchError: true
          };
        }
        case DATASET_SVP_DATASET_FETCH: {
          const isPayloadTooLarge = action.payload.statusCode === 413;
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isFetchStarted: false,
            isFetchFailed: true,
            isPartialData: false,
            isEmptyData: false,
            isTooBigData: isPayloadTooLarge,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isPayloadTooLarge || isTooLongQuery
          };
        }
        case DATASET_SVP_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          };
        }
        case DATASET_SVP_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(
              action.payload.extra.isView &&
              action.payload.statusCode === 409 &&
              action.payload.response
            ),
            viewErrorMessage: action.payload.response
          };
        }
        case DATASET_SVP_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default datasetSVPReducer;
