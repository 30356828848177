import React, {Fragment, useEffect, useState} from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import FilterAlt from "@mui/icons-material/FilterAlt";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ChartLayout from "../../chart-layout";
import {CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT} from "../../chart/constants";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import DatasetMetadataButton from "../../dataset-metadata-button";
import FullscreenDialog from "../../fullscreen-dialog";
import TableLayout from "../../table-layout";
import {getDimensionsWithHclWithError, isChartLayoutCartesianByViewerIdx, viewersFactory} from "../constant";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  enableDatasetSVDatasetFetch,
  fetchDatasetSVStructureCodelist,
  fetchDatasetSVStructureCodelistFull,
  hideDatasetSVCriteria,
  hideDatasetSVCriteriaAlert,
  hideDatasetSVCriteriaObsCountWarning,
  hideDatasetSVLayout,
  hideDatasetSVStructureCodelistFull,
  resetSVChartSorting,
  setDatasetSVHierarchy,
  setDatasetSVStructureCriteria,
  setDatasetSVViewer,
  showDatasetSVCriteria,
  showDatasetSVLayout,
  submitDatasetSVChartFilterTree,
  submitDatasetSVChartLayout,
  submitDatasetSVTableFilterTree,
  submitDatasetSVTableLayout
} from "../../../state/dataset/single-viewer/actions";
import {
  getDatasetSize,
  getDimensionFilterValues,
  getFilterTreeFromJsonStat,
  getInitialFiltersValue,
  MAX_ALLOWED_CELLS
} from "../../../utils/dataset";
import {
  FREQ_ANNUAL,
  getMinAndMax,
  getTimeValuesFromRange,
  SUPPORTED_FREQ_VALUES
} from "../../../utils/timePeriodAndFreq";
import {getNodes} from "../../../utils/tree";

const navigationActionDisabledStyle = {
  color: "rgba(0, 0, 0, 0.26) !important",
  "& svg": {
    color: "rgba(0, 0, 0, 0.26)"
  }
};

const mapStateToProps = ({hub, node, dataset}) => {
  const viewerIdx = dataset.singleViewer.viewerIdx;

  const chartLayoutCartesian = dataset.singleViewer.chartLayoutCartesian;
  const chartLayoutRadial = dataset.singleViewer.chartLayoutRadial;

  const chartLockedDimensionsCartesian = dataset.singleViewer.chartLockedDimensionsCartesian;
  const chartLockedDimensionsRadial = dataset.singleViewer.chartLockedDimensionsRadial;

  return {
    maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
    maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
    node: node,
    mode: dataset.commons.mode,
    type: dataset.commons.type,
    jsonStat: dataset.singleViewer.dataset,
    isEmptyData: dataset.singleViewer.isEmptyData,
    isTooBigData: dataset.singleViewer.isTooBigData,
    isTooLongQuery: dataset.singleViewer.isTooLongQuery,
    isCriteriaVisible: dataset.singleViewer.isCriteriaVisible,
    isCriteriaAlertVisible: dataset.singleViewer.isCriteriaAlertVisible,
    isObsCountWarningVisible: dataset.singleViewer.isObsCountWarningVisible,
    dimensions: dataset.singleViewer.dimensions,
    dimensionsInfo: dataset.singleViewer.dimensionsInfo,
    timeDim: dataset.singleViewer.timeDim,
    freqDim: dataset.singleViewer.freqDim,
    viewerIdx: dataset.singleViewer.viewerIdx,
    templateLayouts: dataset.singleViewer.template?.layouts,
    isLayoutVisible: dataset.singleViewer.isLayoutVisible,
    tableLayout: dataset.singleViewer.tableLayout,
    mapLayout: dataset.singleViewer.mapLayout,
    chartLayout: isChartLayoutCartesianByViewerIdx(viewerIdx) ? chartLayoutCartesian : chartLayoutRadial,
    labelFormat: dataset.singleViewer.labelFormat,
    criteria: dataset.singleViewer.criteria,
    enableCriteria: dataset.singleViewer.enableCriteria,
    enableLayout: dataset.singleViewer.enableLayout,
    codelists: dataset.singleViewer.codelists,
    hierarchicalCodelists: dataset.singleViewer.hierarchicalCodelists,
    codelistsLength: dataset.singleViewer.codelistsLength,
    codelistFetchError: dataset.singleViewer.codelistFetchError,
    isTableEnabled: dataset.singleViewer.isTableEnabled,
    isMapEnabled: dataset.singleViewer.isMapEnabled,
    isChartEnabled: dataset.singleViewer.isChartEnabled,
    tableLockedDimensions: dataset.singleViewer.tableLockedDimensions,
    chartLockedDimensions: isChartLayoutCartesianByViewerIdx(viewerIdx)
      ? chartLockedDimensionsCartesian
      : chartLockedDimensionsRadial,
    missingFilterValues: dataset.singleViewer.missingFilterValues,
    checkDatasetSize: dataset.singleViewer.checkDatasetSize,
    chartSettings: dataset.singleViewer.chartSettings
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservations => dispatch(enableDatasetSVDatasetFetch(maxObservations)),
  onViewerSet: viewerIdx => dispatch(setDatasetSVViewer(viewerIdx)),
  onLayoutShow: () => dispatch(showDatasetSVLayout()),
  onLayoutHide: () => dispatch(hideDatasetSVLayout()),
  onTableLayoutSet: layout => dispatch(submitDatasetSVTableLayout(layout)),
  onTableFilterTreeSet: filterTree => dispatch(submitDatasetSVTableFilterTree(filterTree)),
  onChartLayoutSet: layout => dispatch(submitDatasetSVChartLayout(layout)),
  onChartFilterTreeSet: filterTree => dispatch(submitDatasetSVChartFilterTree(filterTree)),
  onChartSortingReset: isChartCartesian => dispatch(resetSVChartSorting(isChartCartesian)),
  onCriteriaShow: () => dispatch(showDatasetSVCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetSVCriteria()),
  onSetCriteria: (criteria, clearHcl) => dispatch(setDatasetSVStructureCriteria(criteria, clearHcl)),
  onCriteriaAlertHide: () => dispatch(hideDatasetSVCriteriaAlert()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic
  ) =>
    dispatch(
      fetchDatasetSVStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetSVCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetSVStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetSVStructureCodelistFull()),
  onHierarchyChange: (dimensionId, hierarchyId, tabId, selectedKeys) =>
    dispatch(setDatasetSVHierarchy(dimensionId, hierarchyId, tabId, selectedKeys))
});

function SingleViewerSidebar(props) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetMap,

    maxObservations,
    maxCells,
    node,
    mode,
    type,
    jsonStat,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    isObsCountWarningVisible,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    viewerIdx,
    templateLayouts,
    isLayoutVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    criteria,
    enableCriteria,
    enableLayout,
    codelists,
    hierarchicalCodelists,
    codelistsLength,
    codelistFetchError,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,
    tableLockedDimensions,
    chartLockedDimensions,
    missingFilterValues,
    checkDatasetSize,

    onFetchDatasetEnable,
    onViewerSet,
    onLayoutShow,
    onLayoutHide,
    onTableLayoutSet,
    onTableFilterTreeSet,
    onChartLayoutSet,
    onChartFilterTreeSet,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onCriteriaAlertHide,
    fetchCodelist,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide,
    onHierarchyChange,
    chartSettings,
    onChartSortingReset
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  const availableCharts = (
    nodeExtras?.ChartsSettings?.length > 0
      ? viewers.filter(view => nodeExtras?.ChartsSettings?.some(obj => obj.chartType === view.chartType))
      : nodeExtras?.ChartsSettings
        ? []
        : viewers.filter(chart => chart.type !== "map" && chart.type !== "table")
  ).filter(({hidden}) => hidden !== true);

  const [anchorEl, setAnchorEl] = useState(null);

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [isObsCountErrorVisible, setObsCountErrorVisibility] = useState(false);

  const [dimensionsWithHierarchiesError, setDimensionsWithHierarchiesError] = useState([]);
  const [isHierarchiesErrorVisible, setHierarchiesErrorVisibility] = useState(false);

  const [isLayoutDialogVisible, setLayoutDialogVisible] = useState(false);

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  const handleViewerSelect = index => {
    onViewerSet(index);
    setAnchorEl(null);
  };

  const handleLayoutOpen = () => {
    onLayoutShow();
  };

  const handleLayoutClose = () => {
    onLayoutHide();
    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    if (viewerIdx === 0) {
      let filtersValue = tableLayout.filtersValue;

      if (tableLayout.filters.join("+") !== tmpTableLayout.filters.join("+")) {
        const newTableFilterTree = getFilterTreeFromJsonStat(tmpTableLayout.filters, jsonStat);
        onTableFilterTreeSet(newTableFilterTree);
        filtersValue = getInitialFiltersValue(jsonStat, tmpTableLayout, newTableFilterTree);
      }

      onTableLayoutSet({
        ...tmpTableLayout,
        filtersValue: filtersValue
      });
      onLayoutHide();
    } else if (viewerIdx >= 2) {
      const newPrimaryDim = tmpChartLayout.primaryDim[0];
      const newSecondaryDim = tmpChartLayout.secondaryDim[0];
      const prevPrimaryDim = chartLayout.primaryDim[0];
      const prevSecondaryDim = chartLayout.secondaryDim[0];

      const obsSortingOrder = isChartLayoutCartesianByViewerIdx(viewerIdx)
        ? chartSettings.obsSortingOrderCartesian
        : chartSettings.obsSortingOrderRadial;

      const isDimChanged = newPrimaryDim !== prevPrimaryDim || newSecondaryDim !== prevSecondaryDim;
      const isChartSorted = obsSortingOrder !== CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT;

      if (isDimChanged && isChartSorted) {
        setLayoutDialogVisible(true);
      } else {
        handleChartLayoutSet(false);
      }
    }
  };

  const handleChartLayoutSet = resetChartSorting => {
    const newPrimaryDim = tmpChartLayout.primaryDim[0];
    const newSecondaryDim = tmpChartLayout.secondaryDim[0];
    const prevPrimaryDim = chartLayout.primaryDim[0];
    const prevSecondaryDim = chartLayout.secondaryDim[0];

    const isSecondaryDimChanged =
      (!prevSecondaryDim && !!newSecondaryDim) ||
      (!!prevSecondaryDim && !newSecondaryDim) ||
      (!!prevSecondaryDim && !!newSecondaryDim && prevSecondaryDim !== newSecondaryDim);

    const filters = [...tmpChartLayout.primaryDim, ...tmpChartLayout.secondaryDim, ...tmpChartLayout.filters];
    const newChartFilterTree = getFilterTreeFromJsonStat(filters, jsonStat);
    if (newPrimaryDim !== prevPrimaryDim) {
      tmpChartLayout.primaryDimValues = jsonStat.dimension[newPrimaryDim].category.index;
      tmpChartLayout.secondaryDimValues = newSecondaryDim
        ? getDimensionFilterValues(newSecondaryDim, jsonStat, tmpChartLayout, newChartFilterTree)
        : [];
    } else if (isSecondaryDimChanged) {
      tmpChartLayout.secondaryDimValues = newSecondaryDim
        ? getDimensionFilterValues(newSecondaryDim, jsonStat, tmpChartLayout, newChartFilterTree)
        : [];
    }
    onChartLayoutSet({
      ...tmpChartLayout,
      filtersValue: getInitialFiltersValue(jsonStat, tmpChartLayout, newChartFilterTree)
    });
    onChartFilterTreeSet(newChartFilterTree);
    if (resetChartSorting) {
      onChartSortingReset(isChartLayoutCartesianByViewerIdx(viewerIdx));
    }
    onLayoutHide();
    setLayoutDialogVisible(false);
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = () => {
    const dimensionsWithError = getDimensionsWithHclWithError(dimensions, criteria);
    if (dimensionsWithError.length > 0) {
      setDimensionsWithHierarchiesError(dimensionsWithError);
      setHierarchiesErrorVisibility(true);
      return;
    }
    if (!checkDatasetSize) {
      onFetchDatasetEnable(maxObservations);
    } else {
      const datasetSize = (dimensions || []).reduce((acc, {id: dimId}, idx) => {
        if (dimId !== timeDim) {
          if ((criteria[dimId]?.filterValues || []).length > 0) {
            return acc * criteria[dimId].filterValues.length;
          } else {
            return acc * codelistsLength[idx];
          }
        } else {
          const freqs = freqDim
            ? (criteria[freqDim]?.filterValues || []).length > 0
              ? criteria[freqDim].filterValues
              : getNodes(codelists[freqDim], "children").map(({id}) => id)
            : [FREQ_ANNUAL];
          const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;
          const {min, max} = getMinAndMax(codelists[timeDim], freq, node);

          if (criteria[timeDim]?.from && criteria[timeDim]?.to) {
            const timeValues = getTimeValuesFromRange(
              freqs,
              moment(criteria[timeDim].from),
              moment(criteria[timeDim].to)
            );
            return acc * timeValues.length;
          } else if (criteria[timeDim]?.period) {
            const timeValuesByFreq = getTimeValuesFromRange([freq], moment(min), moment(max));
            const from =
              criteria[timeDim].period > timeValuesByFreq.length
                ? min
                : timeValuesByFreq[timeValuesByFreq.length - criteria[timeDim].period];
            const to = timeValuesByFreq[timeValuesByFreq.length - 1];
            const timeValues = getTimeValuesFromRange(freqs, from, to);
            return acc * timeValues.length;
          } else {
            const timeValues = getTimeValuesFromRange(freqs, moment(min), moment(max));
            return acc * timeValues.length;
          }
        }
      }, 1);

      if (datasetSize > maxObservations) {
        setObsCountErrorVisibility(true);
      } else {
        onFetchDatasetEnable(maxObservations);
      }
    }
  };

  const isCriteriaDisabled = !enableCriteria;
  const isLayoutDisabled = !enableLayout || !jsonStat || !(!!tableLayout || !!mapLayout || !!chartLayout);
  const isTableDisabled = !jsonStat || !tableLayout;
  const isChartDisabled = !jsonStat || !chartLayout;
  const isMapDisabled = !jsonStat || !mapLayout;

  const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

  const datasetSize = getDatasetSize(dimensionsInfo, tmpTableLayout);

  return (
    <Fragment>
      <Card
        sx={{
          width: "80px",
          height: "100%",
          overflow: "auto",
          padding: "8px 0",
          marginRight: "16px",
          "& .MuiBottomNavigation-root": {
            height: "unset !important"
          },
          "& .MuiBottomNavigationAction-label": {
            fontSize: "13px !important"
          }
        }}
        role="menu"
      >
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            id="data-viewer__sidebar__criteria-btn"
            label={t("scenes.dataViewer.singleViewer.sidebar.criteria")}
            icon={<FilterAlt />}
            sx={isCriteriaDisabled ? navigationActionDisabledStyle : null}
            disabled={isCriteriaDisabled}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels onChange={handleLayoutOpen}>
          <BottomNavigationAction
            id="data-viewer__sidebar__layout-btn"
            label={t("scenes.dataViewer.singleViewer.sidebar.layout")}
            icon={<EditIcon />}
            sx={isLayoutDisabled || viewerIdx === 1 ? navigationActionDisabledStyle : null}
            disabled={isLayoutDisabled || viewerIdx === 1}
            role="menuitem"
          />
        </BottomNavigation>
        <Divider sx={{margin: "4px 0"}} />

        {metadataUrl && (
          <Fragment>
            <DatasetMetadataButton metadataUrl={metadataUrl} datasetId={datasetId} nodeId={nodeId} />
            <Divider sx={{margin: "4px 0"}} />
          </Fragment>
        )}

        {isTableEnabled && !templateLayouts?.disableTable && (
          <BottomNavigation showLabels value={viewerIdx === 0 ? 0 : null} onChange={() => handleViewerSelect(0)}>
            <BottomNavigationAction
              id="data-viewer__sidebar__table-btn"
              label={viewers[0].title}
              icon={viewers[0].icon}
              sx={isTableDisabled ? navigationActionDisabledStyle : null}
              disabled={isTableDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isChartEnabled && !templateLayouts?.disableChart && availableCharts && availableCharts.length > 0 && (
          <BottomNavigation
            showLabels
            value={viewerIdx >= 2 ? 0 : null}
            onChange={({currentTarget}) => setAnchorEl(currentTarget)}
          >
            <BottomNavigationAction
              id="data-viewer__sidebar__chart-btn"
              label={viewerIdx >= 2 ? viewers[viewerIdx].title : t("scenes.dataViewer.singleViewer.sidebar.chart")}
              icon={viewerIdx >= 2 ? viewers[viewerIdx].icon : <BarChartIcon />}
              sx={isChartDisabled ? navigationActionDisabledStyle : null}
              disabled={isChartDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isMapEnabled && !templateLayouts?.disableMap && !isMapDisabled && (
          <BottomNavigation showLabels value={viewerIdx === 1 ? 0 : null} onChange={() => handleViewerSelect(1)}>
            <BottomNavigationAction
              id="data-viewer__sidebar__map-btn"
              label={viewers[1].title}
              icon={viewers[1].icon}
              sx={isMapDisabled ? navigationActionDisabledStyle : null}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {availableCharts && availableCharts.length > 0 && (
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {availableCharts.map(option => (
              <MenuItem
                id={`data-viewer__sidebar__available-charts-${option.key}-btn`}
                key={option.key}
                sx={theme => [{color: "gray"}, viewerIdx === option.key && {color: theme.palette.primary.main}]}
                selected={viewerIdx === option.key}
                onClick={() => handleViewerSelect(option.key)}
              >
                {option.icon}
                <Typography sx={{marginLeft: "8px"}}>{option.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}

        <Dialog
          id="data-viewer__sidebar__obs-count-error-dialog"
          open={isObsCountErrorVisible}
          maxWidth="md"
          onClose={() => setObsCountErrorVisibility(false)}
        >
          <CustomDialogTitle onClose={() => setObsCountErrorVisibility(false)}>
            {t("scenes.dataViewer.singleViewer.dialogs.obsCountError.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.dataViewer.singleViewer.dialogs.obsCountError.content")}</DialogContent>
          <DialogActions>
            <Button
              id="data-viewer__sidebar__obs-count-error-close-btn"
              onClick={() => setObsCountErrorVisibility(false)}
            >
              {t("commons.confirm.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <FullscreenDialog open={isCriteriaVisible} onClose={handleCriteriaClose} id={"criteria-dialog"}>
          <CustomDialogTitle onClose={handleCriteriaClose}>
            {t("scenes.dataViewer.singleViewer.dialogs.criteria.title")}
          </CustomDialogTitle>
          <DialogContent>
            <Criteria
              viewerMode={ViewerMode.SingleViewer}
              nodeId={nodeId}
              nodeCode={nodeCode}
              datasetId={datasetId}
              dimensions={dimensions}
              timeDim={timeDim}
              freqDim={freqDim}
              mode={mode}
              type={type}
              criteria={criteria}
              onSetCriteria={onSetCriteria}
              codelists={codelists}
              hierarchicalCodelists={hierarchicalCodelists}
              codelistsLength={codelistsLength}
              fetchCodelist={fetchCodelist}
              codelistFetchError={codelistFetchError}
              isCriteriaValid={isCriteriaValid}
              setCriteriaValidity={setCriteriaValidity}
              isObsCountWarningVisible={isObsCountWarningVisible}
              onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
              onSubmit={handleCriteriaSubmit}
              defaultLastNPeriods={defaultLastNPeriods}
              showCodelistInfo
              missingFilterValues={missingFilterValues}
              fetchCodelistFull={fetchCodelistFull}
              onCodelistFullHide={onCodelistFullHide}
              onHierarchyChange={onHierarchyChange}
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__criteria-cancel-btn" onClick={handleCriteriaClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={handleCriteriaSubmit}
              disabled={!isCriteriaValid}
              id="data-viewer__sidebar__criteria-confirm-btn"
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </FullscreenDialog>

        <Dialog
          id="data-viewer__sidebar__table-layout-dialog"
          open={isLayoutVisible && viewerIdx === 0}
          fullWidth
          maxWidth="md"
          onClose={handleLayoutClose}
        >
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.tableLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <TableLayout
              layout={tmpTableLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={tableLockedDimensions}
              setLayout={setTmpTableLayout}
              jsonStat={jsonStat}
              labelFormat={labelFormat}
              alertText={
                datasetSize > maxCells ? t("scenes.dataViewer.singleViewer.sidebar.alerts.table.tooMuchCells") : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__table-layout-cancel-btn" onClick={handleLayoutClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="data-viewer__sidebar__table-layout-confirm-btn"
              autoFocus
              onClick={handleLayoutSubmit}
              disabled={datasetSize > maxCells}
              color="primary"
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          id="data-viewer__sidebar__chart-layout-dialog"
          open={isLayoutVisible && viewerIdx >= 2}
          fullWidth
          maxWidth="md"
          onClose={handleLayoutClose}
        >
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.chartLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <ChartLayout
              layout={tmpChartLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={chartLockedDimensions}
              setLayout={setTmpChartLayout}
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__chart-layout-cancel-btn" onClick={handleLayoutClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="data-viewer__sidebar__chart-layout-confirm-btn"
              autoFocus
              onClick={handleLayoutSubmit}
              color="primary"
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      <Dialog
        id="data-viewer__sidebar__criteria-alert-dialog"
        open={isCriteriaAlertVisible}
        fullWidth
        maxWidth="sm"
        onClose={onCriteriaAlertHide}
      >
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            id="data-viewer__sidebar__criteria-alert-close-btn"
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              onCriteriaShow();
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__sidebar__hierarchies-error-dialog"
        open={isHierarchiesErrorVisible}
        maxWidth="md"
        onClose={() => setHierarchiesErrorVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setHierarchiesErrorVisibility(false)}>
          {t("scenes.dataViewer.singleViewer.dialogs.hierarchiesError.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Trans
            i18nKey={"scenes.dataViewer.singleViewer.dialogs.hierarchiesError.content"}
            values={{dimensions: dimensionsWithHierarchiesError.join(", ")}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="data-viewer__sidebar__hierarchies-error-close-btn"
            onClick={() => setHierarchiesErrorVisibility(false)}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="sidebar-chart-layout__dialog"
        open={isLayoutDialogVisible}
        maxWidth="xs"
        onClose={() => setLayoutDialogVisible(false)}
      >
        <CustomDialogTitle onClose={() => setLayoutDialogVisible(false)}>
          {t("scenes.dataViewer.singleViewer.dialogs.layoutDialog.title")}
        </CustomDialogTitle>
        <DialogContent>{t("scenes.dataViewer.singleViewer.dialogs.layoutDialog.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setLayoutDialogVisible(false)}>{t("commons.confirm.cancel")}</Button>
          <Button onClick={() => handleChartLayoutSet(true)}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleViewerSidebar);
