import React, {Fragment, useEffect, useState} from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import {NodeDataAccessibility, NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import AttributeList from "../../attribute-list";
import ButtonSelect from "../../button-select";
import CustomDialogTitle from "../../custom-dialog-title";
import AttributeIcon from "../../custom-icons/AttributeIcon";
import DatasetMetadataButton, {VIEW_MODE_ICON_BUTTON} from "../../dataset-metadata-button";
import I18nTextField from "../../i18n-text-field";
import ModulesPlaceholder from "../../modules-placeholder";
import Query from "../../query";
import DatasetViewerTools from "../DatasetViewerTools";
import DataViewerTimings from "../Timings";
import MultiViewerTemplateBuilder from "./TemplateBuilder";
import {ViewerMode} from "../../../state/dataset/constants";
import {setDatasetViewerMode} from "../../../state/dataset/datasetActions";
import {
  fetchDatasetMVSdmxQuery,
  hideDatasetMVSdmxQuery,
  hideDatasetMVViewError,
  hideDatasetMVViewTemplate,
  showDatasetMVSdmxQuery,
  showDatasetMVViewTemplate,
  submitDatasetMVViewTemplate
} from "../../../state/dataset/multi-viewer/actions";
import {MERGED_DATASETS_TITLE_SEPARATOR} from "../../../utils/constants";
import {CRITERIA_FILTER_TYPE_PERIODS, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../../utils/dataset";
import {DECIMAL_SEPARATOR_DEFAULT, ROUNDING_STRATEGY} from "../../../utils/formatters";
import {validateI18nObj} from "../../../utils/i18n";
import {
  canSaveAsView,
  canSaveTemplate,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

const $ = window.jQuery;

const mapStateToProps = ({app, user, hub, dataset}) => ({
  themeConfig: app.themeConfig,
  defaultLanguage: app.language,
  user: user,
  nodes: hub.nodes,
  dataset: dataset.multiViewer.dataset,
  timeDim: dataset.multiViewer.timeDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  chartType: dataset.multiViewer.chartType,
  view: dataset.multiViewer.view,
  template: dataset.multiViewer.template,
  hasViewLayout: dataset.multiViewer.hasViewLayout,
  hasTemplateLayout: dataset.multiViewer.hasTemplateLayout,
  hasAnnotationLayout: dataset.multiViewer.hasAnnotationLayout,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  labelFormat: dataset.multiViewer.labelFormat,
  temporalDimOrder: dataset.multiViewer.temporalDimOrder,
  criteria: dataset.multiViewer.criteria,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  roundingStrategy: dataset.multiViewer.roundingStrategy,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  timings: dataset.multiViewer.timings,
  isViewVisible: dataset.multiViewer.isViewVisible,
  isViewErrorVisible: dataset.multiViewer.isViewErrorVisible,
  viewErrorMessage: dataset.multiViewer.viewErrorMessage,
  isTemplateVisible: dataset.multiViewer.isTemplateVisible,
  isQueryVisible: dataset.multiViewer.isQueryVisible,
  structureQuery: dataset.multiViewer.structureQuery,
  dataQuery: dataset.multiViewer.dataQuery,
  detailLevel: dataset.multiViewer.detailLevel,
  territories: dataset.multiViewer.territories,
  territorialClassificationsConfig: dataset.multiViewer.territorialClassificationsConfig,
  indicators: dataset.multiViewer.indicators,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  additionalDatasets: dataset.multiViewer.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetMVViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetMVViewTemplate(isView)),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) =>
    dispatch(submitDatasetMVViewTemplate(nodeId, viewTemplate, isView)),
  onViewErrorHide: isView => dispatch(hideDatasetMVViewError(isView)),
  onQueryShow: () => dispatch(showDatasetMVSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetMVSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetMVSdmxQuery(nodeId, datasetId, criteria, datasetTitle)),
  setViewerMode: viewerMode => dispatch(setDatasetViewerMode(viewerMode))
});

const areNodesAllowedForSaveViewTemplate = (datasets, nodes) => {
  const datasetNodes = datasets.map(({nodeCode}) => nodeCode);
  return (
    nodes
      .filter(({code}) => datasetNodes.includes(code))
      .findIndex(
        ({visible, dataAccess}) => visible === NodeVisibility.No && dataAccess === NodeDataAccessibility.Profiled
      ) === -1
  );
};

function MultiViewerHeader({...props}) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    onRender,
    datasetMap,

    themeConfig,
    defaultLanguage,
    user,
    nodes,
    dataset,
    timeDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    view,
    template,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    temporalDimOrder,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    timings,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    isQueryVisible,
    structureQuery,
    dataQuery,
    detailLevel,
    territories,
    territorialClassificationsConfig,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    additionalDatasets,

    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onQueryShow,
    onQueryHide,
    fetchQuery,
    setViewerMode
  } = props;
  const showQueryInfo = nodeExtras?.QueryInfo;

  const {t} = useTranslation();

  const [tempView, setTempView] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);

  const [datasetAttributeMap, setDatasetAttributeMap] = useState({});
  const [seriesAttributeMap, setSeriesAttributeMap] = useState({});

  const [notes, setNotes] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    const headerExtraActionsWidth = $("#data-viewer__header__extra-actions").outerWidth(true);
    $("#data-viewer__header__title-actions-container").width(`calc(100% - ${headerExtraActionsWidth}px)`);
  }, []);

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__actions").outerWidth(true);
    $("#data-viewer__header__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  useEffect(() => {
    setDatasetAttributeMap(getDatasetAttributeMap(dataset));
  }, [dataset]);

  useEffect(() => {
    setSeriesAttributeMap(getSeriesAttributeMap(dataset, labelFormat));
  }, [dataset, labelFormat]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  const getCompleteViewTemplate = (viewTemplate, isUpdating) => ({
    defaultView: "table",
    enableCriteria: true,
    enableLayout: true,
    enableVariation: false,
    hiddenDimensions: [],
    ...viewTemplate,
    datasetId: datasetId,
    title: isUpdating
      ? viewTemplate.title
      : {
          [defaultLanguage]: [datasetTitle, ...additionalDatasets.map(({datasetTitle}) => datasetTitle)].join(
            ` ${MERGED_DATASETS_TITLE_SEPARATOR} `
          )
        },
    mode: ViewerMode.MultiViewer,
    criteria: getCriteriaArrayFromObject(criteria),
    layouts: {
      ...viewTemplate?.layouts,
      detailLevel: detailLevel,
      mapDetailLevel: undefined,
      territories: territories,
      territorialClassificationsConfig: territorialClassificationsConfig,
      labelFormat: labelFormat,
      temporalDimOrder: temporalDimOrder,
      isTableVisible: isTableVisible,
      isMapVisible: isMapVisible,
      isChartVisible: isChartVisible,
      chartType: chartType,
      mapBaseLayer: mapSettings?.baseLayer ? mapSettings.baseLayer : null
    },
    decimalSeparator: {
      ...(viewTemplate?.decimalSeparator || {}),
      [defaultLanguage]:
        decimalSeparator !== null && decimalSeparator !== undefined ? decimalSeparator : DECIMAL_SEPARATOR_DEFAULT
    },
    roundingStrategy: roundingStrategy || ROUNDING_STRATEGY,
    decimalNumber: decimalPlaces
  });

  const handleViewOpen = isUpdating => {
    setIsUpdatingView(isUpdating);
    onViewTemplateShow(true);
    const completeView = getCompleteViewTemplate(view, isUpdating);
    setTempView({
      ...completeView,
      type: "view",
      layouts: {
        ...completeView.layouts,
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
        showArithmeticMean: showArithmeticMean,
        showStandardDeviation: showStandardDeviation,
        showCoefficientOfVariation: showCoefficientOfVariation
      },
      indicatorsDefinition: JSON.stringify(indicators),
      additionalDatasets: JSON.stringify(additionalDatasets)
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setTempView(null);
  };

  const handleViewSubmit = viewId => {
    setTempView(prevVal => ({
      ...prevVal,
      decimalSeparator: undefined,
      roundingStrategy: undefined,
      decimalNumber: undefined
    }));
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempView,
        viewTemplateId: viewId ? Number(viewId) : undefined,
        layouts: JSON.stringify(tempView.layouts)
      },
      true
    );
  };

  const handleTemplateOpen = isUpdating => {
    onViewTemplateShow(false);
    const completeTemplate = getCompleteViewTemplate(template, isUpdating);
    setTempTemplate({
      ...completeTemplate,
      type: "template",
      layouts: {
        ...completeTemplate.layouts,
        tableDefaultLayout: "custom",
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTempTemplate(null);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempTemplate,
        layouts: JSON.stringify({
          ...tempTemplate.layouts,
          tableDefaultLayout: undefined
        }),
        roundingStrategy: tempTemplate.roundingStrategy === "inherit" ? null : tempTemplate.roundingStrategy,
        decimalNumber: tempTemplate.decimalNumber
      },
      false
    );
  };

  const handleNotesShow = notes => {
    setNotes(notes);
  };

  const handleNotesHide = () => {
    setNotes(null);
  };

  const handleAttributesShow = attributes => {
    setAttributes(attributes);
  };

  const handleAttributesHide = () => {
    setAttributes(null);
  };

  const handleTimingsShow = () => {
    setTimingsVisibility(true);
  };

  const handleTimingsHide = () => {
    setTimingsVisibility(false);
  };

  const isValidTemplate = validateI18nObj(tempTemplate?.title) && validateI18nObj(tempTemplate?.decimalSeparator);

  const datasets = [
    {
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle
    },
    ...additionalDatasets
  ];

  return (
    <Fragment>
      <Box
        id="data-viewer__header__title-actions-container"
        sx={{
          color: theme => theme.palette.primary.main,
          display: "inline-block",
          verticalAlign: "top"
        }}
      >
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid
            item
            id="data-viewer__header__title"
            sx={{
              paddingTop: "7px",
              minHeight: "48px",
              "& > h1:first-child": {
                marginLeft: "0px"
              },
              "& > h1:last-child": {
                marginRight: "0px"
              }
            }}
          >
            {datasets.map(({datasetId, datasetTitle, nodeCode}, idx, arr) => {
              const notes = datasetMap[datasetId]?.note || "";
              const datasetAttributes = datasetAttributeMap?.[`${nodeCode},${datasetId}`] || [];
              const seriesAttributes = seriesAttributeMap?.[`${nodeCode},${datasetId}`] || [];
              const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

              return (
                <Fragment key={idx}>
                  <Typography
                    variant={"h1"}
                    sx={{
                      display: "unset",
                      fontSize: "28px",
                      fontWeight: "300px",
                      letterSpacing: "0px",
                      margin: "0 4px",
                      color: theme => theme.palette.text.primary
                    }}
                  >
                    {datasetTitle}
                  </Typography>
                  {notes.length > 0 && (
                    <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                      <IconButton
                        id="dataset-notes-btn"
                        aria-label={t("scenes.dataViewer.header.action.information.label")}
                        onClick={() => handleNotesShow(notes)}
                        sx={{
                          marginTop: "-10px"
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {datasetAttributes.concat(seriesAttributes).length > 0 && (
                    <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
                      <IconButton
                        id="dataset-attributes-btn"
                        aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                        onClick={() =>
                          handleAttributesShow({
                            datasetAttributes: datasetAttributes,
                            seriesAttributes: seriesAttributes
                          })
                        }
                        sx={{
                          marginTop: "-10px"
                        }}
                      >
                        <AttributeIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {metadataUrl && (
                    <DatasetMetadataButton
                      viewMode={VIEW_MODE_ICON_BUTTON}
                      metadataUrl={metadataUrl}
                      datasetId={datasetId}
                      nodeId={nodeId}
                      sx={{
                        marginTop: "-10px"
                      }}
                    />
                  )}
                  {idx < arr.length - 1 && (
                    <Typography
                      variant={"h1"}
                      sx={{
                        display: "unset",
                        fontSize: "28px",
                        fontWeight: "300px",
                        letterSpacing: "0px",
                        margin: "0 4px"
                      }}
                    >
                      {MERGED_DATASETS_TITLE_SEPARATOR}
                    </Typography>
                  )}
                </Fragment>
              );
            })}
          </Grid>
          <Grid item id="data-viewer__header__actions">
            <Grid container alignItems="center">
              {(() => {
                if (canViewTemplateOrAnnotationIcon(user, nodeId)) {
                  let title = null;
                  if (hasViewLayout) {
                    title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                  } else if (hasTemplateLayout) {
                    title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                  } else if (hasAnnotationLayout) {
                    title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                  }

                  if (title) {
                    return (
                      <Grid
                        item
                        id="dataset-layout-info-btn"
                        sx={{
                          width: "40px",
                          height: "40px",
                          padding: "8px",
                          color: "rgba(0, 0, 0, 0.54)"
                        }}
                      >
                        <Tooltip id="data_viewer_multi_viewer_header_info_btn" title={title}>
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    );
                  }
                }
              })()}
              {canViewTimesLog(user, nodeId) && (
                <Grid item id="dataset-times-log-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                    <div>
                      <IconButton
                        id="data_viewer_multi_viewer_header_times_log_btn"
                        aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                        onClick={handleTimingsShow}
                        disabled={timings === null}
                      >
                        <AccessTimeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {additionalDatasets.length === 0 && canUseDatasetTools(user) && (
                <Grid item id="dataset-cache-template-btn">
                  <DatasetViewerTools
                    id="data_viewer_multi_viewer_header_cache_template_btn"
                    templateId={template?.viewTemplateId || null}
                  />
                </Grid>
              )}
              {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) &&
                areNodesAllowedForSaveViewTemplate(datasets, nodes) && (
                  <Grid item id="dataset-save-btn">
                    <ButtonSelect
                      id="data_viewer_multi_viewer_header_dataset_save_btn"
                      icon={<SaveIcon />}
                      ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                      tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                      color="default"
                      disabled={!tableLayout && !chartLayout && !mapLayout}
                    >
                      {canSaveAsView(user) && (
                        <div id="data-viewer__dataset-save-as-view-btn" onClick={() => handleViewOpen(false)}>
                          {t("scenes.dataViewer.header.action.save.values.createView")}
                        </div>
                      )}
                      {canSaveAsView(user) && hasViewLayout && viewId && (
                        <div id="data-viewer__dataset-update-view-btn" onClick={() => handleViewOpen(true)}>
                          {t("scenes.dataViewer.header.action.save.values.updateView")}
                        </div>
                      )}
                      {indicators.length === 0 && additionalDatasets.length === 0 && canSaveTemplate(user, nodeId) && (
                        <div
                          id="data-viewer__dataset-save-as-template-btn"
                          onClick={() => handleTemplateOpen(hasTemplateLayout)}
                        >
                          {hasTemplateLayout
                            ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                            : t("scenes.dataViewer.header.action.save.values.createTemplate")}
                        </div>
                      )}
                    </ButtonSelect>
                  </Grid>
                )}
              <ModulesPlaceholder
                id="data-viewer-header-button"
                viewTemplate={{
                  ...getCompleteViewTemplate(view, false),
                  type: "view",
                  layouts: JSON.stringify({
                    ...getCompleteViewTemplate(view, false).layouts,
                    tableLayout: tableLayout,
                    tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
                    mapLayout: mapLayout,
                    ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
                    chartLayout: chartLayout,
                    ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
                    showArithmeticMean: showArithmeticMean,
                    showStandardDeviation: showStandardDeviation,
                    showCoefficientOfVariation: showCoefficientOfVariation
                  }),
                  nodeId,
                  indicatorsDefinition: JSON.stringify(indicators),
                  additionalDatasets: JSON.stringify(additionalDatasets)
                }}
                sx={{
                  width: "40px",
                  height: "40px"
                }}
              />
              {(attachedFiles || []).length > 0 && (
                <Grid item id="dataset-attachments-btn">
                  <ButtonSelect
                    id="data_viewer_multi_viewer_header_dataset_attachments_btn"
                    icon={<AttachFileIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                    tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                    color="default"
                    onChange={({attachedFile}) => window.open(attachedFile.url)}
                  >
                    {attachedFiles.map((attachedFile, idx) => {
                      const fileName = attachedFile.url.split("/").pop();
                      return (
                        <Tooltip title={attachedFile.description} key={idx} data-value={{attachedFile}}>
                          <div>{`${fileName} (${attachedFile.format})`}</div>
                        </Tooltip>
                      );
                    })}
                  </ButtonSelect>
                </Grid>
              )}
              {showQueryInfo && (
                <Grid item id="dataset-query-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                    <div>
                      <IconButton
                        id="data_viewer_multi_viewer_header_dataset_query_btn"
                        aria-label={t("scenes.dataViewer.header.action.query.label")}
                        onClick={onQueryShow}
                      >
                        <DeveloperModeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        id="data-viewer__header__extra-actions"
        sx={{
          display: "inline-block",
          verticalAlign: "top"
        }}
      >
        {themeConfig.isMultiViewer && (
          <Box
            id="dataset-viewer-mode-btn"
            sx={{
              display: "none",
              width: "48px"
            }}
          >
            <ButtonSelect
              id="data_viewer_multi_viewer_header_dataset_viewer_mode_btn"
              icon={<HelpIcon />}
              ariaLabel={t("scenes.dataViewer.header.action.viewerMode.label")}
              tooltip={t("scenes.dataViewer.header.action.viewerMode.tooltip")}
              color="default"
              onChange={({mode}) => setViewerMode(mode)}
            >
              <div data-value={{mode: ViewerMode.SingleViewer}}>{ViewerMode.SingleViewer}</div>
              <div data-value={{mode: ViewerMode.MultiViewer}}>{ViewerMode.MultiViewer}</div>
            </ButtonSelect>
          </Box>
        )}
      </Box>

      <Dialog
        id="data-viewer__header__attributes-dialog"
        open={attributes !== null}
        fullWidth
        maxWidth="md"
        onClose={handleAttributesHide}
      >
        <CustomDialogTitle onClose={handleAttributesHide}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={attributes?.datasetAttributes || []}
            seriesAttributes={attributes?.seriesAttributes || []}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__attributes-dialog-close-btn" onClick={handleAttributesHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog id="data-viewer__header__timings-dialog" open={isTimingsVisible} onClose={handleTimingsHide}>
        <DialogContent sx={{width: "400px"}}>
          <DataViewerTimings timings={timings} />
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__dataset-timing-dialog-close-button" autoFocus onClick={handleTimingsHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__header__view-dialog"
        open={isViewVisible}
        maxWidth="sm"
        fullWidth
        onClose={handleViewClose}
      >
        <CustomDialogTitle onClose={handleViewClose}>
          {isUpdatingView
            ? t("scenes.dataViewer.header.dialogs.view.title.update")
            : t("scenes.dataViewer.header.dialogs.view.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
              <Grid item xs={12}>
                <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl id="data-viewer__header__view-dialog-title" fullWidth>
                <I18nTextField
                  label={t("scenes.dataViewer.header.dialogs.view.form.title.label")}
                  required
                  variant="outlined"
                  value={tempView?.title || ""}
                  onChange={value => setTempView({...tempView, title: value})}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__view-dialog-close-btn" onClick={handleViewClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="data-viewer__header__view-dialog-save-btn"
            color="primary"
            autoFocus
            onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
            disabled={!tempView || !validateI18nObj(tempView.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__header__view-duplicate-error-dialog"
        open={isViewErrorVisible}
        maxWidth="md"
        onClose={onViewErrorHide}
      >
        <CustomDialogTitle onClose={onViewErrorHide}>
          {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
        </CustomDialogTitle>
        <DialogContent>
          {viewErrorMessage && (
            <Fragment>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
              <b>{Object.keys(viewErrorMessage).join(", ")}</b>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__view-duplicate-error-dialog-close-btn" onClick={onViewErrorHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__header__template-dialog"
        open={isTemplateVisible}
        maxWidth="sm"
        fullWidth
        onClose={handleTemplateClose}
      >
        <CustomDialogTitle onClose={handleTemplateClose}>
          {hasTemplateLayout
            ? t("scenes.dataViewer.header.dialogs.template.title.update")
            : t("scenes.dataViewer.header.dialogs.template.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <MultiViewerTemplateBuilder
            template={tempTemplate}
            onChange={setTempTemplate}
            criteria={criteria}
            timeDim={timeDim}
          />
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__template-dialog-cancel-btn" onClick={handleTemplateClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="data-viewer__header__template-dialog-save-btn"
            color="primary"
            onClick={handleTemplateSubmit}
            disabled={!isValidTemplate}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__header__notes-dialog"
        open={notes !== null}
        fullWidth
        maxWidth="md"
        onClose={handleNotesHide}
      >
        <CustomDialogTitle onClose={handleNotesHide}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>{notes}</DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__notes-dialog-close-btn" onClick={handleNotesHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__header__query-dialog"
        open={isQueryVisible}
        fullWidth
        maxWidth="md"
        onClose={onQueryHide}
      >
        <CustomDialogTitle onClose={onQueryHide}>{t("scenes.dataViewer.header.dialogs.query.title")}</CustomDialogTitle>
        <DialogContent
          sx={theme => ({
            "& > *": {
              marginBottom: theme.spacing(2)
            },
            "& > *:last-child": {
              marginBottom: "0px"
            }
          })}
        >
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")} query={dataQuery} />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button id="data-viewer__header__query-dialog-close-btn" onClick={onQueryHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerHeader);
