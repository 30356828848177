import React, {Fragment, useEffect, useState} from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import FilterAlt from "@mui/icons-material/FilterAlt";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ChartLayout from "../../chart-layout";
import {CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT} from "../../chart/constants";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import DatasetMetadataButton from "../../dataset-metadata-button";
import FullscreenDialog from "../../fullscreen-dialog";
import TableLayout from "../../table-layout";
import {getDimensionsWithHclWithError, isChartLayoutCartesianByViewerIdx, viewersFactory} from "../constant";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  enableDatasetSVPDatasetFetch,
  fetchDatasetSVPStructureCodelistFull,
  hideDatasetSVPCriteria,
  hideDatasetSVPCriteriaAlert,
  hideDatasetSVPLayout,
  hideDatasetSVPStructureCodelistFull,
  resetSVPChartSorting,
  setDatasetSVPHierarchy,
  setDatasetSVPStructureCriteria,
  setDatasetSVPViewer,
  showDatasetSVPCriteria,
  showDatasetSVPLayout,
  submitDatasetSVPChartLayout,
  submitDatasetSVPTableLayout
} from "../../../state/dataset/single-viewer-plus/actions";
import {
  getDatasetSize,
  getSVPFilteredChartLayout,
  getSVPFilteredTableLayout,
  MAX_ALLOWED_CELLS
} from "../../../utils/dataset";

const navigationActionDisabledStyle = {
  color: "rgba(0, 0, 0, 0.26) !important",
  "& svg": {
    color: "rgba(0, 0, 0, 0.26)"
  }
};

const mapStateToProps = ({hub, dataset}) => {
  const viewerIdx = dataset.singleViewerPlus.viewerIdx;

  const chartLayoutCartesian = dataset.singleViewerPlus.chartLayoutCartesian;
  const chartLayoutRadial = dataset.singleViewerPlus.chartLayoutRadial;

  const chartLockedDimensionsCartesian = dataset.singleViewerPlus.chartLockedDimensionsCartesian;
  const chartLockedDimensionsRadial = dataset.singleViewerPlus.chartLockedDimensionsRadial;

  return {
    maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
    maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
    mode: dataset.commons.mode,
    type: dataset.commons.type,
    dataset: dataset.singleViewerPlus.dataset,
    isEmptyData: dataset.singleViewerPlus.isEmptyData,
    isTooBigData: dataset.singleViewerPlus.isTooBigData,
    isTooLongQuery: dataset.singleViewerPlus.isTooLongQuery,
    isCriteriaVisible: dataset.singleViewerPlus.isCriteriaVisible,
    initialCriteriaDimension: dataset.singleViewerPlus.initialCriteriaDimension,
    isCriteriaAlertVisible: dataset.singleViewerPlus.isCriteriaAlertVisible,
    dimensions: dataset.singleViewerPlus.dimensions,
    dimensionsInfo: dataset.singleViewerPlus.dimensionsInfo,
    timeDim: dataset.singleViewerPlus.timeDim,
    freqDim: dataset.singleViewerPlus.freqDim,
    territoryDim: dataset.singleViewerPlus.territoryDim,
    viewerIdx: dataset.singleViewerPlus.viewerIdx,
    isLayoutVisible: dataset.singleViewerPlus.isLayoutVisible,
    tableLayout: dataset.singleViewerPlus.tableLayout,
    mapLayout: dataset.singleViewerPlus.mapLayout,
    chartLayout: isChartLayoutCartesianByViewerIdx(viewerIdx) ? chartLayoutCartesian : chartLayoutRadial,
    templateLayouts: dataset.singleViewerPlus.template?.layouts,
    labelFormat: dataset.singleViewerPlus.labelFormat,
    areCriteriaApplied: dataset.singleViewerPlus.areCriteriaApplied,
    criteria: dataset.singleViewerPlus.criteria,
    enableCriteria: dataset.singleViewerPlus.enableCriteria,
    enableLayout: dataset.singleViewerPlus.enableLayout,
    codelistTrees: dataset.singleViewerPlus.codelistTrees,
    codelistLists: dataset.singleViewerPlus.codelistFilteredLists,
    codelistsLength: dataset.singleViewerPlus.codelistsLength,
    hierarchicalCodelists: dataset.singleViewerPlus.hierarchicalCodelists,
    codelistFetchError: dataset.singleViewerPlus.codelistFetchError,
    isTableEnabled: dataset.singleViewerPlus.isTableEnabled,
    isMapEnabled: dataset.singleViewerPlus.isMapEnabled,
    isChartEnabled: dataset.singleViewerPlus.isChartEnabled,
    tableLockedDimensions: dataset.singleViewerPlus.tableLockedDimensions,
    chartLockedDimensions: isChartLayoutCartesianByViewerIdx(viewerIdx)
      ? chartLockedDimensionsCartesian
      : chartLockedDimensionsRadial,
    missingFilterValues: dataset.singleViewerPlus.missingFilterValues,
    chartSettings: dataset.singleViewerPlus.chartSettings
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: limit => dispatch(enableDatasetSVPDatasetFetch(limit)),
  onViewerSet: viewerIdx => dispatch(setDatasetSVPViewer(viewerIdx)),
  onLayoutShow: () => dispatch(showDatasetSVPLayout()),
  onLayoutHide: () => dispatch(hideDatasetSVPLayout()),
  onTableLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPTableLayout(layout, enableFetch)),
  onChartLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPChartLayout(layout, enableFetch)),
  onChartSortingReset: isChartCartesian => dispatch(resetSVPChartSorting(isChartCartesian)),
  onCriteriaShow: () => dispatch(showDatasetSVPCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetSVPCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetSVPStructureCriteria(criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetSVPCriteriaAlert()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetSVPStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetSVPStructureCodelistFull()),
  onHierarchyChange: (dimensionId, hierarchyId, tabId) =>
    dispatch(setDatasetSVPHierarchy(dimensionId, hierarchyId, tabId))
});

function SingleViewerPlusSidebar(props) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetMap,

    maxObservations,
    maxCells,
    mode,
    type,
    dataset,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    isCriteriaVisible,
    initialCriteriaDimension,
    isCriteriaAlertVisible,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    territoryDim,
    viewerIdx,
    isLayoutVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    templateLayouts,
    labelFormat,
    areCriteriaApplied,
    criteria,
    enableCriteria,
    enableLayout,
    codelistTrees,
    codelistLists,
    codelistsLength,
    hierarchicalCodelists,
    codelistFetchError,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,
    tableLockedDimensions,
    chartLockedDimensions,
    missingFilterValues,

    onFetchDatasetEnable,
    onViewerSet,
    onLayoutShow,
    onLayoutHide,
    onTableLayoutSet,
    onChartLayoutSet,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onCriteriaAlertHide,
    fetchCodelistFull,
    onCodelistFullHide,
    onHierarchyChange,
    chartSettings,
    onChartSortingReset
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  const availableCharts = (
    nodeExtras?.ChartsSettings?.length > 0
      ? viewers.filter(view => nodeExtras?.ChartsSettings?.some(obj => obj.chartType === view.chartType))
      : nodeExtras?.ChartsSettings
        ? []
        : viewers.filter(chart => chart.type !== "map" && chart.type !== "table")
  ).filter(({hidden}) => hidden !== true);

  const [anchorEl, setAnchorEl] = useState(null);

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [dimensionsWithHierarchiesError, setDimensionsWithHierarchiesError] = useState([]);
  const [isHierarchiesErrorVisible, setHierarchiesErrorVisibility] = useState(false);

  const [isLayoutDialogVisible, setLayoutDialogVisible] = useState(false);

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  const handleViewerSelect = index => {
    onViewerSet(index);
    setAnchorEl(null);
  };

  const handleLayoutOpen = () => {
    onLayoutShow();
  };

  const handleLayoutClose = () => {
    onLayoutHide();

    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    if (viewerIdx === 0) {
      onTableLayoutSet(getSVPFilteredTableLayout(tmpTableLayout, codelistLists, timeDim, freqDim, territoryDim), true);
      onLayoutHide();
    } else if (viewerIdx >= 2) {
      const newPrimaryDim = tmpChartLayout.primaryDim[0];
      const newSecondaryDim = tmpChartLayout.secondaryDim[0];
      const prevPrimaryDim = chartLayout.primaryDim[0];
      const prevSecondaryDim = chartLayout.secondaryDim[0];

      const obsSortingOrder = isChartLayoutCartesianByViewerIdx(viewerIdx)
        ? chartSettings.obsSortingOrderCartesian
        : chartSettings.obsSortingOrderRadial;

      const isDimChanged = newPrimaryDim !== prevPrimaryDim || newSecondaryDim !== prevSecondaryDim;
      const isChartSorted = obsSortingOrder !== CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT;

      if (isDimChanged && isChartSorted) {
        setLayoutDialogVisible(true);
      } else {
        handleChartLayoutSet(false);
      }
    }
  };

  const handleChartLayoutSet = resetChartSorting => {
    const newChartLayout = _.cloneDeep(tmpChartLayout);
    newChartLayout.primaryDimValues =
      newChartLayout.primaryDim[0] === chartLayout.primaryDim[0] ? newChartLayout.primaryDimValues : [];
    newChartLayout.secondaryDimValues =
      newChartLayout.secondaryDim[0] === chartLayout.secondaryDim[0] ? newChartLayout.secondaryDimValues : [];
    onChartLayoutSet(getSVPFilteredChartLayout(newChartLayout, codelistLists, timeDim, freqDim, territoryDim), true);
    if (resetChartSorting) {
      onChartSortingReset(isChartLayoutCartesianByViewerIdx(viewerIdx));
    }
    onLayoutHide();
    setLayoutDialogVisible(false);
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = () => {
    const dimensionsWithError = getDimensionsWithHclWithError(dimensions, criteria);
    if (dimensionsWithError.length > 0) {
      setDimensionsWithHierarchiesError(dimensionsWithError);
      setHierarchiesErrorVisibility(true);
      return;
    }
    const limit = viewerIdx === 0 ? Math.min(maxObservations, maxCells) : maxObservations;
    onFetchDatasetEnable(limit);
  };

  const isTableDisabled = !areCriteriaApplied || !tableLayout;
  const isChartDisabled = !areCriteriaApplied || !chartLayout;
  const isMapDisabled = !areCriteriaApplied || !mapLayout;
  const isCriteriaDisabled = !enableCriteria;
  const isLayoutDisabled =
    !enableLayout ||
    !areCriteriaApplied ||
    (viewerIdx === 0 && isTableDisabled) ||
    (viewerIdx === 1 && isMapDisabled) ||
    (viewerIdx >= 2 && isChartDisabled);

  const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

  const tmpLayout = viewerIdx === 0 ? tmpTableLayout : viewerIdx >= 1 ? tmpChartLayout : null;
  const datasetSize = getDatasetSize(
    dimensionsInfo,
    tmpLayout,
    tmpChartLayout?.primaryDim?.[0] !== chartLayout?.primaryDim?.[0],
    tmpChartLayout?.secondaryDim?.[0] !== chartLayout?.secondaryDim?.[0]
  );

  return (
    <Fragment>
      <Card
        sx={{
          width: "80px",
          height: "100%",
          overflow: "auto",
          padding: "8px 0",
          marginRight: "16px",
          "& .MuiBottomNavigation-root": {
            height: "unset !important"
          },
          "& .MuiBottomNavigationAction-label": {
            fontSize: "13px !important"
          }
        }}
        role="menu"
      >
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            id="data-viewer__sidebar__criteria-btn"
            label={t("scenes.dataViewer.singleViewerPlus.sidebar.criteria")}
            icon={<FilterAlt />}
            sx={isCriteriaDisabled ? navigationActionDisabledStyle : null}
            disabled={isCriteriaDisabled}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels onChange={handleLayoutOpen}>
          <BottomNavigationAction
            id="data-viewer__sidebar__layout-btn"
            label={t("scenes.dataViewer.singleViewerPlus.sidebar.layout")}
            icon={<EditIcon />}
            sx={isLayoutDisabled || viewerIdx === 1 ? navigationActionDisabledStyle : null}
            disabled={isLayoutDisabled || viewerIdx === 1}
            role="menuitem"
          />
        </BottomNavigation>
        <Divider sx={{margin: "4px 0"}} />

        {metadataUrl && (
          <Fragment>
            <DatasetMetadataButton metadataUrl={metadataUrl} datasetId={datasetId} nodeId={nodeId} />
            <Divider sx={{margin: "4px 0"}} />
          </Fragment>
        )}

        {isTableEnabled && !templateLayouts?.disableTable && (
          <BottomNavigation showLabels value={viewerIdx === 0 ? 0 : null} onChange={() => handleViewerSelect(0)}>
            <BottomNavigationAction
              id="data-viewer__sidebar__table-btn"
              label={viewers[0].title}
              icon={viewers[0].icon}
              sx={isTableDisabled ? navigationActionDisabledStyle : null}
              disabled={isTableDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isChartEnabled && !templateLayouts?.disableChart && availableCharts && availableCharts.length > 0 && (
          <BottomNavigation
            showLabels
            value={viewerIdx >= 2 ? 0 : null}
            onChange={({currentTarget}) => setAnchorEl(currentTarget)}
          >
            <BottomNavigationAction
              id="data-viewer__sidebar__chart-btn"
              label={viewerIdx >= 2 ? viewers[viewerIdx].title : t("scenes.dataViewer.singleViewerPlus.sidebar.chart")}
              icon={viewerIdx >= 2 ? viewers[viewerIdx].icon : <BarChartIcon />}
              sx={isChartDisabled ? navigationActionDisabledStyle : null}
              disabled={isChartDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isMapEnabled && !templateLayouts?.disableMap && !isMapDisabled && (
          <BottomNavigation showLabels value={viewerIdx === 1 ? 0 : null} onChange={() => handleViewerSelect(1)}>
            <BottomNavigationAction
              id="data-viewer__sidebar__map-btn"
              label={viewers[1].title}
              icon={viewers[1].icon}
              sx={isMapDisabled ? navigationActionDisabledStyle : null}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {availableCharts && availableCharts.length > 0 && (
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {availableCharts.map(option => (
              <MenuItem
                id={`data-viewer__sidebar__available-charts-${option.key}-btn`}
                key={option.key}
                sx={theme => [{color: "gray"}, viewerIdx === option.key && {color: theme.palette.primary.main}]}
                selected={viewerIdx === option.key}
                onClick={() => handleViewerSelect(option.key)}
              >
                {option.icon}
                <Typography sx={{marginLeft: "8px"}}>{option.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}

        <FullscreenDialog open={isCriteriaVisible} onClose={handleCriteriaClose} id={"criteria-dialog"}>
          <CustomDialogTitle onClose={handleCriteriaClose}>
            {t("scenes.dataViewer.singleViewerPlus.dialogs.criteria.title")}
          </CustomDialogTitle>
          <DialogContent>
            <Criteria
              viewerMode={ViewerMode.SingleViewer}
              nodeId={nodeId}
              nodeCode={nodeCode}
              datasetId={datasetId}
              dimensions={dimensions}
              initialDim={initialCriteriaDimension}
              timeDim={timeDim}
              freqDim={freqDim}
              mode={mode}
              type={type}
              criteria={criteria}
              onSetCriteria={onSetCriteria}
              codelists={codelistTrees}
              hierarchicalCodelists={hierarchicalCodelists}
              codelistsLength={codelistsLength}
              codelistFetchError={codelistFetchError}
              isCriteriaValid={isCriteriaValid}
              setCriteriaValidity={setCriteriaValidity}
              defaultLastNPeriods={defaultLastNPeriods}
              showCodelistInfo
              missingFilterValues={missingFilterValues}
              fetchCodelistFull={fetchCodelistFull}
              onCodelistFullHide={onCodelistFullHide}
              onHierarchyChange={onHierarchyChange}
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__criteria-cancel-btn" onClick={handleCriteriaClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={handleCriteriaSubmit}
              disabled={!isCriteriaValid}
              id="data-viewer__sidebar__criteria-confirm-btn"
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </FullscreenDialog>

        <Dialog
          id="data-viewer__sidebar__table-layout-dialog"
          open={isLayoutVisible && viewerIdx === 0}
          fullWidth
          maxWidth="md"
          onClose={handleLayoutClose}
        >
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.tableLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <TableLayout
              layout={tmpTableLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={tableLockedDimensions}
              setLayout={setTmpTableLayout}
              jsonStat={dataset}
              labelFormat={labelFormat}
              alertText={
                datasetSize > maxObservations
                  ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.table.tooMuchObservations")
                  : datasetSize > maxCells
                    ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.table.tooMuchCells")
                    : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__table-layout-cancel-btn" onClick={handleLayoutClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="data-viewer__sidebar__table-layout-confirm-btn"
              autoFocus
              onClick={handleLayoutSubmit}
              disabled={datasetSize > Math.min(maxObservations, maxCells)}
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          id="data-viewer__sidebar__chart-layout-dialog"
          open={isLayoutVisible && viewerIdx >= 2}
          fullWidth
          maxWidth="md"
          onClose={handleLayoutClose}
        >
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.chartLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <ChartLayout
              key={viewerIdx}
              layout={tmpChartLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={chartLockedDimensions}
              setLayout={setTmpChartLayout}
              alertText={
                datasetSize > maxObservations
                  ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.chart.tooMuchObservations")
                  : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button id="data-viewer__sidebar__chart-layout-cancel-btn" onClick={handleLayoutClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="data-viewer__sidebar__chart-layout-confirm-btn"
              autoFocus
              onClick={handleLayoutSubmit}
              disabled={datasetSize > maxObservations}
            >
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      <Dialog
        id="data-viewer__sidebar__criteria-alert-dialog"
        open={isCriteriaAlertVisible}
        fullWidth
        maxWidth="sm"
        onClose={onCriteriaAlertHide}
      >
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            id="data-viewer__sidebar__criteria-alert-close-btn"
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              onCriteriaShow();
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="data-viewer__sidebar__hierarchies-error-dialog"
        open={isHierarchiesErrorVisible}
        maxWidth="md"
        onClose={() => setHierarchiesErrorVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setHierarchiesErrorVisibility(false)}>
          {t("scenes.dataViewer.singleViewer.dialogs.hierarchiesError.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Trans
            i18nKey={"scenes.dataViewer.singleViewer.dialogs.hierarchiesError.content"}
            values={{dimensions: dimensionsWithHierarchiesError.join(", ")}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="data-viewer__sidebar__hierarchies-error-close-btn"
            onClick={() => setHierarchiesErrorVisibility(false)}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="sidebar-chart-layout__dialog"
        open={isLayoutDialogVisible}
        maxWidth="xs"
        onClose={() => setLayoutDialogVisible(false)}
      >
        <CustomDialogTitle onClose={() => setLayoutDialogVisible(false)}>
          {t("scenes.dataViewer.singleViewerPlus.dialogs.layoutDialog.title")}
        </CustomDialogTitle>
        <DialogContent>{t("scenes.dataViewer.singleViewerPlus.dialogs.layoutDialog.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setLayoutDialogVisible(false)}>{t("commons.confirm.cancel")}</Button>
          <Button onClick={() => handleChartLayoutSet(true)}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleViewerPlusSidebar);
