import React, {useEffect, useRef, useState} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {Box, IconButton} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {VariableSizeList} from "react-window";

/* Based on https://material-ui.com/components/autocomplete/#virtualization */

const listboxStyle = {
  overflowX: "hidden",
  boxSizing: "border-box",
  "& ul": {
    padding: "0px",
    margin: "0px"
  }
};

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const AutocompleteSearchInput = ({id, options, onSubmit, sx, disableAutoFocus}) => {
  const {t} = useTranslation();

  const inputRef = useRef();
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    if (!disableAutoFocus) {
      inputRef.current.focus({preventScroll: true});
    }
  }, [disableAutoFocus]);

  return (
    <Box
      id={id}
      sx={theme => ({
        borderRadius: "4px",
        backgroundColor: "#f5f5f5",
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
        position: "relative",
        ...sx
      })}
      style={{width: "100%"}}
    >
      <label htmlFor="search-input" style={{display: "none"}}>
        {t("components.searchInput.label")}
      </label>
      <Autocomplete
        ListboxProps={{
          sx: listboxStyle
        }}
        freeSolo
        defaultValue={null}
        value={searchedText}
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={options || []}
        renderOption={({key, ...props}, option, state) => {
          const string = option.replaceAll(/ /g, "\xa0");
          const substring = (state.inputValue ?? "").replaceAll(/ /g, "\xa0");

          const start = (string || "").toLowerCase().indexOf(substring.toLowerCase());
          const end = start + substring.length;

          return (
            <Tooltip key={state.index} placement="bottom-start" title={option}>
              <Typography {...props} noWrap>
                {string.substring(0, start)}
                <b>{string.substring(start, end)}</b>
                {string.substring(end)}
              </Typography>
            </Tooltip>
          );
        }}
        filterOptions={options => {
          return options.filter(item => (item ?? "").toLowerCase().includes((searchedText ?? "").toLowerCase()));
        }}
        onChange={(event, newValue) => {
          onSubmit(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            id="search-input"
            onChange={() => {
              setSearchedText(inputRef.current.value);
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onSubmit(searchedText);
              }
            }}
            fullWidth
            size="small"
            variant="outlined"
            placeholder={t("components.searchInput.placeholder")}
            InputProps={{
              ...params.InputProps,
              style: {paddingRight: "6px"},
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment:
                searchedText && searchedText !== "" ? (
                  <InputAdornment position="end">
                    <Tooltip title={t("components.autoSearchInput.clearSearch.tooltip")}>
                      <span>
                        <IconButton
                          aria-label={t("components.autoSearchInput.clearSearch.ariaLabel")}
                          onClick={() => setSearchedText("")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ) : undefined,
              inputRef: inputRef
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteSearchInput;
