import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RestoreIcon from "@mui/icons-material/Restore";
import {Checkbox, Dialog, DialogActions, DialogContent, useTheme} from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import {merge} from "lodash";
import moment from "moment";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import validator from "validator";
import {NodeDataAccessibility, NodeVisibility} from "../../../../model/IHubMinimalNode.d.ts";
import {getNodePingUrl} from "../../../../serverApi/urls";
import ComponentVariantLoader from "../../../component-variant-loader";
import CustomDatePicker from "../../../custom-date-picker";
import CustomDialogTitle from "../../../custom-dialog-title/index.jsx";
import {viewersFactory} from "../../../data-viewer/constant";
import FileInput from "../../../file-input";
import FormLabelWithTooltip from "../../../form-label-with-tooltip";
import I18nHtmlEditor from "../../../i18n-html-editor";
import I18nTextField from "../../../i18n-text-field";
import PasswordInput from "../../../password-input";
import TabPanel from "../../../tab-panel";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET
} from "../../../temporal-dim-order-selector/constants";
import CustomColorsForm from "../../custom-colors-form/CustomColorsForm";
import useApi from "../../../../state/api/useApi";
import {
  ALL_PARTIAL,
  ALL_PARTIAL_OPTIMIZED,
  STEP_BY_STEP_DYNAMIC,
  STEP_BY_STEP_PARTIAL
} from "../../../../state/dataset/constants";
import {
  clearNodesConfigNode,
  fetchNodesConfigNode,
  sendNodesConfigNodeCreate,
  sendNodesConfigNodeEdit
} from "../../../../state/nodesConfig/nodesConfigActions";
import {initRequest, RequestMethod} from "../../../../middlewares/request/requestActions";
import {downloadFormats} from "../../../../utils/download";
import {getI18nObjFromLanguages, validateI18nObj} from "../../../../utils/i18n";
import {isValidIntegerInInclusiveRange} from "../../../../utils/validator";

const fieldStyle = {
  margin: "8px 0",
  "& > .MuiFormControlLabel-root": {
    marginTop: "0px"
  }
};

const subfieldStyle = {
  marginLeft: "48px",
  width: `calc(100% - 48px)`
};

const paperStyle = {
  marginTop: "16px",
  padding: "16px"
};

export const CATALOG_NAVIGATION_MODE_TREE = "Tree";
export const CATALOG_NAVIGATION_MODE_GRID = "Card";
export const CATALOG_NAVIGATION_MODE_LIST = "List";

const defaultAnnotations = {
  ORDER: "ORDER", // Order Annotation
  ORDER_CATEGORY: "ORDER", // Category Order Annotation
  ORDER_CATEGORY_SCHEMA: "ORDER", // Category Schema Order Annotation
  ORDER_CODELIST: "ORDER", // Codelists Order Annotation
  NOT_DISPLAYED: "NOT_DISPLAYED", // Not Displayed Annotation
  DEFAULT: "DEFAULT", // Default Annotation
  TIME_PERIOD_START: "TIME_PERIOD_START", // Time Period Start Default
  TIME_PERIOD_END: "TIME_PERIOD_END", // Time Period End Default
  LASTNPERIOD: "LAST_N_PERIOD", // Last N Period Default
  LASTNOBSERVATIONS: "LAST_N_OBSERVATIONS", // Last N Observation Default
  LAYOUT_ROW: "LAYOUT_ROW", // Layout Row Annotation
  LAYOUT_COLUMN: "LAYOUT_COLUMN", // Layout Column Annotation
  LAYOUT_FILTER: "LAYOUT_FILTER", // Layout Filter Annotation
  LAYOUT_ROW_SECTION: "LAYOUT_ROW_SECTION", // Layout Row Section Annotation
  LAYOUT_CHART_PRIMARY_DIM_CARTESIAN: "LAYOUT_CHART_PRIMARY_DIM_CARTESIAN", // Layout Chart CARTESIAN Primary Dim Annotation
  LAYOUT_CHART_SECONDARY_DIM_CARTESIAN: "LAYOUT_CHART_SECONDARY_DIM_CARTESIAN", // Layout Chart CARTESIAN Secondary Dim Annotation
  LAYOUT_CHART_FILTER_CARTESIAN: "LAYOUT_CHART_FILTER_CARTESIAN", // Layout Chart CARTESIAN Filter Annotation
  LAYOUT_CHART_PRIMARY_DIM_RADIAL: "LAYOUT_CHART_PRIMARY_DIM_RADIAL", // Layout Chart RADIAL Primary Dim Annotation
  LAYOUT_CHART_SECONDARY_DIM_RADIAL: "LAYOUT_CHART_SECONDARY_DIM_RADIAL", // Layout Chart RADIAL Secondary Dim Annotation
  LAYOUT_CHART_FILTER_RADIAL: "LAYOUT_CHART_FILTER_RADIAL", // Layout Chart RADIAL Filter Annotation
  LAYOUT_MAP_FILTER: "LAYOUT_MAP_FILTER", // Layout Map Filter Annotation
  CRITERIA_SELECTION: "LAYOUT_CRITERIA_SELECTION", // Layout Criteria Selection Annotation
  ATTACHED_DATA_FILES: "LAYOUT_ATTACHED_DATA_FILES", // Layout Attached DataFiles Annotation
  LAYOUT_DECIMAL_SEPARATOR: "LAYOUT_DECIMAL_SEPARATOR", // Layout Decimal Separator Annotation
  LAYOUT_NUMBER_OF_DECIMALS: "LAYOUT_DECIMALS_NUMBER", // Layout Decimals Number Annotation
  LAYOUT_EMPTY_CELL_PLACEHOLDER: "LAYOUT_EMPTY_CELL_PLACEHOLDER", // Layout Empty Cell Placeholder Annotation
  DATAFLOW_NOTES: "LAYOUT_DATAFLOW_NOTES", // Layout Dataflow Notes Annotation
  DATAFLOW_SOURCE: "LAYOUT_DATAFLOW_SOURCE", // Layout Dataflow Source Annotation
  METADATA_URL: "LAYOUT_REFERENCE_METADATA", // Layout Reference Metadata Annotation
  KEYWORDS: "LAYOUT_DATAFLOW_KEYWORDS", // Layout Dataflow Keywords Annotation
  LAYOUT_DEFAULT_PRESENTATION: "LAYOUT_DEFAULT_PRESENTATION", // LayoutDefaultPresentation Annotation
  GEO_ID: "LAYOUT_GEO_IDS", // Layout Territorial DimensionIds Annotation
  LAST_UPDATE: "LAST_UPDATE", // LastUpdate Annotation
  VIRTUAL_DATAFLOW_NODE: "LINKED_DATAFLOW_NODE", // Linked Dataflow Node Annotation
  DATAFLOW_CATALOG_TYPE: "DATAFLOW_CATALOG_TYPE", // DataflowCatalogType Annotation
  DATAFLOW_HIDDEN: "LAYOUT_DATAFLOW_HIDDEN", // Layout Dataflow Hidden Annotation
  DISABLED_VIEWERS: "DISABLED_VIEWERS", // Disabled Viewers Annotation
  TABLE_LOCKED_DIMS: "LAYOUT_TABLE_LOCKED_DIMS", // Table Locked Dimensions Annotation
  CHART_LOCKED_DIMS_CARTESIAN: "LAYOUT_CHART_LOCKED_DIMS_CARTESIAN", // Graph CARTESIAN Locked Dimensions Annotation
  CHART_LOCKED_DIMS_RADIAL: "LAYOUT_CHART_LOCKED_DIMS_RADIAL", // Graph RADIAL Locked Dimensions Annotation
  TEMPORAL_DIM_ORDER: "LAYOUT_TEMPORAL_ORDER", // Temporal Dimension Order Annotation
  ANTICIP_EXCEEDING_MAX_NUM_REC: "ANTICIP_EXCEEDING_MAX_NUM_REC", // Anticipation Exceeding Max Num Records Annotation
  FLAT_TABLE_AVAILABLE: "FLAT_TABLE_AVAILABLE", // Optimized Dataflow Annotation
  DDB_CONTENT_CONSTRAINT: "DDB_CONTENT_CONSTRAINT", // Partial codelist Content Constraint
  LAYOUT_AGGREGATE_CELLS: "LAYOUT_AGGREGATE_CELLS", // Annotation to split or merge header or side cells
  HCL_REFERENCE: "HCL_REFERENCE", // Annotation to associate Hierarchical Codelists to dimensions
  DATA_LINK: "DATA_LINK", // External url for the data visualization
  ROUNDING_STRATEGY: "ROUNDING_STRATEGY", // Annotation used to choose a rounding strategy for decimal observations
  ATTRIBUTE_AS_DIM: "ATTRIBUTE_AS_DIM", // Annotation used to choose which attribute should be represented as a dimension in the multidimensional table
  LAYOUT_CHART_OBS_ORDER_CARTESIAN: "LAYOUT_CHART_OBS_ORDER_CARTESIAN", // Annotation used to sort Cartesian Graph
  LAYOUT_CHART_OBS_ORDER_RADIAL: "LAYOUT_CHART_OBS_ORDER_RADIAL" // Annotation used to sort Radial Graph
};

const Form = forwardRef(
  (
    {config, initialNodeVisibility, languages, defaultCriteriaSelectionMode, hub, onSubmit, onCancel, timePeriod},
    ref
  ) => {
    const [tab, setTab] = useState("general");
    const {t} = useTranslation();

    const theme = useTheme();
    const [dateForm] = useState(moment());

    const pingApi = useApi(initRequest("ping", getNodePingUrl(), RequestMethod.POST));
    const [hasPinged, setHasPinged] = useState(false);

    const [restoreAnnotationsVisibility, setRestoreAnnotationVisibility] = useState(false);

    const viewers = viewersFactory(t);

    const defaultCustomColors = {
      isEnabled: false,
      palette: {
        text: {
          primary: theme.palette.text.primary
        },
        primary: {
          main: theme.palette.primary.main,
          contrastText: theme.palette.primary.contrastText
        },
        secondary: {
          main: theme.palette.secondary.main,
          contrastText: theme.palette.secondary.contrastText
        }
      }
    };

    const getCustomColorsFromExtras = config => {
      const customColorsValue = JSON.parse(config.extras.find(({key}) => key === "CustomColors")?.value || "{}");
      return merge(defaultCustomColors, customColorsValue);
    };

    const nodeSettingsForm = useForm({
      defaultValues: {
        active: false,
        visible: NodeVisibility.Yes,
        dataAccess: NodeDataAccessibility.All,
        criteriaSelectionMode: defaultCriteriaSelectionMode,
        ...config,
        annotationConfig: config?.extras?.find(({key}) => key === "AnnotationConfig")
          ? JSON.parse(config.extras.find(({key}) => key === "AnnotationConfig").value)
          : defaultAnnotations,
        showCategoryLevels: config?.showCategoryLevels || 1,
        decimalNumber: config?.decimalNumber ?? -2,
        roundingStrategy:
          config?.roundingStrategy === null || config?.roundingStrategy === undefined
            ? "NormalRounding"
            : config?.roundingStrategy,
        decimalSeparator: config?.decimalSeparator || getI18nObjFromLanguages(languages, ","),
        catalogNavigationMode: config?.catalogNavigationMode || "List",
        hiddenAttributes: config?.extras?.find(({key}) => key === "HiddenAttributes")
          ? JSON.parse(config.extras.find(({key}) => key === "HiddenAttributes").value)
          : [],
        restDataResponseXml:
          config?.extras?.find(({key}) => key === "RestDataResponseXml") &&
          (config.extras.find(({key}) => key === "RestDataResponseXml").value === "false" ||
            config.extras.find(({key}) => key === "RestDataResponseXml").value === "true")
            ? config.extras.find(({key}) => key === "RestDataResponseXml").value
            : "",
        pingArtifact: "ConceptScheme",
        downloadFormats: config?.extras?.find(({key}) => key === "DownloadFormats")
          ? JSON.parse(config.extras.find(({key}) => key === "DownloadFormats").value)
          : [],
        typesOfChart: config?.extras?.find(({key}) => key === "ChartsSettings")
          ? JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value).length === viewers.length - 2
            ? []
            : JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value)
          : [],
        typesOfChartShowsOnly: config?.extras?.find(({key}) => key === "ChartsSettings")
          ? JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value).length < viewers.length - 2 &&
            JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value).length !== 0
            ? true
            : false
          : false,
        typeOfChartShowsNone: config?.extras?.find(({key}) => key === "ChartsSettings")
          ? JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value).length === 0
            ? true
            : false
          : false,
        typesOfChartShowsAll: config?.extras?.find(({key}) => key === "ChartsSettings")
          ? JSON.parse(config.extras.find(({key}) => key === "ChartsSettings").value).length === viewers.length - 2
            ? true
            : false
          : true,
        supportPostFilters:
          config?.extras?.find(({key}) => key === "SupportPostFilters") &&
          (config.extras.find(({key}) => key === "SupportPostFilters").value === "false" ||
            config.extras.find(({key}) => key === "SupportPostFilters").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "SupportPostFilters").value)
            : false,
        supportPostFiltersStructure:
          config?.extras?.find(({key}) => key === "SupportPostFiltersStructure") &&
          (config.extras.find(({key}) => key === "SupportPostFiltersStructure").value === "false" ||
            config.extras.find(({key}) => key === "SupportPostFiltersStructure").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "SupportPostFiltersStructure").value)
            : false,
        enableEndpointV20:
          config?.extras?.find(({key}) => key === "EnableEndPointV20") &&
          (config.extras.find(({key}) => key === "EnableEndPointV20").value === "false" ||
            config.extras.find(({key}) => key === "EnableEndPointV20").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "EnableEndPointV20").value)
            : false,
        endpointV20: config?.extras?.find(({key}) => key === "EndPointV20")
          ? config.extras.find(({key}) => key === "EndPointV20").value || ""
          : "",
        defaultLastNPeriods: config?.extras?.find(({key}) => key === "DefaultLastNPeriods")
          ? config.extras.find(({key}) => key === "DefaultLastNPeriods").value || ""
          : "",
        showVirtualDataflow:
          config?.extras?.find(({key}) => key === "ShowVirtualDataflow") &&
          (config.extras.find(({key}) => key === "ShowVirtualDataflow").value === "false" ||
            config.extras.find(({key}) => key === "ShowVirtualDataflow").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "ShowVirtualDataflow").value)
            : false,
        queryInfo:
          config?.extras?.find(({key}) => key === "QueryInfo") &&
          (config.extras.find(({key}) => key === "QueryInfo").value === "false" ||
            config.extras.find(({key}) => key === "QueryInfo").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "QueryInfo").value)
            : false,
        queryInfoBaseUrl: config?.extras?.find(({key}) => key === "QuerySDMXBaseUrl")
          ? config.extras.find(({key}) => key === "QuerySDMXBaseUrl").value || ""
          : "",
        showOnlyFileDataflow:
          config?.extras?.find(({key}) => key === "ShowOnlyFileDataflow") &&
          (config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value === "false" ||
            config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value)
            : true,
        timePeriodRangeStart: config?.extras?.find(({key}) => key === "TimePeriodRangeStart")
          ? config.extras.find(({key}) => key === "TimePeriodRangeStart").value || null
          : null,
        timePeriodRangeEnd: config?.extras?.find(({key}) => key === "TimePeriodRangeEnd")
          ? config.extras.find(({key}) => key === "TimePeriodRangeEnd").value || null
          : null,
        timePeriodFormatYear: config?.extras?.find(({key}) => key === "TimePeriodFormatYear")
          ? JSON.parse(config.extras.find(({key}) => key === "TimePeriodFormatYear").value) ||
            getI18nObjFromLanguages(languages, "YYYY")
          : getI18nObjFromLanguages(languages, "YYYY"),
        timePeriodFormatMonth: config?.extras?.find(({key}) => key === "TimePeriodFormatMonth")
          ? JSON.parse(config.extras.find(({key}) => key === "TimePeriodFormatMonth").value) ||
            getI18nObjFromLanguages(languages, "YYYY-MM")
          : getI18nObjFromLanguages(languages, "YYYY-MM"),
        timePeriodFormatDay: config?.extras?.find(({key}) => key === "TimePeriodFormatDay")
          ? JSON.parse(config.extras.find(({key}) => key === "TimePeriodFormatDay").value) ||
            getI18nObjFromLanguages(languages, "YYYY-MM-DD")
          : getI18nObjFromLanguages(languages, "YYYY-MM-DD"),
        timePeriodFormatQuarter: config?.extras?.find(({key}) => key === "TimePeriodFormatQuarter")
          ? JSON.parse(config.extras.find(({key}) => key === "TimePeriodFormatQuarter").value) ||
            getI18nObjFromLanguages(languages, "YYYY-[Q]Q")
          : getI18nObjFromLanguages(languages, "YYYY-[Q]Q"),
        timePeriodFormatSemester: config?.extras?.find(({key}) => key === "TimePeriodFormatSemester")
          ? JSON.parse(config.extras.find(({key}) => key === "TimePeriodFormatSemester").value) ||
            getI18nObjFromLanguages(languages, "YYYY-[S]Sm")
          : getI18nObjFromLanguages(languages, "YYYY-[S]Sm"),
        hideLabelOnCategoryWithImage:
          config?.extras?.find(({key}) => key === "HideLabelOnCategoryWithImage") &&
          (config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value === "false" ||
            config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value)
            : false,
        temporalDimOrder:
          config?.extras?.find(({key}) => key === "TemporalDimOrder") &&
          (config.extras.find(({key}) => key === "TemporalDimOrder").value === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC ||
            config.extras.find(({key}) => key === "TemporalDimOrder").value === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC)
            ? config.extras.find(({key}) => key === "TemporalDimOrder").value
            : TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET,
        hierarchyOnlyAttributes: config?.extras?.find(({key}) => key === "HierarchyOnlyAttributes")
          ? JSON.parse(config.extras.find(({key}) => key === "HierarchyOnlyAttributes").value)
          : [],
        hideHierarchyOnlyRows:
          config?.extras?.find(({key}) => key === "HideHierarchyOnlyRows") &&
          (config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value === "false" ||
            config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value)
            : false,
        supportedLanguages: (config?.extras?.find(({key}) => key === "SupportedLanguages")
          ? JSON.parse(config.extras.find(({key}) => key === "SupportedLanguages").value)
          : []
        ).filter(lang => hub.supportedLanguages.includes(lang)),
        defaultLanguage:
          config?.extras?.find(({key}) => key === "DefaultLanguage") &&
          hub.supportedLanguages.includes(config.extras.find(({key}) => key === "DefaultLanguage").value)
            ? config.extras.find(({key}) => key === "DefaultLanguage").value || null
            : null,
        enableCriteriaCache:
          config?.extras?.find(({key}) => key === "EnableCriteriaCache") &&
          (config.extras.find(({key}) => key === "EnableCriteriaCache").value === "false" ||
            config.extras.find(({key}) => key === "EnableCriteriaCache").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "EnableCriteriaCache").value)
            : false,
        anticipationExceedingMaxNumRecords:
          config?.extras?.find(({key}) => key === "AnticipationExceedingMaxNumRecords") &&
          (config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value === "false" ||
            config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value)
            : false,
        forceLinkedDataflowAnnotations:
          config?.extras?.find(({key}) => key === "ForceLinkedDataflowAnnotations") &&
          (config.extras.find(({key}) => key === "ForceLinkedDataflowAnnotations").value === "false" ||
            config.extras.find(({key}) => key === "ForceLinkedDataflowAnnotations").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "ForceLinkedDataflowAnnotations").value)
            : false,
        flatTableAvailable:
          config?.extras?.find(({key}) => key === "FlatTableAvailable") &&
          (config.extras.find(({key}) => key === "FlatTableAvailable").value === "false" ||
            config.extras.find(({key}) => key === "FlatTableAvailable").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "FlatTableAvailable").value)
            : false,
        showHubDashboards:
          config?.extras?.find(({key}) => key === "ShowHubDashboards") &&
          (config.extras.find(({key}) => key === "ShowHubDashboards").value === "false" ||
            config.extras.find(({key}) => key === "ShowHubDashboards").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "ShowHubDashboards").value)
            : false,
        customColors: config?.extras?.find(({key}) => key === "CustomColors")
          ? getCustomColorsFromExtras(config)
          : defaultCustomColors,
        generateSourceDownloadsFromCache:
          config?.extras?.find(({key}) => key === "GenerateSourceDownloadsFromCache") &&
          (config.extras.find(({key}) => key === "GenerateSourceDownloadsFromCache").value === "false" ||
            config.extras.find(({key}) => key === "GenerateSourceDownloadsFromCache").value === "true")
            ? JSON.parse(config.extras.find(({key}) => key === "GenerateSourceDownloadsFromCache").value)
            : false
      }
    });

    const {
      register,
      handleSubmit,
      watch,
      getValues,
      setValue,
      clearErrors,
      formState: {errors}
    } = nodeSettingsForm;

    const enableHttpAuth = watch("enableHttpAuth");
    const enableProxy = watch("enableProxy");
    const supportPostFiltersStructure = watch("supportPostFiltersStructure");
    const enableEndpointV20 = watch("enableEndpointV20");
    const showVirtualDataflow = watch("showVirtualDataflow");
    const isCriteriaSelectionModeDynamic = (watch("criteriaSelectionMode") || "") === STEP_BY_STEP_DYNAMIC;
    const isLastNPeriodsDisabled =
      (watch("criteriaSelectionMode") || "") !== STEP_BY_STEP_DYNAMIC &&
      (watch("criteriaSelectionMode") || "") !== STEP_BY_STEP_PARTIAL &&
      (watch("criteriaSelectionMode") || "") !== ALL_PARTIAL &&
      (watch("criteriaSelectionMode") || "") !== ALL_PARTIAL_OPTIMIZED;
    const supportedLanguages = getValues("supportedLanguages");
    const defaultLanguage = getValues("defaultLanguage");

    useImperativeHandle(ref, () => ({
      submit(f) {
        handleSubmit(val => {
          const data = {
            ...config,
            ...val,
            type: "SDMX-REST",
            annotationConfig: undefined,
            restDataResponseXml: undefined,
            hiddenAttributes: undefined,
            downloadFormats: undefined,
            supportPostFilters: undefined,
            supportPostFiltersStructure: undefined,
            enableEndpointV20: undefined,
            endpointV20: undefined,
            defaultLastNPeriods: undefined,
            showVirtualDataflow: undefined,
            queryInfo: undefined,
            queryInfoBaseUrl: undefined,
            showOnlyFileDataflow: undefined,
            timePeriodRangeStart: undefined,
            timePeriodRangeEnd: undefined,
            timePeriodFormatYear: undefined,
            timePeriodFormatMonth: undefined,
            timePeriodFormatDay: undefined,
            timePeriodFormatQuarter: undefined,
            timePeriodFormatSemester: undefined,
            hideLabelOnCategoryWithImage: undefined,
            temporalDimOrder: undefined,
            hierarchyOnlyAttributes: undefined,
            hideHierarchyOnlyRows: undefined,
            supportedLanguages: undefined,
            defaultLanguage: undefined,
            typesOfChart: undefined,
            typesOfChartShowsNone: undefined,
            typesOfChartShowsAll: undefined,
            typesOfChartShowsOnly: undefined,
            enableCriteriaCache: undefined,
            anticipationExceedingMaxNumRecords: undefined,
            forceLinkedDataflowAnnotations: undefined,
            flatTableAvailable: undefined,
            showHubDashboards: undefined,
            customColors: undefined,
            generateSourceDownloadsFromCache: undefined
          };

          if (data.extras?.find(({key}) => key === "AnnotationConfig")) {
            data.extras.find(({key}) => key === "AnnotationConfig").value = JSON.stringify(val.annotationConfig);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "AnnotationConfig",
              value: JSON.stringify(val.annotationConfig),
              isPublic: false
            });
          }

          if (data.extras?.find(({key}) => key === "RestDataResponseXml")) {
            data.extras.find(({key}) => key === "RestDataResponseXml").value = val.restDataResponseXml;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "RestDataResponseXml",
              value: val.restDataResponseXml,
              isPublic: false
            });
          }

          if (data.extras?.find(({key}) => key === "SupportPostFilters")) {
            data.extras.find(({key}) => key === "SupportPostFilters").value = JSON.stringify(val.supportPostFilters);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "SupportPostFilters",
              value: JSON.stringify(val.supportPostFilters),
              isPublic: false
            });
          }

          if (data.extras?.find(({key}) => key === "SupportPostFiltersStructure")) {
            data.extras.find(({key}) => key === "SupportPostFiltersStructure").value = JSON.stringify(
              val.supportPostFiltersStructure
            );
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "SupportPostFiltersStructure",
              value: JSON.stringify(val.supportPostFiltersStructure),
              isPublic: false
            });
          }

          if (data.extras?.find(({key}) => key === "HiddenAttributes")) {
            data.extras.find(({key}) => key === "HiddenAttributes").value = JSON.stringify(val.hiddenAttributes);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "HiddenAttributes",
              value: JSON.stringify(val.hiddenAttributes),
              isPublic: true
            });
          }

          if (data.extras?.find(({key}) => key === "DownloadFormats")) {
            data.extras.find(({key}) => key === "DownloadFormats").value = JSON.stringify(val.downloadFormats);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "DownloadFormats",
              value: JSON.stringify(val.downloadFormats),
              isPublic: true
            });
          }

          if (data.extras?.find(({key}) => key === "EnableEndPointV20")) {
            data.extras.find(({key}) => key === "EnableEndPointV20").value = JSON.stringify(val.enableEndpointV20);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "EnableEndPointV20",
              value: JSON.stringify(val.enableEndpointV20),
              isPublic: false
            });
          }

          if (data.extras?.find(({key}) => key === "EndPointV20")) {
            data.extras.find(({key}) => key === "EndPointV20").value = val.endpointV20;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "EndPointV20",
              value: val.endpointV20 || "",
              isPublic: false
            });
          }

          const defaultLastNPeriodsExtra = data.extras?.find(({key}) => key === "DefaultLastNPeriods");
          const defaultLastNPeriodsExtraPublic = true;
          if (defaultLastNPeriodsExtra) {
            defaultLastNPeriodsExtra.value = val.defaultLastNPeriods;
            defaultLastNPeriodsExtra.isPublic = defaultLastNPeriodsExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "DefaultLastNPeriods",
              value: val.defaultLastNPeriods,
              isPublic: defaultLastNPeriodsExtraPublic
            });
          }

          if (data.extras?.find(({key}) => key === "ShowVirtualDataflow")) {
            data.extras.find(({key}) => key === "ShowVirtualDataflow").value = JSON.stringify(val.showVirtualDataflow);
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "ShowVirtualDataflow",
              value: JSON.stringify(val.showVirtualDataflow),
              isPublic: false
            });
          }

          const queryInfoExtra = data.extras?.find(({key}) => key === "QueryInfo");
          const queryInfoExtraPublic = true;
          if (queryInfoExtra) {
            queryInfoExtra.value = JSON.stringify(val.queryInfo);
            queryInfoExtra.isPublic = queryInfoExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "QueryInfo",
              value: JSON.stringify(val.queryInfo),
              isPublic: queryInfoExtraPublic
            });
          }

          const queryInfoBaseUrlExtra = data.extras?.find(({key}) => key === "QuerySDMXBaseUrl");
          const queryInfoBaseUrlExtraPublic = false;
          if (queryInfoBaseUrlExtra) {
            queryInfoBaseUrlExtra.value = val.queryInfoBaseUrl;
            queryInfoBaseUrlExtra.isPublic = queryInfoBaseUrlExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "QuerySDMXBaseUrl",
              value: val.queryInfoBaseUrl,
              isPublic: queryInfoBaseUrlExtraPublic
            });
          }

          const showOnlyFileDataflowExtra = data.extras?.find(({key}) => key === "ShowOnlyFileDataflow");
          const showOnlyFileDataflowExtraPublic = false;
          if (showOnlyFileDataflowExtra) {
            showOnlyFileDataflowExtra.value = JSON.stringify(val.showOnlyFileDataflow);
            showOnlyFileDataflowExtra.isPublic = showOnlyFileDataflowExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "ShowOnlyFileDataflow",
              value: JSON.stringify(val.showOnlyFileDataflow),
              isPublic: showOnlyFileDataflowExtraPublic
            });
          }

          const timePeriodRangeStartExtra = data.extras?.find(({key}) => key === "TimePeriodRangeStart");
          const timePeriodRangeStartExtraPublic = true;
          if (timePeriodRangeStartExtra) {
            timePeriodRangeStartExtra.value = val.timePeriodRangeStart;
            timePeriodRangeStartExtra.isPublic = timePeriodRangeStartExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodRangeStart",
              value: val.timePeriodRangeStart,
              isPublic: timePeriodRangeStartExtraPublic
            });
          }

          const timePeriodRangeEndExtra = data.extras?.find(({key}) => key === "TimePeriodRangeEnd");
          const timePeriodRangeEndExtraPublic = true;
          if (timePeriodRangeEndExtra) {
            timePeriodRangeEndExtra.value = val.timePeriodRangeEnd;
            timePeriodRangeEndExtra.isPublic = timePeriodRangeEndExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodRangeEnd",
              value: val.timePeriodRangeEnd,
              isPublic: timePeriodRangeEndExtraPublic
            });
          }

          const timePeriodFormatYearExtra = data.extras?.find(({key}) => key === "TimePeriodFormatYear");
          const timePeriodFormatYearExtraPublic = true;
          if (timePeriodFormatYearExtra) {
            timePeriodFormatYearExtra.value = JSON.stringify(val.timePeriodFormatYear);
            timePeriodFormatYearExtra.isPublic = timePeriodFormatYearExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodFormatYear",
              value: JSON.stringify(val.timePeriodFormatYear),
              isPublic: timePeriodFormatYearExtraPublic
            });
          }

          const timePeriodFormatMonthExtra = data.extras?.find(({key}) => key === "TimePeriodFormatMonth");
          const timePeriodFormatMonthExtraPublic = true;
          if (timePeriodFormatMonthExtra) {
            timePeriodFormatMonthExtra.value = JSON.stringify(val.timePeriodFormatMonth);
            timePeriodFormatMonthExtra.isPublic = timePeriodFormatMonthExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodFormatMonth",
              value: JSON.stringify(val.timePeriodFormatMonth),
              isPublic: timePeriodFormatMonthExtraPublic
            });
          }

          const timePeriodFormatDayExtra = data.extras?.find(({key}) => key === "TimePeriodFormatDay");
          const timePeriodFormatDayExtraPublic = true;
          if (timePeriodFormatDayExtra) {
            timePeriodFormatDayExtra.value = JSON.stringify(val.timePeriodFormatDay);
            timePeriodFormatDayExtra.isPublic = timePeriodFormatDayExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodFormatDay",
              value: JSON.stringify(val.timePeriodFormatDay),
              isPublic: timePeriodFormatDayExtraPublic
            });
          }

          const timePeriodFormatQuarterExtra = data.extras?.find(({key}) => key === "TimePeriodFormatQuarter");
          const timePeriodFormatQuarterExtraPublic = true;
          if (timePeriodFormatQuarterExtra) {
            timePeriodFormatQuarterExtra.value = JSON.stringify(val.timePeriodFormatQuarter);
            timePeriodFormatQuarterExtra.isPublic = timePeriodFormatQuarterExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodFormatQuarter",
              value: JSON.stringify(val.timePeriodFormatQuarter),
              isPublic: timePeriodFormatQuarterExtraPublic
            });
          }

          const timePeriodFormatSemesterExtra = data.extras?.find(({key}) => key === "TimePeriodFormatSemester");
          const timePeriodFormatSemesterExtraPublic = true;
          if (timePeriodFormatSemesterExtra) {
            timePeriodFormatSemesterExtra.value = JSON.stringify(val.timePeriodFormatSemester);
            timePeriodFormatSemesterExtra.isPublic = timePeriodFormatSemesterExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TimePeriodFormatSemester",
              value: JSON.stringify(val.timePeriodFormatSemester),
              isPublic: timePeriodFormatSemesterExtraPublic
            });
          }

          const hideLabelOnCategoryWithImageExtra = data.extras?.find(
            ({key}) => key === "HideLabelOnCategoryWithImage"
          );
          const hideLabelOnCategoryWithImageExtraPublic = true;
          if (hideLabelOnCategoryWithImageExtra) {
            hideLabelOnCategoryWithImageExtra.value = JSON.stringify(val.hideLabelOnCategoryWithImage);
            hideLabelOnCategoryWithImageExtra.isPublic = hideLabelOnCategoryWithImageExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "HideLabelOnCategoryWithImage",
              value: JSON.stringify(val.hideLabelOnCategoryWithImage),
              isPublic: hideLabelOnCategoryWithImageExtraPublic
            });
          }

          const temporalDimOrderExtra = data.extras?.find(({key}) => key === "TemporalDimOrder");
          const temporalDimOrderExtraPublic = false;
          if (temporalDimOrderExtra) {
            if (val.temporalDimOrder !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET) {
              temporalDimOrderExtra.value = val.temporalDimOrder;
              temporalDimOrderExtra.isPublic = temporalDimOrderExtraPublic;
            } else {
              data.extras = data.extras.filter(({key}) => key !== "TemporalDimOrder");
            }
          } else if (val.temporalDimOrder !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET) {
            data.extras = data.extras || [];
            data.extras.push({
              key: "TemporalDimOrder",
              value: val.temporalDimOrder,
              isPublic: temporalDimOrderExtraPublic
            });
          }

          const hierarchyOnlyAttributesExtra = data.extras?.find(({key}) => key === "HierarchyOnlyAttributes");
          const hierarchyOnlyAttributesExtraPublic = true;
          if (hierarchyOnlyAttributesExtra) {
            hierarchyOnlyAttributesExtra.value = JSON.stringify(val.hierarchyOnlyAttributes);
            hierarchyOnlyAttributesExtra.isPublic = hierarchyOnlyAttributesExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "HierarchyOnlyAttributes",
              value: JSON.stringify(val.hierarchyOnlyAttributes),
              isPublic: hierarchyOnlyAttributesExtraPublic
            });
          }

          const hideHierarchyOnlyRowsExtra = data.extras?.find(({key}) => key === "HideHierarchyOnlyRows");
          const hideHierarchyOnlyRowsExtraPublic = true;
          if (hideHierarchyOnlyRowsExtra) {
            hideHierarchyOnlyRowsExtra.value = JSON.stringify(val.hideHierarchyOnlyRows);
            hideHierarchyOnlyRowsExtra.isPublic = hideHierarchyOnlyRowsExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "HideHierarchyOnlyRows",
              value: JSON.stringify(val.hideHierarchyOnlyRows),
              isPublic: hideHierarchyOnlyRowsExtraPublic
            });
          }

          const supportedLanguagesExtra = data.extras?.find(({key}) => key === "SupportedLanguages");
          const supportedLanguagesExtraPublic = true;
          if (supportedLanguagesExtra) {
            supportedLanguagesExtra.value = JSON.stringify(val.supportedLanguages);
            supportedLanguagesExtra.isPublic = supportedLanguagesExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "SupportedLanguages",
              value: JSON.stringify(val.supportedLanguages),
              isPublic: supportedLanguagesExtraPublic
            });
          }

          const defaultLanguageExtra = data.extras?.find(({key}) => key === "DefaultLanguage");
          const defaultLanguageExtraPublic = true;
          if (defaultLanguageExtra) {
            defaultLanguageExtra.value = val.defaultLanguage;
            defaultLanguageExtra.isPublic = defaultLanguageExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "DefaultLanguage",
              value: val.defaultLanguage,
              isPublic: defaultLanguageExtraPublic
            });
          }

          const {typesOfChart} = val;
          const allCharts = viewers.slice(2).map(({icon, ...rest}) => rest);
          const isAllSelected = val.typesOfChartShowsAll;
          const chartsSettingsExtra = data.extras?.find(({key}) => key === "ChartsSettings");
          if (chartsSettingsExtra) {
            chartsSettingsExtra.value = isAllSelected
              ? JSON.stringify(allCharts)
              : (typesOfChart && JSON.stringify(typesOfChart)) || [];
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "ChartsSettings",
              value: isAllSelected ? JSON.stringify(allCharts) : (typesOfChart && JSON.stringify(typesOfChart)) || [],
              isPublic: true
            });
          }

          const enableCriteriaCacheExtra = data.extras?.find(({key}) => key === "EnableCriteriaCache");
          if (enableCriteriaCacheExtra) {
            enableCriteriaCacheExtra.value = val.enableCriteriaCache;
            enableCriteriaCacheExtra.isPublic = false;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "EnableCriteriaCache",
              value: JSON.stringify(val.enableCriteriaCache),
              isPublic: false
            });
          }

          const anticipationExceedingMaxNumRecordsExtra = data.extras?.find(
            ({key}) => key === "AnticipationExceedingMaxNumRecords"
          );
          if (anticipationExceedingMaxNumRecordsExtra) {
            anticipationExceedingMaxNumRecordsExtra.value = val.anticipationExceedingMaxNumRecords;
            anticipationExceedingMaxNumRecordsExtra.isPublic = false;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "AnticipationExceedingMaxNumRecords",
              value: JSON.stringify(val.anticipationExceedingMaxNumRecords),
              isPublic: false
            });
          }

          const forceLinkedDataflowAnnotationsExtra = data.extras?.find(
            ({key}) => key === "ForceLinkedDataflowAnnotations"
          );
          if (forceLinkedDataflowAnnotationsExtra) {
            forceLinkedDataflowAnnotationsExtra.value = val.forceLinkedDataflowAnnotations;
            forceLinkedDataflowAnnotationsExtra.isPublic = false;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "ForceLinkedDataflowAnnotations",
              value: JSON.stringify(val.forceLinkedDataflowAnnotations),
              isPublic: false
            });
          }

          const flatTableAvailableExtra = data.extras?.find(({key}) => key === "FlatTableAvailable");
          if (flatTableAvailableExtra) {
            flatTableAvailableExtra.value = val.flatTableAvailable;
            flatTableAvailableExtra.isPublic = false;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "FlatTableAvailable",
              value: JSON.stringify(val.flatTableAvailable),
              isPublic: false
            });
          }

          const showHubDashboardsExtra = data.extras?.find(({key}) => key === "ShowHubDashboards");
          const showHubDashboardsExtraPublic = true;
          if (showHubDashboardsExtra) {
            showHubDashboardsExtra.value = JSON.stringify(val.showHubDashboards);
            showHubDashboardsExtra.isPublic = showHubDashboardsExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "ShowHubDashboards",
              value: JSON.stringify(val.showHubDashboards),
              isPublic: showHubDashboardsExtraPublic
            });
          }

          const customColorExtra = data.extras?.find(({key}) => key === "CustomColors");
          const customColorExtraPublic = true;
          if (customColorExtra) {
            customColorExtra.value = JSON.stringify(val.customColors);
            customColorExtra.isPublic = customColorExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "CustomColors",
              value: JSON.stringify(val.customColors),
              isPublic: customColorExtraPublic
            });
          }

          const generateSourceDownloadsFromCacheExtra = data.extras?.find(
            ({key}) => key === "GenerateSourceDownloadsFromCache"
          );
          const generateSourceDownloadsFromCacheExtraPublic = false;
          if (generateSourceDownloadsFromCacheExtra) {
            generateSourceDownloadsFromCacheExtra.value = JSON.stringify(val.generateSourceDownloadsFromCache);
            generateSourceDownloadsFromCacheExtra.isPublic = generateSourceDownloadsFromCacheExtraPublic;
          } else {
            data.extras = data.extras || [];
            data.extras.push({
              key: "GenerateSourceDownloadsFromCache",
              value: JSON.stringify(val.generateSourceDownloadsFromCache),
              isPublic: generateSourceDownloadsFromCacheExtraPublic
            });
          }

          onSubmit(data);
          f(data);
        })();

        const firstFieldWithErrors = Object.keys(errors)[0];
        if (firstFieldWithErrors) {
          switch (firstFieldWithErrors) {
            case "code":
            case "agency":
            case "title":
            case "slogan":
            case "supportedLanguages":
            case "defaultLanguage": {
              setTab("general");
              break;
            }
            case "showCategoryLevels":
            case "decimalNumber":
            case "decimalSeparator":
            case "labelDimensionTerritorials":
            case "categorySchemaExcludes":
            case "catalogNavigationMode":
            case "hiddenAttributes":
            case "downloadFormats":
            case "defaultLastNPeriods":
            case "timePeriodRangeStart":
            case "timePeriodRangeEnd":
            case "timePeriodFormatYear":
            case "timePeriodFormatMonth":
            case "timePeriodFormatDay":
            case "timePeriodFormatQuarter":
            case "timePeriodFormatSemester":
            case "hideLabelOnCategoryWithImage":
            case "temporalDimOrder":
            case "hierarchyOnlyAttributes":
            case "hideHierarchyOnlyRows": {
              setTab("view");
              break;
            }
            case "ttlCatalog":
            case "ttlDataflow": {
              setTab("cache");
              break;
            }
            default: {
              setTab("endpoint");
            }
          }
        }
      },
      cancel(f) {
        onCancel();
        f();
      }
    }));

    /* custom register */
    useEffect(() => {
      register("code", {
        required: t("commons.validation.required")
      });
      register("title");
      register("agency", {
        required: t("commons.validation.required")
      });
      register("active");
      register("visible");
      register("dataAccess");
      register("default");
      register("slogan");
      register("backgroundMediaURL");
      register("logo");
      register("description");
      register("criteriaSelectionMode", {
        required: t("commons.validation.required")
      });
      register("endPoint", {
        required: t("commons.validation.required")
      });
      register("supportPostFilters");
      register("supportPostFiltersStructure");
      register("enableEndpointV20");
      register("endpointV20", {
        validate: val =>
          getValues("enableEndpointV20") ? (val || "").length > 0 || t("commons.validation.required") : true
      });
      register("enableHttpAuth");
      register("authHttpUsername", {
        validate: val =>
          getValues("enableHttpAuth") ? (val || "").length > 0 || t("commons.validation.required") : true
      });
      register("authHttpPassword");
      register("enableProxy");
      register("proxyAddress", {
        validate: val => (getValues("enableProxy") ? (val || "").length > 0 || t("commons.validation.required") : true)
      });
      register("proxyPort", {
        validate: val =>
          getValues("enableProxy")
            ? (isValidIntegerInInclusiveRange(val) && validator.isPort(val + "")) ||
              t("scenes.nodeSettings.fields.proxyPort.validation")
            : true
      });
      register("proxyUsername");
      register("proxyPassword");
      register("annotationConfig.ORDER");
      register("annotationConfig.ORDER_CATEGORY_SCHEMA");
      register("annotationConfig.ORDER_CATEGORY");
      register("annotationConfig.ORDER_CODELIST");
      register("annotationConfig.NOT_DISPLAYED");
      register("annotationConfig.DEFAULT");
      register("annotationConfig.TIME_PERIOD_START");
      register("annotationConfig.TIME_PERIOD_END");
      register("annotationConfig.LASTNPERIOD");
      register("annotationConfig.LASTNOBSERVATIONS");
      register("annotationConfig.LAYOUT_ROW");
      register("annotationConfig.LAYOUT_COLUMN");
      register("annotationConfig.LAYOUT_FILTER");
      register("annotationConfig.LAYOUT_ROW_SECTION");
      register("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN");
      register("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN");
      register("annotationConfig.LAYOUT_CHART_FILTER_CARTESIAN");
      register("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_RADIAL");
      register("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_RADIAL");
      register("annotationConfig.LAYOUT_CHART_FILTER_RADIAL");
      register("annotationConfig.LAYOUT_MAP_FILTER");
      register("annotationConfig.CRITERIA_SELECTION");
      register("annotationConfig.ATTACHED_DATA_FILES");
      register("annotationConfig.LAYOUT_DECIMAL_SEPARATOR");
      register("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS");
      register("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER");
      register("annotationConfig.DATAFLOW_NOTES");
      register("annotationConfig.DATAFLOW_SOURCE");
      register("annotationConfig.METADATA_URL");
      register("annotationConfig.KEYWORDS");
      register("annotationConfig.LAYOUT_DEFAULT_PRESENTATION");
      register("annotationConfig.GEO_ID");
      register("annotationConfig.LAST_UPDATE");
      register("annotationConfig.VIRTUAL_DATAFLOW_NODE");
      register("annotationConfig.DATAFLOW_CATALOG_TYPE");
      register("annotationConfig.DATAFLOW_HIDDEN");
      register("annotationConfig.DISABLED_VIEWERS");
      register("annotationConfig.TABLE_LOCKED_DIMS");
      register("annotationConfig.CHART_LOCKED_DIMS_CARTESIAN");
      register("annotationConfig.CHART_LOCKED_DIMS_RADIAL");
      register("annotationConfig.TEMPORAL_DIM_ORDER");
      register("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC");
      register("annotationConfig.FLAT_TABLE_AVAILABLE");
      register("annotationConfig.DDB_CONTENT_CONSTRAINT");
      register("annotationConfig.LAYOUT_AGGREGATE_CELLS");
      register("annotationConfig.HCL_REFERENCE");
      register("annotationConfig.DATA_LINK");
      register("annotationConfig.ROUNDING_STRATEGY");
      register("annotationConfig.ATTRIBUTE_AS_DIM");
      register("annotationConfig.LAYOUT_CHART_OBS_ORDER_CARTESIAN");
      register("annotationConfig.LAYOUT_CHART_OBS_ORDER_RADIAL");
      register("ttlCatalog", {
        validate: val =>
          (val || "").length === 0 ||
          isValidIntegerInInclusiveRange(val, -1) ||
          t("scenes.nodeSettings.validation.ttlCatalog")
      });
      register("ttlDataflow", {
        validate: val =>
          (val || "").length === 0 ||
          isValidIntegerInInclusiveRange(val, -1) ||
          t("scenes.nodeSettings.validation.ttlDataflow")
      });
      register("showDataflowUncategorized");
      register("showDataflowNotInProduction");
      register("showCategoryLevels", {
        required: t("commons.validation.required")
      });
      register("decimalNumber");
      register("decimalSeparator", {
        validate: val => validateI18nObj(val) || t("commons.validation.requiredAnyLanguage")
      });
      register("roundingStrategy");
      register("labelDimensionTerritorials");
      register("categorySchemaExcludes");
      register("catalogNavigationMode", {
        required: t("commons.validation.required")
      });
      register("hiddenAttributes");
      register("restDataResponseXml", {
        required: t("commons.validation.required")
      });
      register("downloadFormats");
      register("defaultLastNPeriods", {
        validate: val => !val || val > 0 || t("commons.validation.positiveInteger")
      });
      register("showVirtualDataflow");
      register("queryInfo");
      register("queryInfoBaseUrl");
      register("showOnlyFileDataflow");
      register("timePeriodRangeStart", {
        validate: val => !val || moment(val).isValid() || t("commons.validation.validDate")
      });
      register("timePeriodRangeEnd", {
        validate: val => !val || moment(val).isValid() || t("commons.validation.validDate")
      });
      register("hideLabelOnCategoryWithImage");
      register("temporalDimOrder");
      register("hierarchyOnlyAttributes");
      register("hideHierarchyOnlyRows");
      register("supportedLanguages");
      register("defaultLanguage", {
        validate: val =>
          (getValues("supportedLanguages") || []).length > 0
            ? (val || "").length > 0 || t("commons.validation.required")
            : true
      });
      register("enableCriteriaCache");
      register("anticipationExceedingMaxNumRecords");
      register("forceLinkedDataflowAnnotations");
      register("flatTableAvailable");
      register("timePeriodFormatYear");
      register("timePeriodFormatMonth");
      register("timePeriodFormatDay");
      register("timePeriodFormatQuarter");
      register("timePeriodFormatSemester");
      register("pingArtifact");
      register("typesOfChart", {
        validate: val =>
          getValues("typesOfChart") && getValues("typesOfChartShowsOnly")
            ? (val || "").length > 0 || t("commons.validation.required")
            : true
      });
      register("typesOfChartShowsOnly");
      register("typesOfChartShowsAll");
      register("typesOfChartShowsNone");
      register("showHubDashboards");
      register("customColors");
      register("generateSourceDownloadsFromCache");
    }, [register, getValues, t]);

    return (
      <Box sx={{height: "100%"}}>
        <Tabs value={tab} onChange={(_, tab) => setTab(tab)} variant="scrollable" scrollButtons="auto">
          <Tab
            id="node-settings-form__general-tab"
            value="general"
            label={t("scenes.nodeSettings.tabs.general.label")}
          />
          <Tab
            id="node-settings-form__infos-tab"
            value="infos"
            label={t("scenes.nodeSettings.tabs.information.label")}
          />
          <Tab
            id="node-settings-form__endpoint-tab"
            value="endpoint"
            label={t("scenes.nodeSettings.tabs.endpoint.label")}
          />
          <Tab
            id="node-settings-form__annotations-tab"
            value="annotations"
            label={t("scenes.nodeSettings.tabs.annotations.label")}
          />
          <Tab id="node-settings-form__view-tab" value="view" label={t("scenes.nodeSettings.tabs.view.label")} />
          <Tab id="node-settings-form__colors-tab" value="colors" label={t("scenes.nodeSettings.tabs.colors.label")} />
          <Tab id="node-settings-form__cache-tab" value="cache" label={t("scenes.nodeSettings.tabs.cache.label")} />
          <Tab
            value="extra"
            label={t("scenes.nodeSettings.tabs.extra.label")}
            id="node-settings__tabs__extra"
            style={{display: "none"}}
          />
        </Tabs>
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100% - 56px)",
            marginTop: "8px",
            padding: "0 4px"
          }}
        >
          <TabPanel value="general" selected={tab}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="node-settings-form__code-input"
                    disabled={!!config}
                    name="code"
                    variant="outlined"
                    label={
                      <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.code.tooltip")}>
                        {t("scenes.nodeSettings.fields.code.label")}
                      </FormLabelWithTooltip>
                    }
                    required
                    error={!!errors.code}
                    helperText={errors.code?.message}
                    value={watch("code") || ""}
                    onChange={({target}) => setValue("code", (target.value || "").toUpperCase())}
                    inputProps={{
                      style: {textTransform: "uppercase"}
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={fieldStyle} id="node-settings-form__title-input">
                  <I18nTextField
                    name="title"
                    label={t("scenes.nodeSettings.fields.title.label")}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    variant="outlined"
                    value={watch("title") || {}}
                    onChange={value => setValue("title", value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="node-settings-form__agency-input"
                    name="agency"
                    label={t("scenes.nodeSettings.fields.agency.label")}
                    required
                    error={!!errors.agency}
                    helperText={errors.agency?.message}
                    variant="outlined"
                    value={watch("agency") || ""}
                    onChange={({target}) => setValue("agency", target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth sx={fieldStyle}>
              <FormControlLabel
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.active.tooltip")} tooltipOnRight>
                    {t("scenes.nodeSettings.fields.active.label")}
                  </FormLabelWithTooltip>
                }
                control={
                  <Checkbox
                    id="node-settings-form__active-checkbox"
                    name="active"
                    checked={watch("active")}
                    onChange={(e, value) => setValue("active", value)}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle} id="node-settings-form__visible-select">
              <ComponentVariantLoader
                componentId="node-and-data-visibility-select"
                visible={watch("visible")}
                dataAccess={watch("dataAccess")}
                onChange={({visible, dataAccess}) => {
                  setValue("visible", visible);
                  setValue("dataAccess", dataAccess);
                  if (visible !== NodeVisibility.Yes) {
                    setValue("default", false);
                  }
                }}
              />
            </FormControl>
            {initialNodeVisibility === NodeVisibility.Profiled && watch("visible") !== NodeVisibility.Profiled && (
              <Alert severity="warning" sx={{marginTop: "8px"}}>
                {t("scenes.nodeSettings.fields.visible.warning.label")}
              </Alert>
            )}
            <FormControl fullWidth sx={fieldStyle}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.default.label")}
                control={
                  <Checkbox
                    id="node-settings-form__default-checkbox"
                    name="default"
                    checked={watch("default")}
                    onChange={(e, value) => setValue("default", value)}
                    disabled={watch("visible") !== NodeVisibility.Yes}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle} id="node-settings-form__slogan-input">
              <I18nTextField
                name="slogan"
                label={t("scenes.nodeSettings.fields.slogan.label")}
                variant="outlined"
                value={watch("slogan") || {}}
                onChange={value => setValue("slogan", value)}
              />
            </FormControl>
            <Autocomplete
              id="node-settings-form__supported-languages-select"
              multiple
              variant="outlined"
              options={hub?.supportedLanguages || []}
              value={watch("supportedLanguages") || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
              }
              onChange={(e, val) => {
                setValue("supportedLanguages", val);
                if (defaultLanguage && !val.includes(defaultLanguage)) {
                  setValue("defaultLanguage", null);
                }
              }}
              renderInput={params => (
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    {...params}
                    label={t("scenes.nodeSettings.fields.supportedLanguages.label")}
                    variant="outlined"
                  />
                </FormControl>
              )}
            />
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                id="node-settings-form__default-language-select"
                select
                required={(supportedLanguages || []).length > 0}
                label={t("scenes.nodeSettings.fields.defaultLanguage.label")}
                onChange={e => setValue("defaultLanguage", e.target.value)}
                value={watch("defaultLanguage") || ""}
                error={!!errors.defaultLanguage}
                helperText={errors.defaultLanguage?.message}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                disabled={(supportedLanguages || []).length === 0}
              >
                {supportedLanguages.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth sx={fieldStyle} id="node-settings-form__background-media-url-input">
              <FileInput
                id={`node_settings_fields_background_media_url_input`}
                label={t("scenes.nodeSettings.fields.backgroundMediaURL.label")}
                value={watch("backgroundMediaURL")}
                onChange={value => setValue("backgroundMediaURL", value)}
                enableDownload
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle} id="node-settings-form__logo-media-url-input">
              <FileInput
                label={t("scenes.nodeSettings.fields.logo.label")}
                value={watch("logo")}
                onChange={value => setValue("logo", value)}
                enableDownload
              />
            </FormControl>
          </TabPanel>
          <TabPanel value="infos" selected={tab}>
            <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
          </TabPanel>
          <TabPanel value="endpoint" selected={tab}>
            <Paper variant="outlined" sx={paperStyle}>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__node-type-select"
                  name="type"
                  select
                  label={t("scenes.nodeSettings.fields.type.label")}
                  value="SDMX-REST"
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value="SDMX-REST">SDMX-REST</MenuItem>
                </TextField>
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <ComponentVariantLoader
                  componentId="criteria-selection-mode-select"
                  value={watch("criteriaSelectionMode") || ""}
                  onChange={e => setValue("criteriaSelectionMode", e.target.value)}
                  error={!!errors.criteriaSelectionMode}
                  helperText={errors.criteriaSelectionMode?.message}
                />
              </FormControl>
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__endpoint-url-input"
                  name="endPoint"
                  label={t("scenes.nodeSettings.fields.endPoint.label")}
                  required
                  error={!!errors.endPoint}
                  helperText={errors.endPoint?.message}
                  variant="outlined"
                  value={watch("endPoint") || ""}
                  onChange={({target}) => {
                    setValue("endPoint", target.value);
                    setHasPinged(false);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__response-format-select"
                  select
                  label={t("scenes.nodeSettings.fields.responseFormat.label")}
                  value={watch("restDataResponseXml") || ""}
                  required
                  variant="outlined"
                  error={!!errors.restDataResponseXml}
                  helperText={errors.restDataResponseXml?.message}
                  onChange={({target}) => {
                    setValue("restDataResponseXml", target.value);
                    setHasPinged(false);
                  }}
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem id="node-settings-form__response-format-select__xml" value="true">
                    XML
                  </MenuItem>
                  <MenuItem id="node-settings-form__response-format-select__json" value="false">
                    JSON
                  </MenuItem>
                </TextField>
              </FormControl>
              <Grid container columnGap={2} alignItems="center" sx={fieldStyle}>
                <Grid item sx={{width: "calc(100% - 100px)"}}>
                  <FormControl fullWidth>
                    <TextField
                      id="node-settings-form__ping-artifact-select"
                      select
                      label={t("scenes.nodeSettings.fields.pingArtifact.label")}
                      value={watch("pingArtifact")}
                      variant="outlined"
                      SelectProps={{
                        SelectDisplayProps: {"aria-haspopup": true}
                      }}
                      onChange={({target}) => {
                        setValue("pingArtifact", target.value);
                        setHasPinged(false);
                      }}
                    >
                      <MenuItem id="node-settings-form__ping-artifact-select__concept-scheme" value="ConceptScheme">
                        Concept Scheme
                      </MenuItem>
                      <MenuItem id="node-settings-form__ping-artifact-select__category-scheme" value="CategoryScheme">
                        Category Scheme
                      </MenuItem>
                      <MenuItem id="node-settings-form__ping-artifact-select__codelist" value="CodeList">
                        Codelist
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    id="node-settings-form__ping-button"
                    onClick={() => {
                      pingApi.call({
                        ...pingApi.request,
                        data: {
                          type: watch("pingArtifact"),
                          url: watch("endPoint"),
                          responseFormat: watch("restDataResponseXml") === "true" ? "XML" : "JSON",
                          enableHttpAuth: watch("enableHttpAuth"),
                          authHttpUsername: watch("authHttpUsername"),
                          authHttpPassword: watch("authHttpPassword")
                        },
                        messages: t => ({
                          onStart: t("scenes.nodeSettings.tabs.endpoint.ping.onStart", {endpoint: watch("endPoint")})
                        })
                      });
                      setHasPinged(true);
                    }}
                    disabled={
                      (watch("endPoint") || "").length === 0 ||
                      (watch("enableHttpAuth") && (watch("authHttpUsername") || "").length === 0)
                    }
                    endIcon={
                      hasPinged ? (
                        pingApi.error !== null ? (
                          <ErrorOutlineIcon fontSize="medium" color="secondary" />
                        ) : (
                          <CheckCircleOutlineIcon fontSize="medium" htmlColor="green" />
                        )
                      ) : (
                        ""
                      )
                    }
                    sx={{marginTop: "8px"}}
                  >
                    PING
                  </Button>
                </Grid>
              </Grid>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.supportPostFilters.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__support-post-filters-checkbox"
                      name="supportPostFilters"
                      checked={watch("supportPostFilters") || false}
                      onChange={(e, value) => setValue("supportPostFilters", value)}
                    />
                  }
                />
              </FormControl>
              {isCriteriaSelectionModeDynamic && (
                <Paper variant="outlined" sx={paperStyle}>
                  <CardHeader
                    subheader={
                      <FormLabelWithTooltip
                        tooltip={t("scenes.nodeSettings.tabs.endpoint.cards.structureFilters.tooltip")}
                        tooltipOnRight
                      >
                        {t("scenes.nodeSettings.tabs.endpoint.cards.structureFilters.label")}
                      </FormLabelWithTooltip>
                    }
                    sx={{padding: "0px"}}
                  />
                  <FormControl component="fieldset">
                    {(() => {
                      let radioValue;
                      if (supportPostFiltersStructure && enableEndpointV20) {
                        radioValue = null;
                      } else if (supportPostFiltersStructure) {
                        radioValue = "supportPostFiltersStructure";
                      } else if (enableEndpointV20) {
                        radioValue = "enableEndpointV20";
                      } else {
                        radioValue = "GET";
                      }

                      return (
                        <RadioGroup
                          value={radioValue}
                          onChange={event => {
                            switch (event.target.value) {
                              case "supportPostFiltersStructure": {
                                setValue("supportPostFiltersStructure", true);
                                setValue("enableEndpointV20", false);
                                break;
                              }
                              case "enableEndpointV20": {
                                setValue("supportPostFiltersStructure", false);
                                setValue("enableEndpointV20", true);
                                break;
                              }
                              default: {
                                setValue("supportPostFiltersStructure", false);
                                setValue("enableEndpointV20", false);
                              }
                            }
                          }}
                        >
                          <FormControlLabel
                            id="node-settings-form__structure-filters-default"
                            value="GET"
                            control={<Radio />}
                            label={t("scenes.nodeSettings.fields.structureFilters.default.label")}
                          />
                          <FormControlLabel
                            id="node-settings-form__structure-filters-support-post-filters-structure"
                            value="supportPostFiltersStructure"
                            control={<Radio />}
                            label={t("scenes.nodeSettings.fields.structureFilters.supportPostFiltersStructure.label")}
                          />
                          <FormControlLabel
                            id="node-settings-form__structure-filters-enable-endpoint-v20"
                            value="enableEndpointV20"
                            control={<Radio />}
                            label={
                              <FormLabelWithTooltip
                                tooltip={t("scenes.nodeSettings.fields.structureFilters.enableEndpointV20.tooltip")}
                                tooltipOnRight
                              >
                                {t("scenes.nodeSettings.fields.structureFilters.enableEndpointV20.label")}
                              </FormLabelWithTooltip>
                            }
                          />
                        </RadioGroup>
                      );
                    })()}
                  </FormControl>
                  {enableEndpointV20 && (
                    <FormControl fullWidth sx={fieldStyle}>
                      <TextField
                        name="endpointV20"
                        label={t("scenes.nodeSettings.fields.endpointV20.label")}
                        required
                        error={!!errors.endpointV20}
                        helperText={errors.endpointV20?.message}
                        variant="outlined"
                        value={watch("endpointV20") || ""}
                        onChange={({target}) => setValue("endpointV20", target.value)}
                      />
                    </FormControl>
                  )}
                </Paper>
              )}
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item sm={4}>
                  <FormControl fullWidth sx={fieldStyle}>
                    <FormControlLabel
                      label={t("scenes.nodeSettings.fields.enableHttpAuth.label")}
                      control={
                        <Checkbox
                          name="enableHttpAuth"
                          checked={enableHttpAuth || false}
                          onChange={(e, value) => {
                            setValue("enableHttpAuth", value);
                            setHasPinged(false);
                          }}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                {enableHttpAuth && (
                  <Fragment>
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <TextField
                          name="authHttpUsername"
                          label={t("scenes.nodeSettings.fields.authHttpUsername.label")}
                          required
                          error={!!errors.authHttpUsername}
                          helperText={errors.authHttpUsername?.message}
                          variant="outlined"
                          value={watch("authHttpUsername") || ""}
                          onChange={({target}) => {
                            setValue("authHttpUsername", target.value);
                            setHasPinged(false);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <PasswordInput
                          disableAutocomplete
                          disableShowPassword
                          name="authHttpPassword"
                          label={t("scenes.nodeSettings.fields.authHttpPassword.label")}
                          variant="outlined"
                          value={watch("authHttpPassword") || ""}
                          onChange={({target}) => {
                            setValue("authHttpPassword", target.value);
                            setHasPinged(false);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4} />
                  </Fragment>
                )}
              </Grid>
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item sm={4}>
                  <FormControl fullWidth sx={fieldStyle}>
                    <FormControlLabel
                      label={t("scenes.nodeSettings.fields.enableProxy.label")}
                      control={
                        <Checkbox
                          name="enableProxy"
                          checked={enableProxy || false}
                          onChange={(e, value) => setValue("enableProxy", value)}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                {enableProxy && (
                  <Fragment>
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <TextField
                          name="proxyAddress"
                          label={t("scenes.nodeSettings.fields.proxyAddress.label")}
                          required
                          error={!!errors.proxyAddress}
                          helperText={errors.proxyAddress?.message}
                          variant="outlined"
                          value={watch("proxyAddress") || ""}
                          onChange={({target}) => setValue("proxyAddress", target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <TextField
                          name="proxyPort"
                          label={t("scenes.nodeSettings.fields.proxyPort.label")}
                          required
                          error={!!errors.proxyPort}
                          helperText={errors.proxyPort?.message}
                          variant="outlined"
                          value={watch("proxyPort") || ""}
                          onChange={({target}) => setValue("proxyPort", target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4} />
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <TextField
                          name="proxyUsername"
                          label={t("scenes.nodeSettings.fields.proxyUsername.label")}
                          variant="outlined"
                          value={watch("proxyUsername") || ""}
                          onChange={({target}) => setValue("proxyUsername", target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl fullWidth sx={fieldStyle}>
                        <PasswordInput
                          disableAutocomplete
                          disableShowPassword
                          name="proxyPassword"
                          label={t("scenes.nodeSettings.fields.proxyPassword.label")}
                          variant="outlined"
                          value={watch("proxyPassword") || ""}
                          onChange={e => setValue("proxyPassword", e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Paper>
          </TabPanel>
          <TabPanel value="annotations" selected={tab}>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ORDER"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.ORDER.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ORDER") || ""}
                onChange={({target}) => setValue("annotationConfig.ORDER", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ORDER_CATEGORY_SCHEMA"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY_SCHEMA.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY_SCHEMA.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ORDER_CATEGORY_SCHEMA") || ""}
                onChange={({target}) => setValue("annotationConfig.ORDER_CATEGORY_SCHEMA", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ORDER_CATEGORY"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ORDER_CATEGORY") || ""}
                onChange={({target}) => setValue("annotationConfig.ORDER_CATEGORY", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ORDER_CODELIST"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER_CODELIST.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.ORDER_CODELIST.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ORDER_CODELIST") || ""}
                onChange={({target}) => setValue("annotationConfig.ORDER_CODELIST", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.NOT_DISPLAYED"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.NOT_DISPLAYED.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.NOT_DISPLAYED.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.NOT_DISPLAYED") || ""}
                onChange={({target}) => setValue("annotationConfig.NOT_DISPLAYED", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DEFAULT"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DEFAULT.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DEFAULT.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DEFAULT") || ""}
                onChange={({target}) => setValue("annotationConfig.DEFAULT", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={[fieldStyle, subfieldStyle]}>
              <TextField
                name="annotationConfig.TIME_PERIOD_START"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_START.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_START.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.TIME_PERIOD_START") || ""}
                onChange={({target}) => setValue("annotationConfig.TIME_PERIOD_START", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={[fieldStyle, subfieldStyle]}>
              <TextField
                name="annotationConfig.TIME_PERIOD_END"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_END.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_END.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.TIME_PERIOD_END") || ""}
                onChange={({target}) => setValue("annotationConfig.TIME_PERIOD_END", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={[fieldStyle, subfieldStyle]}>
              <TextField
                name="annotationConfig.LASTNPERIOD"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LASTNPERIOD.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LASTNPERIOD.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LASTNPERIOD") || ""}
                onChange={({target}) => setValue("annotationConfig.LASTNPERIOD", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={[fieldStyle, subfieldStyle]}>
              <TextField
                name="annotationConfig.LASTNOBSERVATIONS"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LASTNOBSERVATION.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LASTNOBSERVATION.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LASTNOBSERVATIONS") || ""}
                onChange={({target}) => setValue("annotationConfig.LASTNOBSERVATIONS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_ROW"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_ROW") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_ROW", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_COLUMN"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_COLUMN.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_COLUMN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_COLUMN") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_COLUMN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_FILTER"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_FILTER.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_FILTER.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_FILTER") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_FILTER", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_ROW_SECTION"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW_SECTION.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW_SECTION.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_ROW_SECTION") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_ROW_SECTION", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_CARTESIAN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_CARTESIAN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_FILTER_CARTESIAN"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER_CARTESIAN.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER_CARTESIAN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_FILTER_CARTESIAN") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_FILTER_CARTESIAN", target.value)}
              />
            </FormControl>

            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_PRIMARY_DIM_RADIAL"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM_RADIAL.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM_RADIAL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_RADIAL") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_PRIMARY_DIM_RADIAL", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_SECONDARY_DIM_RADIAL"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM_RADIAL.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM_RADIAL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_RADIAL") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_SECONDARY_DIM_RADIAL", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_FILTER_RADIAL"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER_RADIAL.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER_RADIAL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_FILTER_RADIAL") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_FILTER_RADIAL", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_MAP_FILTER"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_MAP_FILTER.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_MAP_FILTER.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_MAP_FILTER") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_MAP_FILTER", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.CRITERIA_SELECTION"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.CRITERIA_SELECTION.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.CRITERIA_SELECTION.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.CRITERIA_SELECTION") || ""}
                onChange={({target}) => setValue("annotationConfig.CRITERIA_SELECTION", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ATTACHED_DATA_FILES"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.ATTACHED_DATA_FILES.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.ATTACHED_DATA_FILES.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ATTACHED_DATA_FILES") || ""}
                onChange={({target}) => setValue("annotationConfig.ATTACHED_DATA_FILES", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_DECIMAL_SEPARATOR"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_DECIMAL_SEPARATOR.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_DECIMAL_SEPARATOR.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_DECIMAL_SEPARATOR") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_DECIMAL_SEPARATOR", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_NUMBER_OF_DECIMALS"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_NUMBER_OF_DECIMALS.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_NUMBER_OF_DECIMALS.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_EMPTY_CELL_PLACEHOLDER.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_EMPTY_CELL_PLACEHOLDER.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DATAFLOW_NOTES"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_NOTES.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DATAFLOW_NOTES.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DATAFLOW_NOTES") || ""}
                onChange={({target}) => setValue("annotationConfig.DATAFLOW_NOTES", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DATAFLOW_SOURCE"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_SOURCE.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DATAFLOW_SOURCE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DATAFLOW_SOURCE") || ""}
                onChange={({target}) => setValue("annotationConfig.DATAFLOW_SOURCE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.METADATA_URL"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.METADATA_URL.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.METADATA_URL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.METADATA_URL") || ""}
                onChange={({target}) => setValue("annotationConfig.METADATA_URL", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.KEYWORDS"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.KEYWORDS.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.KEYWORDS.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.KEYWORDS") || ""}
                onChange={({target}) => setValue("annotationConfig.KEYWORDS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_DEFAULT_PRESENTATION"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_DEFAULT_PRESENTATION.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_DEFAULT_PRESENTATION.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_DEFAULT_PRESENTATION") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_DEFAULT_PRESENTATION", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.GEO_ID"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.GEO_ID.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.GEO_ID.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.GEO_ID") || ""}
                onChange={({target}) => setValue("annotationConfig.GEO_ID", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAST_UPDATE"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAST_UPDATE.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.LAST_UPDATE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAST_UPDATE") || ""}
                onChange={({target}) => setValue("annotationConfig.LAST_UPDATE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.VIRTUAL_DATAFLOW_NODE"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.VIRTUAL_DATAFLOW_NODE.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.VIRTUAL_DATAFLOW_NODE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.VIRTUAL_DATAFLOW_NODE") || ""}
                onChange={({target}) => setValue("annotationConfig.VIRTUAL_DATAFLOW_NODE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DATAFLOW_CATALOG_TYPE"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_CATALOG_TYPE.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.DATAFLOW_CATALOG_TYPE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DATAFLOW_CATALOG_TYPE") || ""}
                onChange={({target}) => setValue("annotationConfig.DATAFLOW_CATALOG_TYPE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DATAFLOW_HIDDEN"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_HIDDEN.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DATAFLOW_HIDDEN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DATAFLOW_HIDDEN") || ""}
                onChange={({target}) => setValue("annotationConfig.DATAFLOW_HIDDEN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DISABLED_VIEWERS"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DISABLED_VIEWERS.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DISABLED_VIEWERS.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DISABLED_VIEWERS") || ""}
                onChange={({target}) => setValue("annotationConfig.DISABLED_VIEWERS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.TABLE_LOCKED_DIMS"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TABLE_LOCKED_DIMS.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.TABLE_LOCKED_DIMS.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.TABLE_LOCKED_DIMS") || ""}
                onChange={({target}) => setValue("annotationConfig.TABLE_LOCKED_DIMS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.CHART_LOCKED_DIMS_CARTESIAN"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.CHART_LOCKED_DIMS_CARTESIAN.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.CHART_LOCKED_DIMS_CARTESIAN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.CHART_LOCKED_DIMS_CARTESIAN") || ""}
                onChange={({target}) => setValue("annotationConfig.CHART_LOCKED_DIMS_CARTESIAN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.CHART_LOCKED_DIMS_RADIAL"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.CHART_LOCKED_DIMS_RADIAL.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.CHART_LOCKED_DIMS_RADIAL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.CHART_LOCKED_DIMS_RADIAL") || ""}
                onChange={({target}) => setValue("annotationConfig.CHART_LOCKED_DIMS_RADIAL", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.TEMPORAL_DIM_ORDER"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.TEMPORAL_DIM_ORDER.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.TEMPORAL_DIM_ORDER.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.TEMPORAL_DIM_ORDER") || ""}
                onChange={({target}) => setValue("annotationConfig.TEMPORAL_DIM_ORDER", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.ANTICIP_EXCEEDING_MAX_NUM_REC.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.ANTICIP_EXCEEDING_MAX_NUM_REC.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC") || ""}
                onChange={({target}) => setValue("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.FLAT_TABLE_AVAILABLE"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.FLAT_TABLE_AVAILABLE.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.FLAT_TABLE_AVAILABLE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.FLAT_TABLE_AVAILABLE") || ""}
                onChange={({target}) => setValue("annotationConfig.FLAT_TABLE_AVAILABLE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DDB_CONTENT_CONSTRAINT"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.DDB_CONTENT_CONSTRAINT.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.DDB_CONTENT_CONSTRAINT.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DDB_CONTENT_CONSTRAINT") || ""}
                onChange={({target}) => setValue("annotationConfig.DDB_CONTENT_CONSTRAINT", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_AGGREGATE_CELLS"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_AGGREGATE_CELLS.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_AGGREGATE_CELLS.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_AGGREGATE_CELLS") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_AGGREGATE_CELLS", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.HCL_REFERENCE"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.HCL_REFERENCE.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.HCL_REFERENCE.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.HCL_REFERENCE") || ""}
                onChange={({target}) => setValue("annotationConfig.HCL_REFERENCE", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.DATA_LINK"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATA_LINK.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.DATA_LINK.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.DATA_LINK") || ""}
                onChange={({target}) => setValue("annotationConfig.DATA_LINK", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ROUNDING_STRATEGY"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ROUNDING_STRATEGY.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.ROUNDING_STRATEGY.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ROUNDING_STRATEGY") || ""}
                onChange={({target}) => setValue("annotationConfig.ROUNDING_STRATEGY", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.ATTRIBUTE_AS_DIM"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ATTRIBUTE_AS_DIM.tooltip")}>
                    {t("scenes.nodeSettings.fields.annotations.ATTRIBUTE_AS_DIM.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.ATTRIBUTE_AS_DIM") || ""}
                onChange={({target}) => setValue("annotationConfig.ATTRIBUTE_AS_DIM", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_OBS_ORDER_CARTESIAN"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_OBS_ORDER_CARTESIAN.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_OBS_ORDER_CARTESIAN.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_OBS_ORDER_CARTESIAN") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_OBS_ORDER_CARTESIAN", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="annotationConfig.LAYOUT_CHART_OBS_ORDER_RADIAL"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_OBS_ORDER_RADIAL.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_OBS_ORDER_RADIAL.label")}
                  </FormLabelWithTooltip>
                }
                variant="outlined"
                value={watch("annotationConfig.LAYOUT_CHART_OBS_ORDER_RADIAL") || ""}
                onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_OBS_ORDER_RADIAL", target.value)}
              />
            </FormControl>
            <Button sx={fieldStyle} onClick={() => setRestoreAnnotationVisibility(true)} endIcon={<RestoreIcon />}>
              {t("scenes.nodeSettings.fields.annotations.restoreDefaults.button")}
            </Button>
            <Dialog
              open={restoreAnnotationsVisibility}
              maxWidth="xs"
              onClose={() => setRestoreAnnotationVisibility(false)}
            >
              <CustomDialogTitle onClose={() => setRestoreAnnotationVisibility(false)}>
                {t("scenes.nodeSettings.fields.annotations.restoreDefaults.title")}
              </CustomDialogTitle>
              <DialogContent>{t("scenes.nodeSettings.fields.annotations.restoreDefaults.content")}</DialogContent>
              <DialogActions>
                <Button onClick={() => setRestoreAnnotationVisibility(false)} autoFocus>
                  {t("commons.confirm.cancel")}
                </Button>
                <Button
                  onClick={() => {
                    setValue("annotationConfig", defaultAnnotations);
                    setRestoreAnnotationVisibility(false);
                  }}
                >
                  {t("commons.confirm.confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </TabPanel>
          <TabPanel value="cache" selected={tab}>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="ttlCatalog"
                variant="outlined"
                type="number"
                helperText={errors.ttlCatalog?.message}
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.catalogCacheValidity.tooltip")}>
                    {t("scenes.nodeSettings.fields.catalogCacheValidity.label")}
                  </FormLabelWithTooltip>
                }
                error={!!errors.ttlCatalog}
                value={watch("ttlCatalog") !== null && watch("ttlCatalog") !== undefined ? watch("ttlCatalog") : ""}
                onChange={({target}) => setValue("ttlCatalog", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                name="ttlDataflow"
                variant="outlined"
                type="number"
                helperText={errors.ttlDataflow?.message}
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.dataflowCacheValidity.tooltip")}>
                    {t("scenes.nodeSettings.fields.dataflowCacheValidity.label")}
                  </FormLabelWithTooltip>
                }
                error={!!errors.ttlDataflow}
                value={watch("ttlDataflow") !== null && watch("ttlDataflow") !== undefined ? watch("ttlDataflow") : ""}
                onChange={({target}) => setValue("ttlDataflow", target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <FormControlLabel
                label={
                  <FormLabelWithTooltip
                    tooltipOnRight
                    tooltip={t("scenes.nodeSettings.fields.enableCriteriaCache.tooltip")}
                  >
                    {t("scenes.nodeSettings.fields.enableCriteriaCache.label")}
                  </FormLabelWithTooltip>
                }
                control={
                  <Checkbox
                    name="enableCriteriaCache"
                    required
                    checked={watch("enableCriteriaCache") || false}
                    onChange={(e, value) => setValue("enableCriteriaCache", value)}
                  />
                }
              />
            </FormControl>
          </TabPanel>
          <TabPanel value="view" selected={tab}>
            <Paper variant="outlined" sx={paperStyle}>
              <CardHeader subheader={t("scenes.nodeSettings.tabs.view.cards.catalog.title")} sx={{padding: "0px"}} />
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.showDataflowUncategorized.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__show-dataflow-uncategorized-checkbox"
                      name="showDataflowUncategorized"
                      required
                      checked={watch("showDataflowUncategorized") || false}
                      onChange={(e, value) => setValue("showDataflowUncategorized", value)}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.showDataflowNotInProduction.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__show-dataflow-not-in-production-checkbox"
                      name="showDataflowNotInProduction"
                      required
                      checked={watch("showDataflowNotInProduction") || false}
                      onChange={(e, value) => setValue("showDataflowNotInProduction", value)}
                    />
                  }
                />
              </FormControl>

              <Paper variant="outlined" sx={paperStyle}>
                <CardHeader
                  subheader={t("scenes.nodeSettings.tabs.view.cards.linkedDataflow.title")}
                  sx={{padding: "0px"}}
                />
                <FormControl fullWidth sx={fieldStyle}>
                  <FormControlLabel
                    label={t("scenes.nodeSettings.fields.showLinkedDataflow.label")}
                    control={
                      <Checkbox
                        id="node-settings-form__show-linked-dataflow-checkbox"
                        name="showVirtualDataflow"
                        required
                        checked={watch("showVirtualDataflow") || false}
                        onChange={(e, value) => setValue("showVirtualDataflow", value)}
                      />
                    }
                  />
                </FormControl>

                {showVirtualDataflow && (
                  <FormControl fullWidth sx={fieldStyle}>
                    <FormControlLabel
                      label={t("scenes.nodeSettings.fields.forceLinkedDataflowAnnotations.label")}
                      control={
                        <Checkbox
                          id="node-settings-form__force-linked-dataflow-annotations-checkbox"
                          name="forceLinkedDataflowAnnotations"
                          required
                          checked={watch("forceLinkedDataflowAnnotations") || false}
                          onChange={(e, value) => setValue("forceLinkedDataflowAnnotations", value)}
                        />
                      }
                    />
                  </FormControl>
                )}
              </Paper>

              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.showOnlyFileDataflow.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__show-only-file-dataflow-checkbox"
                      name="showOnlyFileDataflow"
                      required
                      checked={watch("showOnlyFileDataflow") || false}
                      onChange={(e, value) => setValue("showOnlyFileDataflow", value)}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__show-category-levels-select"
                  name="showCategoryLevels"
                  select
                  required
                  label={t("scenes.nodeSettings.fields.showCategoryLevels.label")}
                  onChange={e => setValue("showCategoryLevels", e.target.value)}
                  value={watch("showCategoryLevels") || ""}
                  error={!!errors.showCategoryLevels}
                  helperText={errors.showCategoryLevels?.message}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </TextField>
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.hideLabelOnCategoryWithImage.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__hide-label-on-category-with-image-checkbox"
                      name="hideLabelOnCategoryWithImage"
                      required
                      checked={watch("hideLabelOnCategoryWithImage") || false}
                      onChange={(e, value) => setValue("hideLabelOnCategoryWithImage", value)}
                    />
                  }
                />
              </FormControl>
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={watch("categorySchemaExcludes") || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                onChange={(e, val) => setValue("categorySchemaExcludes", val)}
                renderInput={params => (
                  <FormControl id="node-settings-form__category-schema-excludes" fullWidth sx={fieldStyle}>
                    <TextField
                      label={t("scenes.nodeSettings.fields.categorySchemaExcludes.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.nodeSettings.fields.categorySchemaExcludes.placeholder")}
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__catalog-navigation-mode-select"
                  select
                  label={t("scenes.nodeSettings.fields.catalogNavigationMode.label")}
                  onChange={e => setValue("catalogNavigationMode", e.target.value)}
                  value={watch("catalogNavigationMode") || ""}
                  required
                  variant="outlined"
                  error={!!errors.catalogNavigationMode}
                  helperText={errors.catalogNavigationMode?.message}
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={CATALOG_NAVIGATION_MODE_TREE}>
                    {t("scenes.nodeSettings.fields.catalogNavigationMode.values.tree")}
                  </MenuItem>
                  <MenuItem value={CATALOG_NAVIGATION_MODE_GRID}>
                    {t("scenes.nodeSettings.fields.catalogNavigationMode.values.grid")}
                  </MenuItem>
                  <MenuItem value={CATALOG_NAVIGATION_MODE_LIST}>
                    {t("scenes.nodeSettings.fields.catalogNavigationMode.values.list")}
                  </MenuItem>
                </TextField>
              </FormControl>
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <CardHeader subheader={t("scenes.nodeSettings.tabs.view.cards.dataflow.title")} sx={{padding: "0px"}} />
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  select
                  id="node-settings-form__decimal-number-input"
                  name="decimalNumber"
                  label={t("scenes.nodeSettings.fields.decimalNumber.label")}
                  variant="outlined"
                  required
                  value={watch("decimalNumber")}
                  onChange={({target}) => setValue("decimalNumber", target.value)}
                >
                  <MenuItem value={-2}>{t("scenes.nodeSettings.fields.decimalNumber.values.notFormatted")}</MenuItem>
                  <MenuItem value={0}>{t("scenes.nodeSettings.fields.decimalNumber.values.asInteger")}</MenuItem>
                  {[...Array(20)].map((_, idx) => (
                    <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__rounding-strategy-select"
                  name="roundingStrategy"
                  select
                  label={t("scenes.nodeSettings.fields.roundingStrategy.label")}
                  variant="outlined"
                  required
                  value={watch("roundingStrategy") || ""}
                  onChange={e => setValue("roundingStrategy", e.target.value)}
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value="NormalRounding">
                    {t("scenes.nodeSettings.fields.roundingStrategy.values.round")}
                  </MenuItem>
                  <MenuItem value="RoundingUp">{t("scenes.nodeSettings.fields.roundingStrategy.values.ceil")}</MenuItem>
                  <MenuItem value="Truncation">
                    {t("scenes.nodeSettings.fields.roundingStrategy.values.floor")}
                  </MenuItem>
                </TextField>
              </FormControl>
              <FormControl id="node-settings-form__decimal-separator-select" fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="decimalSeparator"
                  select
                  required
                  label={t("scenes.nodeSettings.fields.decimalSeparator.label")}
                  onChange={value => setValue("decimalSeparator", value)}
                  value={watch("decimalSeparator") || ""}
                  helper={t("scenes.nodeSettings.fields.decimalSeparator.helper")}
                  error={!!errors.decimalSeparator}
                  helperText={errors.decimalSeparator?.message}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value=".">Dot</MenuItem>
                  <MenuItem value=",">Comma</MenuItem>
                </I18nTextField>
              </FormControl>
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={watch("labelDimensionTerritorials") || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                onChange={(e, val) => setValue("labelDimensionTerritorials", val)}
                renderInput={params => (
                  <FormControl id="node-settings-form__label-dimension-territorials" fullWidth sx={fieldStyle}>
                    <TextField
                      label={t("scenes.nodeSettings.fields.labelDimensionTerritorials.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.nodeSettings.fields.labelDimensionTerritorials.placeholder")}
                    />
                  </FormControl>
                )}
              />
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={watch("hiddenAttributes") || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                onChange={(e, val) => setValue("hiddenAttributes", val)}
                renderInput={params => (
                  <FormControl id="node-settings-form__hidden-attributes" fullWidth sx={fieldStyle}>
                    <TextField
                      label={t("scenes.nodeSettings.fields.hiddenAttributes.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.nodeSettings.fields.hiddenAttributes.placeholder")}
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  id="node-settings-form__default-attribute-sort-select"
                  select
                  label={t("scenes.nodeSettings.fields.temporalDimOrder.label")}
                  value={watch("temporalDimOrder")}
                  variant="outlined"
                  onChange={ev => setValue("temporalDimOrder", ev.target.value)}
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}>
                    {t("scenes.nodeSettings.fields.temporalDimOrder.values.unset")}
                  </MenuItem>
                  <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
                    {t("scenes.nodeSettings.fields.temporalDimOrder.values.asc")}
                  </MenuItem>
                  <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
                    {t("scenes.nodeSettings.fields.temporalDimOrder.values.desc")}
                  </MenuItem>
                </TextField>
              </FormControl>
              <Paper variant="outlined" sx={paperStyle}>
                <CardHeader subheader={t("scenes.nodeSettings.fields.queryInfo.title")} sx={{padding: "0px"}} />
                <FormControl fullWidth sx={fieldStyle}>
                  <FormControlLabel
                    label={t("scenes.nodeSettings.fields.queryInfo.label")}
                    control={
                      <Checkbox
                        id="node-settings-form__query-info-checkbox"
                        name="queryInfo"
                        required
                        checked={watch("queryInfo") || false}
                        onChange={(e, value) => setValue("queryInfo", value)}
                      />
                    }
                  />
                </FormControl>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="node-settings-form__query-info-base-url"
                    label={
                      <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.queryInfoBaseUrl.tooltip")}>
                        {t("scenes.nodeSettings.fields.queryInfoBaseUrl.label")}
                      </FormLabelWithTooltip>
                    }
                    value={watch("queryInfoBaseUrl") || ""}
                    onChange={({target}) => setValue("queryInfoBaseUrl", target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Paper>
              <Paper variant="outlined" sx={paperStyle}>
                <CardHeader
                  subheader={t("scenes.nodeSettings.tabs.view.cards.dataflow.cards.timePeriod.title")}
                  sx={{padding: "0px"}}
                />
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="node-settings-form__default-last-n-periods"
                    label={
                      <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.defaultLastNPeriods.tooltip")}>
                        {t("scenes.nodeSettings.fields.defaultLastNPeriods.label")}
                      </FormLabelWithTooltip>
                    }
                    onChange={e => {
                      if (e.target.value.match(/^[0-9]*$/gm)) {
                        setValue("defaultLastNPeriods", e.target.value);
                      }
                    }}
                    value={watch("defaultLastNPeriods") || ""}
                    variant="outlined"
                    error={!!errors.defaultLastNPeriods}
                    helperText={errors.defaultLastNPeriods?.message}
                    disabled={isLastNPeriodsDisabled}
                  />
                </FormControl>
                <Grid container spacing={2} sx={{...fieldStyle, marginLeft: "-16px"}}>
                  <Grid item xs={12}>
                    {t("scenes.nodeSettings.fields.timePeriodRange.label")}:
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl id="node-settings-form__time-period-range-start" fullWidth>
                      <CustomDatePicker
                        label={t("scenes.nodeSettings.fields.timePeriodRangeStart.label")}
                        inputVariant="outlined"
                        value={watch("timePeriodRangeStart") || null}
                        onChange={date =>
                          setValue("timePeriodRangeStart", date ? moment(date).format("YYYY-MM-DD") : null)
                        }
                        error={!!errors.timePeriodRangeStart}
                        helperText={errors.timePeriodRangeStart?.message}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl id="node-settings-form__time-period-range-end" fullWidth>
                      <CustomDatePicker
                        label={t("scenes.nodeSettings.fields.timePeriodRangeEnd.label")}
                        inputVariant="outlined"
                        value={watch("timePeriodRangeEnd") || null}
                        onChange={date =>
                          setValue("timePeriodRangeEnd", date ? moment(date).format("YYYY-MM-DD") : null)
                        }
                        error={!!errors.timePeriodRangeEnd}
                        helperText={errors.timePeriodRangeEnd?.message}
                      />
                    </FormControl>
                  </Grid>
                  {timePeriod && (
                    <Grid container spacing={2} sx={fieldStyle}>
                      <Grid item xs={12}>
                        {t("scenes.nodeSettings.fields.timePeriodFormat.label")}:
                      </Grid>
                      {timePeriod.formatYear && (
                        <Grid item xs={6}>
                          <FormControl fullWidth sx={fieldStyle} id="node-settings-form__time-period-format-year">
                            <I18nTextField
                              name="timePeriodFormatYear"
                              select
                              required
                              label={t("scenes.nodeSettings.fields.timePeriodFormatYear.label")}
                              onChange={value => setValue("timePeriodFormatYear", value)}
                              value={watch("timePeriodFormatYear") || ""}
                              //helper={t("scenes.nodeSettings.fields.decimalSeparator.helper")}
                              error={!!errors.timePeriodFormatYear}
                              helperText={errors.timePeriodFormatYear?.message}
                              variant="outlined"
                              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                            >
                              {timePeriod.formatYear.map(tfy => (
                                <MenuItem value={tfy}>{dateForm.format(tfy)}</MenuItem>
                              ))}
                            </I18nTextField>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={fieldStyle} id="node-settings-form__time-period-format-month">
                          <I18nTextField
                            name="timePeriodFormatMonth"
                            select
                            required
                            label={t("scenes.nodeSettings.fields.timePeriodFormatMonth.label")}
                            onChange={value => setValue("timePeriodFormatMonth", value)}
                            value={watch("timePeriodFormatMonth") || ""}
                            //helper={t("scenes.nodeSettings.fields.timePeriodFormatMonth.helper")}
                            error={!!errors.timePeriodFormatMonth}
                            helperText={errors.timePeriodFormatMonth?.message}
                            variant="outlined"
                            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                          >
                            {timePeriod.formatMonth.map(tfm => (
                              <MenuItem value={tfm}>{dateForm.format(tfm)}</MenuItem>
                            ))}
                          </I18nTextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={fieldStyle} id="node-settings-form__time-period-format-day">
                          <I18nTextField
                            name="timePeriodFormatDay"
                            select
                            required
                            label={t("scenes.nodeSettings.fields.timePeriodFormatDay.label")}
                            onChange={value => setValue("timePeriodFormatDay", value)}
                            value={watch("timePeriodFormatDay") || ""}
                            //helper={t("scenes.nodeSettings.fields.timePeriodFormatDay.helper")}
                            error={!!errors.timePeriodFormatDay}
                            helperText={errors.timePeriodFormatDay?.message}
                            variant="outlined"
                            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                          >
                            {timePeriod.formatDay.map(tfd => (
                              <MenuItem value={tfd}>{dateForm.format(tfd)}</MenuItem>
                            ))}
                          </I18nTextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={fieldStyle} id="node-settings-form__time-period-format-quarter">
                          <I18nTextField
                            name="timePeriodFormatQuarter"
                            select
                            required
                            label={t("scenes.nodeSettings.fields.timePeriodFormatQuarter.label")}
                            onChange={value => setValue("timePeriodFormatQuarter", value)}
                            value={watch("timePeriodFormatQuarter") || ""}
                            //helper={t("scenes.nodeSettings.fields.timePeriodFormatQuarter.helper")}
                            error={!!errors.timePeriodFormatQuarter}
                            helperText={errors.timePeriodFormatQuarter?.message}
                            variant="outlined"
                            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                          >
                            {timePeriod.formatQuarter.map(tfq => (
                              <MenuItem value={tfq}>{dateForm.customFormat(tfq)}</MenuItem>
                            ))}
                          </I18nTextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={fieldStyle} id="node-settings-form__time-period-format-semester">
                          <I18nTextField
                            name="timePeriodFormatSemester"
                            select
                            required
                            label={t("scenes.nodeSettings.fields.timePeriodFormatSemester.label")}
                            onChange={value => setValue("timePeriodFormatSemester", value)}
                            value={watch("timePeriodFormatSemester") || ""}
                            //helper={t("scenes.nodeSettings.fields.timePeriodFormatSemester.helper")}
                            error={!!errors.timePeriodFormatSemester}
                            helperText={errors.timePeriodFormatSemester?.message}
                            variant="outlined"
                            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                          >
                            {timePeriod.formatSemester.map(tfs => (
                              <MenuItem value={tfs}>{dateForm.customFormat(tfs)}</MenuItem>
                            ))}
                          </I18nTextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <Paper variant="outlined" sx={paperStyle}>
                <CardHeader subheader={t("scenes.nodeSettings.fields.hierarchyOnly.title")} sx={{padding: "0px"}} />
                <Autocomplete
                  multiple
                  variant="outlined"
                  freeSolo
                  options={[]}
                  value={watch("hierarchyOnlyAttributes") || []}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                  }
                  onChange={(e, val) => setValue("hierarchyOnlyAttributes", val)}
                  renderInput={params => (
                    <FormControl id="node-settings-form__hierarchy-only-attributes" fullWidth sx={fieldStyle}>
                      <TextField
                        {...params}
                        label={
                          <FormLabelWithTooltip
                            tooltip={t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.tooltip")}
                          >
                            {t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.label")}
                          </FormLabelWithTooltip>
                        }
                        placeholder={t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.placeholder")}
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
                <FormControl fullWidth sx={fieldStyle}>
                  <FormControlLabel
                    label={t("scenes.nodeSettings.fields.hideHierarchyOnlyRows.label")}
                    control={
                      <Checkbox
                        id="node-settings-form__hide-hierarchy-only-rows-checkbox"
                        name="hideHierarchyOnlyRows"
                        required
                        checked={watch("hideHierarchyOnlyRows") || false}
                        onChange={(e, value) => setValue("hideHierarchyOnlyRows", value)}
                      />
                    }
                  />
                </FormControl>
              </Paper>
              <Paper variant="outlined" sx={paperStyle}>
                <CardHeader
                  subheader={t("scenes.nodeSettings.tabs.view.cards.dataflow.cards.chartsSettings.title")}
                  sx={{padding: "0px"}}
                />
                <Grid item xs={12} sx={fieldStyle}>
                  {t("scenes.nodeSettings.fields.chartsSettings.label")}:
                </Grid>
                <FormControl fullWidth sx={fieldStyle}>
                  <RadioGroup
                    id="node-settings-form__types-of-chart-radio-group"
                    value={
                      watch("typesOfChartShowsAll")
                        ? "typesOfChartShowsAll"
                        : watch("typesOfChartShowsOnly")
                          ? "typesOfChartShowsOnly"
                          : "typesOfChartShowsNone"
                    }
                    onChange={event => {
                      switch (event.target.value) {
                        case "typesOfChartShowsAll": {
                          setValue("typesOfChartShowsOnly", false);
                          setValue("typesOfChartShowsAll", true);
                          setValue("typesOfChartShowsNone", false);
                          setValue("typesOfChart", []);
                          clearErrors("typesOfChart");
                          break;
                        }
                        case "typesOfChartShowsOnly": {
                          setValue("typesOfChartShowsAll", false);
                          setValue("typesOfChartShowsOnly", true);
                          setValue("typesOfChartShowsNone", false);

                          break;
                        }
                        case "typesOfChartShowsNone": {
                          setValue("typesOfChartShowsAll", false);
                          setValue("typesOfChartShowsOnly", false);
                          setValue("typesOfChartShowsNone", true);
                          setValue("typesOfChart", []);
                          clearErrors("typesOfChart");
                          break;
                        }
                        default: {
                          setValue("typesOfChartShowsAll", false);
                          setValue("typesOfChartShowsOnly", false);
                          setValue("typesOfChartShowsNone", false);
                        }
                      }
                    }}
                  >
                    <FormControlLabel
                      value="typesOfChartShowsAll"
                      control={<Radio />}
                      label={t("scenes.nodeSettings.fields.typesOfChart.checkBoxes.all")}
                    />

                    <Box display={"flex"}>
                      <FormControlLabel
                        value="typesOfChartShowsOnly"
                        sx={{flexGrow: 1}}
                        control={<Radio />}
                        label={t("scenes.nodeSettings.fields.typesOfChart.checkBoxes.showsOnly")}
                      />
                      <Autocomplete
                        name="typesOfChart"
                        style={{width: "90%"}}
                        multiple
                        disabled={!!watch("typesOfChartShowsNone") || !!watch("typesOfChartShowsAll")}
                        variant="outlined"
                        options={viewers.slice(2).map(({icon, ...rest}) => rest)}
                        getOptionLabel={option => option.title}
                        getOptionSelected={(option, value) => option.title === value.title}
                        value={watch("typesOfChart") || []}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option.title} {...getTagProps({index})} />
                          ))
                        }
                        onChange={(e, val) => setValue("typesOfChart", val)}
                        renderInput={params => (
                          <FormControl id="node-settings-form__types-of-chart" fullWidth>
                            <TextField
                              error={!!errors.typesOfChart}
                              helperText={errors?.typesOfChart?.message}
                              label={t("scenes.nodeSettings.fields.typesOfChart.select.label")}
                              {...params}
                              variant="outlined"
                              placeholder={t("scenes.nodeSettings.fields.typesOfChart.select.placeholder")}
                            />
                          </FormControl>
                        )}
                      />
                    </Box>
                    <FormControlLabel
                      value="typesOfChartShowsNone"
                      control={<Radio />}
                      label={t("scenes.nodeSettings.fields.typesOfChart.checkBoxes.none")}
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={
                    <FormLabelWithTooltip
                      tooltip={t("scenes.nodeSettings.fields.anticipationExceedingMaxNumRecords.tooltip")}
                      tooltipOnRight
                    >
                      {t("scenes.nodeSettings.fields.anticipationExceedingMaxNumRecords.label")}
                    </FormLabelWithTooltip>
                  }
                  control={
                    <Checkbox
                      id="node-settings-form__anticipation-exceeding-max-num-records"
                      name="anticipationExceedingMaxNumRecords"
                      required
                      checked={watch("anticipationExceedingMaxNumRecords") || false}
                      onChange={(e, value) => setValue("anticipationExceedingMaxNumRecords", value)}
                    />
                  }
                />
              </FormControl>
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <CardHeader subheader={t("scenes.nodeSettings.tabs.view.cards.download.title")} sx={{padding: "0px"}} />
              <Autocomplete
                multiple
                variant="outlined"
                options={Object.keys(downloadFormats(t))}
                getOptionLabel={option => downloadFormats(t)[option]?.label || ""}
                value={watch("downloadFormats") || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={downloadFormats(t)[option]?.label || ""}
                      {...getTagProps({index})}
                    />
                  ))
                }
                onChange={(e, val) => setValue("downloadFormats", val)}
                renderInput={params => (
                  <FormControl id="node-settings-form__download-formats" fullWidth sx={fieldStyle}>
                    <TextField
                      label={t("scenes.nodeSettings.fields.downloadFormats.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.nodeSettings.fields.downloadFormats.placeholder")}
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.generateSourceDownloadsFromCache.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__generate-source-dataflow-from-cache-checkbox"
                      name="generateSourceDownloadsFromCache"
                      required
                      checked={watch("generateSourceDownloadsFromCache") || false}
                      onChange={(e, value) => setValue("generateSourceDownloadsFromCache", value)}
                    />
                  }
                />
              </FormControl>
            </Paper>
            <Paper variant="outlined" sx={paperStyle}>
              <CardHeader subheader={t("scenes.nodeSettings.tabs.view.cards.dashboard.title")} sx={{padding: "0px"}} />
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.showHubDashboards.label")}
                  control={
                    <Checkbox
                      id="node-settings-form__show-hub-dashboards-checkbox"
                      name="showHubDashboards"
                      required
                      checked={watch("showHubDashboards") || false}
                      onChange={(e, value) => setValue("showHubDashboards", value)}
                    />
                  }
                />
              </FormControl>
            </Paper>
          </TabPanel>
          <TabPanel value="colors" selected={tab}>
            <FormProvider {...nodeSettingsForm}>
              <CustomColorsForm fieldStyle={fieldStyle} />
            </FormProvider>
          </TabPanel>
          <TabPanel value="extra" selected={tab}></TabPanel>
        </Box>
      </Box>
    );
  }
);

const mapStateToProps = state => ({
  config: state.nodesConfig.node,
  initialNodeVisibility: state.nodesConfig.initialNodeVisibility,
  languages: state.app.languages,
  hub: state.hub?.hub,
  defaultCriteriaSelectionMode: state.app.themeConfig?.defaultCriteriaSelectionMode || undefined,
  timePeriod: state.appConfig.timePeriod
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: nodeId => dispatch(fetchNodesConfigNode(nodeId)),
  sendConfigCreate: config => dispatch(sendNodesConfigNodeCreate(config)),
  sendConfig: config => dispatch(sendNodesConfigNodeEdit(config)),
  clearConfig: () => dispatch(clearNodesConfigNode())
});

const NodeSettingsForm = (
  {
    config,
    initialNodeVisibility,
    languages,
    hub,
    defaultCriteriaSelectionMode,
    nodeId,
    fetchConfig,
    sendConfigCreate,
    sendConfig,
    clearConfig,
    timePeriod
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(nodeId !== null);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig(nodeId);
    }
  }, [config, needConfig, setNeedConfig, fetchConfig, nodeId]);

  return (
    (nodeId === null || config) && (
      <Form
        config={nodeId === null ? undefined : config}
        initialNodeVisibility={initialNodeVisibility}
        languages={languages}
        ref={ref}
        onSubmit={nodeId === null ? sendConfigCreate : sendConfig}
        onCancel={clearConfig}
        hub={hub}
        defaultCriteriaSelectionMode={defaultCriteriaSelectionMode}
        timePeriod={timePeriod}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(NodeSettingsForm);
