export const getLanguageTranslations = (lang, t) => {
  const translations = t => ({
    aa: t("commons.languages.aa"),
    ab: t("commons.languages.ab"),
    ae: t("commons.languages.ae"),
    af: t("commons.languages.af"),
    ak: t("commons.languages.ak"),
    am: t("commons.languages.am"),
    an: t("commons.languages.an"),
    ar: t("commons.languages.ar"),
    as: t("commons.languages.as"),
    av: t("commons.languages.av"),
    ay: t("commons.languages.ay"),
    az: t("commons.languages.az"),
    ba: t("commons.languages.ba"),
    be: t("commons.languages.be"),
    bg: t("commons.languages.bg"),
    bi: t("commons.languages.bi"),
    bm: t("commons.languages.bm"),
    bn: t("commons.languages.bn"),
    bo: t("commons.languages.bo"),
    br: t("commons.languages.br"),
    bs: t("commons.languages.bs"),
    ca: t("commons.languages.ca"),
    ce: t("commons.languages.ce"),
    ch: t("commons.languages.ch"),
    co: t("commons.languages.co"),
    cr: t("commons.languages.cr"),
    cs: t("commons.languages.cs"),
    cu: t("commons.languages.cu"),
    cv: t("commons.languages.cv"),
    cy: t("commons.languages.cy"),
    da: t("commons.languages.da"),
    de: t("commons.languages.de"),
    dv: t("commons.languages.dv"),
    dz: t("commons.languages.dz"),
    ee: t("commons.languages.ee"),
    el: t("commons.languages.el"),
    en: t("commons.languages.en"),
    eo: t("commons.languages.eo"),
    es: t("commons.languages.es"),
    et: t("commons.languages.et"),
    eu: t("commons.languages.eu"),
    fa: t("commons.languages.fa"),
    ff: t("commons.languages.ff"),
    fi: t("commons.languages.fi"),
    fj: t("commons.languages.fj"),
    fo: t("commons.languages.fo"),
    fr: t("commons.languages.fr"),
    fy: t("commons.languages.fy"),
    ga: t("commons.languages.ga"),
    gd: t("commons.languages.gd"),
    gl: t("commons.languages.gl"),
    gn: t("commons.languages.gn"),
    gu: t("commons.languages.gu"),
    gv: t("commons.languages.gv"),
    ha: t("commons.languages.ha"),
    he: t("commons.languages.he"),
    hi: t("commons.languages.hi"),
    ho: t("commons.languages.ho"),
    hr: t("commons.languages.hr"),
    ht: t("commons.languages.ht"),
    hu: t("commons.languages.hu"),
    hy: t("commons.languages.hy"),
    hz: t("commons.languages.hz"),
    ia: t("commons.languages.ia"),
    id: t("commons.languages.id"),
    ie: t("commons.languages.ie"),
    ig: t("commons.languages.ig"),
    ii: t("commons.languages.ii"),
    ik: t("commons.languages.ik"),
    io: t("commons.languages.io"),
    is: t("commons.languages.is"),
    it: t("commons.languages.it"),
    iu: t("commons.languages.iu"),
    ja: t("commons.languages.ja"),
    jv: t("commons.languages.jv"),
    ka: t("commons.languages.ka"),
    kg: t("commons.languages.kg"),
    ki: t("commons.languages.ki"),
    kj: t("commons.languages.kj"),
    kk: t("commons.languages.kk"),
    kl: t("commons.languages.kl"),
    km: t("commons.languages.km"),
    kn: t("commons.languages.kn"),
    ko: t("commons.languages.ko"),
    kr: t("commons.languages.kr"),
    ks: t("commons.languages.ks"),
    ku: t("commons.languages.ku"),
    kv: t("commons.languages.kv"),
    kw: t("commons.languages.kw"),
    ky: t("commons.languages.ky"),
    la: t("commons.languages.la"),
    lb: t("commons.languages.lb"),
    lg: t("commons.languages.lg"),
    li: t("commons.languages.li"),
    ln: t("commons.languages.ln"),
    lo: t("commons.languages.lo"),
    lt: t("commons.languages.lt"),
    lu: t("commons.languages.lu"),
    lv: t("commons.languages.lv"),
    mg: t("commons.languages.mg"),
    mh: t("commons.languages.mh"),
    mi: t("commons.languages.mi"),
    mk: t("commons.languages.mk"),
    ml: t("commons.languages.ml"),
    mn: t("commons.languages.mn"),
    mr: t("commons.languages.mr"),
    ms: t("commons.languages.ms"),
    mt: t("commons.languages.mt"),
    my: t("commons.languages.my"),
    na: t("commons.languages.na"),
    nb: t("commons.languages.nb"),
    nd: t("commons.languages.nd"),
    ne: t("commons.languages.ne"),
    ng: t("commons.languages.ng"),
    nl: t("commons.languages.nl"),
    nn: t("commons.languages.nn"),
    no: t("commons.languages.no"),
    nr: t("commons.languages.nr"),
    nv: t("commons.languages.nv"),
    ny: t("commons.languages.ny"),
    oc: t("commons.languages.oc"),
    oj: t("commons.languages.oj"),
    om: t("commons.languages.om"),
    or: t("commons.languages.or"),
    os: t("commons.languages.os"),
    pa: t("commons.languages.pa"),
    pi: t("commons.languages.pi"),
    pl: t("commons.languages.pl"),
    ps: t("commons.languages.ps"),
    pt: t("commons.languages.pt"),
    qu: t("commons.languages.qu"),
    rm: t("commons.languages.rm"),
    rn: t("commons.languages.rn"),
    ro: t("commons.languages.ro"),
    ru: t("commons.languages.ru"),
    rw: t("commons.languages.rw"),
    sa: t("commons.languages.sa"),
    sc: t("commons.languages.sc"),
    sd: t("commons.languages.sd"),
    se: t("commons.languages.se"),
    sg: t("commons.languages.sg"),
    si: t("commons.languages.si"),
    sk: t("commons.languages.sk"),
    sl: t("commons.languages.sl"),
    sm: t("commons.languages.sm"),
    sn: t("commons.languages.sn"),
    so: t("commons.languages.so"),
    sq: t("commons.languages.sq"),
    sr: t("commons.languages.sr"),
    ss: t("commons.languages.ss"),
    st: t("commons.languages.st"),
    su: t("commons.languages.su"),
    sv: t("commons.languages.sv"),
    sw: t("commons.languages.sw"),
    ta: t("commons.languages.ta"),
    te: t("commons.languages.te"),
    tg: t("commons.languages.tg"),
    th: t("commons.languages.th"),
    ti: t("commons.languages.ti"),
    tk: t("commons.languages.tk"),
    tl: t("commons.languages.tl"),
    tn: t("commons.languages.tn"),
    to: t("commons.languages.to"),
    tr: t("commons.languages.tr"),
    ts: t("commons.languages.ts"),
    tt: t("commons.languages.tt"),
    tw: t("commons.languages.tw"),
    ty: t("commons.languages.ty"),
    ug: t("commons.languages.ug"),
    uk: t("commons.languages.uk"),
    ur: t("commons.languages.ur"),
    uz: t("commons.languages.uz"),
    ve: t("commons.languages.ve"),
    vi: t("commons.languages.vi"),
    vo: t("commons.languages.vo"),
    wa: t("commons.languages.wa"),
    wo: t("commons.languages.wo"),
    xh: t("commons.languages.xh"),
    yi: t("commons.languages.yi"),
    yo: t("commons.languages.yo"),
    za: t("commons.languages.za"),
    zh: t("commons.languages.zh"),
    zu: t("commons.languages.zu")
  });

  return translations(t)[lang] ?? t(`commons.languages.${lang}`);
};
