import React from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Base64UploadAdapter,
  Bold,
  ClassicEditor,
  Essentials,
  FontBackgroundColor,
  FontColor,
  Heading,
  ImageBlock,
  ImageInsert,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  RemoveFormat,
  SourceEditing,
  Strikethrough,
  Table,
  TableToolbar,
  Underline,
  Undo
} from "ckeditor5";
import {connect} from "react-redux";
import ar from "ckeditor5/translations/ar.js";
import de from "ckeditor5/translations/de.js";
import es from "ckeditor5/translations/es.js";
import fr from "ckeditor5/translations/fr.js";
import it from "ckeditor5/translations/it.js";
import pt from "ckeditor5/translations/pt.js";
import "./style.css";
import "ckeditor5/ckeditor5.css";

const allToolbarItems = [
  "heading",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "removeFormat",
  "|",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "alignment",
  "bulletedList",
  "numberedList",
  "indent",
  "outdent",
  "|",
  "sourceEditing",
  "imageUpload",
  "insertTable",
  "link",
  "|",
  "undo",
  "redo"
];

const minimalToolbarItems = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "removeFormat",
  "|",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "link"
];

class HtmlEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = HtmlEditor.getInitState(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  static getInitState(props) {
    return {
      value: props ? props.value || "" : ""
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.value !== null && props.value !== undefined) {
      return {
        value: props.value
      };
    } else {
      return HtmlEditor.getInitState(props);
    }
  }

  handleEditorChange(event, editor) {
    const data = editor.getData();
    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {
    const {value} = this.state;

    const {
      defaultLanguage,
      disabled,
      minimalToolbar,
      enableImageUpload = false,
      enableSourceEditing = false
    } = this.props;

    const toolbarItems = allToolbarItems
      .filter(v => enableImageUpload || v !== "imageUpload")
      .filter(v => enableSourceEditing || v !== "sourceEditing");

    const customConfig = {
      toolbar: {
        items: minimalToolbar ? minimalToolbarItems : toolbarItems
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        AutoImage,
        AutoLink,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        Heading,
        ImageBlock,
        ImageInsert,
        ImageUpload,
        Base64UploadAdapter,
        Indent,
        IndentBlock,
        Italic,
        Link,
        List,
        RemoveFormat,
        SourceEditing,
        Strikethrough,
        Table,
        TableToolbar,
        Underline,
        Undo
      ],
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            styles: true
          }
        ]
      },
      link: {
        defaultProtocol: "http://",
        decorators: [
          {
            mode: "manual",
            label: "Open in a new tab",
            defaultValue: true,
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer"
            }
          }
        ]
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
      },
      translations: [de, es, fr, it, pt, ar]
    };

    return (
      <CKEditor
        data={value}
        editor={ClassicEditor}
        config={{
          language: defaultLanguage,
          ...customConfig
        }}
        disabled={disabled}
        onChange={this.handleEditorChange}
      />
    );
  }
}

export default connect(state => ({
  defaultLanguage: state.app.language
}))(HtmlEditor);
